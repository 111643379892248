:root {
  --fm-version: "2.9.83";
}
/* ===== RESET STYLES ===== */
#fm-pivot-view,
#fm-toolbar-wrapper {
  line-height: 1;
  -webkit-font-smoothing: antialiased !important;
  text-rendering: optimizeLegibility !important;
  -webkit-text-size-adjust: none;
}
#fm-pivot-view,
#fm-toolbar-wrapper {
  /* Hide HTML5 Up and Down arrows. */
}
#fm-pivot-view input[type=text],
#fm-toolbar-wrapper input[type=text] {
  -webkit-appearance: none;
}
#fm-pivot-view input[type="number"]::-webkit-outer-spin-button,
#fm-toolbar-wrapper input[type="number"]::-webkit-outer-spin-button,
#fm-pivot-view input[type="number"]::-webkit-inner-spin-button,
#fm-toolbar-wrapper input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
#fm-pivot-view input[type="number"],
#fm-toolbar-wrapper input[type="number"] {
  -moz-appearance: textfield;
}
#fm-pivot-view div,
#fm-pivot-view span,
#fm-pivot-view applet,
#fm-pivot-view object,
#fm-pivot-view iframe,
#fm-pivot-view h1,
#fm-pivot-view h2,
#fm-pivot-view h3,
#fm-pivot-view h4,
#fm-pivot-view h5,
#fm-pivot-view h6,
#fm-pivot-view p,
#fm-pivot-view blockquote,
#fm-pivot-view pre,
#fm-pivot-view a,
#fm-pivot-view abbr,
#fm-pivot-view acronym,
#fm-pivot-view address,
#fm-pivot-view big,
#fm-pivot-view cite,
#fm-pivot-view code,
#fm-pivot-view del,
#fm-pivot-view dfn,
#fm-pivot-view em,
#fm-pivot-view img,
#fm-pivot-view ins,
#fm-pivot-view kbd,
#fm-pivot-view q,
#fm-pivot-view s,
#fm-pivot-view samp,
#fm-pivot-view small,
#fm-pivot-view strike,
#fm-pivot-view strong,
#fm-pivot-view sub,
#fm-pivot-view sup,
#fm-pivot-view tt,
#fm-pivot-view var,
#fm-pivot-view b,
#fm-pivot-view button,
#fm-pivot-view u,
#fm-pivot-view i,
#fm-pivot-view center,
#fm-pivot-view dl,
#fm-pivot-view dt,
#fm-pivot-view dd,
#fm-pivot-view ol,
#fm-pivot-view ul,
#fm-pivot-view li,
#fm-pivot-view fieldset,
#fm-pivot-view form,
#fm-pivot-view label,
#fm-pivot-view legend,
#fm-pivot-view table,
#fm-pivot-view caption,
#fm-pivot-view tbody,
#fm-pivot-view tfoot,
#fm-pivot-view thead,
#fm-pivot-view tr,
#fm-pivot-view th,
#fm-pivot-view td,
#fm-pivot-view article,
#fm-pivot-view aside,
#fm-pivot-view canvas,
#fm-pivot-view details,
#fm-pivot-view embed,
#fm-pivot-view figure,
#fm-pivot-view figcaption,
#fm-pivot-view footer,
#fm-pivot-view header,
#fm-pivot-view menu,
#fm-pivot-view nav,
#fm-pivot-view output,
#fm-pivot-view ruby,
#fm-pivot-view section,
#fm-pivot-view summary,
#fm-pivot-view time,
#fm-pivot-view mark,
#fm-pivot-view audio,
#fm-pivot-view video,
#fm-toolbar-wrapper div,
#fm-toolbar-wrapper span,
#fm-toolbar-wrapper applet,
#fm-toolbar-wrapper object,
#fm-toolbar-wrapper iframe,
#fm-toolbar-wrapper h1,
#fm-toolbar-wrapper h2,
#fm-toolbar-wrapper h3,
#fm-toolbar-wrapper h4,
#fm-toolbar-wrapper h5,
#fm-toolbar-wrapper h6,
#fm-toolbar-wrapper p,
#fm-toolbar-wrapper blockquote,
#fm-toolbar-wrapper pre,
#fm-toolbar-wrapper a,
#fm-toolbar-wrapper abbr,
#fm-toolbar-wrapper acronym,
#fm-toolbar-wrapper address,
#fm-toolbar-wrapper big,
#fm-toolbar-wrapper cite,
#fm-toolbar-wrapper code,
#fm-toolbar-wrapper del,
#fm-toolbar-wrapper dfn,
#fm-toolbar-wrapper em,
#fm-toolbar-wrapper img,
#fm-toolbar-wrapper ins,
#fm-toolbar-wrapper kbd,
#fm-toolbar-wrapper q,
#fm-toolbar-wrapper s,
#fm-toolbar-wrapper samp,
#fm-toolbar-wrapper small,
#fm-toolbar-wrapper strike,
#fm-toolbar-wrapper strong,
#fm-toolbar-wrapper sub,
#fm-toolbar-wrapper sup,
#fm-toolbar-wrapper tt,
#fm-toolbar-wrapper var,
#fm-toolbar-wrapper b,
#fm-toolbar-wrapper button,
#fm-toolbar-wrapper u,
#fm-toolbar-wrapper i,
#fm-toolbar-wrapper center,
#fm-toolbar-wrapper dl,
#fm-toolbar-wrapper dt,
#fm-toolbar-wrapper dd,
#fm-toolbar-wrapper ol,
#fm-toolbar-wrapper ul,
#fm-toolbar-wrapper li,
#fm-toolbar-wrapper fieldset,
#fm-toolbar-wrapper form,
#fm-toolbar-wrapper label,
#fm-toolbar-wrapper legend,
#fm-toolbar-wrapper table,
#fm-toolbar-wrapper caption,
#fm-toolbar-wrapper tbody,
#fm-toolbar-wrapper tfoot,
#fm-toolbar-wrapper thead,
#fm-toolbar-wrapper tr,
#fm-toolbar-wrapper th,
#fm-toolbar-wrapper td,
#fm-toolbar-wrapper article,
#fm-toolbar-wrapper aside,
#fm-toolbar-wrapper canvas,
#fm-toolbar-wrapper details,
#fm-toolbar-wrapper embed,
#fm-toolbar-wrapper figure,
#fm-toolbar-wrapper figcaption,
#fm-toolbar-wrapper footer,
#fm-toolbar-wrapper header,
#fm-toolbar-wrapper menu,
#fm-toolbar-wrapper nav,
#fm-toolbar-wrapper output,
#fm-toolbar-wrapper ruby,
#fm-toolbar-wrapper section,
#fm-toolbar-wrapper summary,
#fm-toolbar-wrapper time,
#fm-toolbar-wrapper mark,
#fm-toolbar-wrapper audio,
#fm-toolbar-wrapper video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: none;
  transition: none;
  border-collapse: collapse;
  border-spacing: 0;
}
#fm-pivot-view ol,
#fm-pivot-view ul,
#fm-toolbar-wrapper ol,
#fm-toolbar-wrapper ul {
  list-style: none;
}
#fm-pivot-view table,
#fm-toolbar-wrapper table {
  border-collapse: collapse;
  border-spacing: 0;
}
#fm-pivot-view caption,
#fm-pivot-view th,
#fm-pivot-view td,
#fm-toolbar-wrapper caption,
#fm-toolbar-wrapper th,
#fm-toolbar-wrapper td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}
#fm-pivot-view q,
#fm-pivot-view blockquote,
#fm-toolbar-wrapper q,
#fm-toolbar-wrapper blockquote {
  quotes: none;
}
#fm-pivot-view q:before,
#fm-pivot-view q:after,
#fm-pivot-view blockquote:before,
#fm-pivot-view blockquote:after,
#fm-toolbar-wrapper q:before,
#fm-toolbar-wrapper q:after,
#fm-toolbar-wrapper blockquote:before,
#fm-toolbar-wrapper blockquote:after {
  content: "";
  content: none;
}
#fm-pivot-view img,
#fm-toolbar-wrapper img {
  max-width: 100%;
  height: auto;
  height: initial;
  border: none;
}
#fm-pivot-view a,
#fm-toolbar-wrapper a {
  text-decoration: none;
}
#fm-pivot-view article,
#fm-pivot-view aside,
#fm-pivot-view details,
#fm-pivot-view figcaption,
#fm-pivot-view figure,
#fm-pivot-view footer,
#fm-pivot-view header,
#fm-pivot-view main,
#fm-pivot-view menu,
#fm-pivot-view nav,
#fm-pivot-view section,
#fm-pivot-view summary,
#fm-toolbar-wrapper article,
#fm-toolbar-wrapper aside,
#fm-toolbar-wrapper details,
#fm-toolbar-wrapper figcaption,
#fm-toolbar-wrapper figure,
#fm-toolbar-wrapper footer,
#fm-toolbar-wrapper header,
#fm-toolbar-wrapper main,
#fm-toolbar-wrapper menu,
#fm-toolbar-wrapper nav,
#fm-toolbar-wrapper section,
#fm-toolbar-wrapper summary {
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
#fm-pivot-view :focus,
#fm-toolbar-wrapper :focus {
  outline: none;
}
#fm-pivot-view h1,
#fm-pivot-view h2,
#fm-pivot-view h3,
#fm-pivot-view h4,
#fm-pivot-view h5,
#fm-pivot-view h6,
#fm-toolbar-wrapper h1,
#fm-toolbar-wrapper h2,
#fm-toolbar-wrapper h3,
#fm-toolbar-wrapper h4,
#fm-toolbar-wrapper h5,
#fm-toolbar-wrapper h6 {
  font-weight: normal;
}
/*-----------STANDARD RESET END-----------------*/
@font-face {
  font-family: "flexmonster-icons";
  src: url("./theme/assets/flexmonster-icons.woff?v2.8.19") format("woff"), url("./theme/assets/flexmonster-icons.ttf?v2.8.19") format("truetype"), url("./theme/assets/flexmonster-icons.svg#flexmonster-icons?v2.8.19") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* === Do NOT remove and do NOT change .fm-csscheck class === */
.fm-csscheck {
  font-family: 'flexmonster';
}
/* ===== PIVOT UI ===== */
#fm-pivot-view .fm-ui,
#fm-pivot-view .fm-ui span,
#fm-pivot-view .fm-ui div,
#fm-pivot-view .fm-ui p,
#fm-pivot-view .fm-ui a,
#fm-pivot-view .fm-ui table,
#fm-pivot-view .fm-ui table th,
#fm-pivot-view .fm-ui table tr,
#fm-pivot-view .fm-ui table td,
#fm-pivot-view .fm-ui ul,
#fm-pivot-view .fm-ui li,
#fm-pivot-view .fm-ui input,
#fm-pivot-view .fm-ui textarea,
#fm-pivot-view .fm-ui select,
#fm-toolbar-wrapper .fm-toolbar-ui,
#fm-toolbar-wrapper .fm-toolbar-ui span,
#fm-toolbar-wrapper .fm-toolbar-ui div,
#fm-toolbar-wrapper .fm-toolbar-ui p,
#fm-toolbar-wrapper .fm-toolbar-ui a,
#fm-toolbar-wrapper .fm-toolbar-ui table,
#fm-toolbar-wrapper .fm-toolbar-ui table th,
#fm-toolbar-wrapper .fm-toolbar-ui table tr,
#fm-toolbar-wrapper .fm-toolbar-ui table td,
#fm-toolbar-wrapper .fm-toolbar-ui ul,
#fm-toolbar-wrapper .fm-toolbar-ui li,
#fm-toolbar-wrapper .fm-toolbar-ui input,
#fm-toolbar-wrapper .fm-toolbar-ui textarea,
#fm-toolbar-wrapper .fm-toolbar-ui select {
  font-family: Arial, sans-serif;
  font-size: 12px;
  text-align: left;
  color: #111;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
  border-radius: 0px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  text-shadow: none;
  float: none;
  line-height: normal;
}
#fm-pivot-view .fm-ui-mobile,
#fm-pivot-view .fm-ui-mobile span,
#fm-pivot-view .fm-ui-mobile div,
#fm-pivot-view .fm-ui-mobile p,
#fm-pivot-view .fm-ui-mobile a,
#fm-pivot-view .fm-ui-mobile table,
#fm-pivot-view .fm-ui-mobile th,
#fm-pivot-view .fm-ui-mobile tr,
#fm-pivot-view .fm-ui-mobile td,
#fm-pivot-view .fm-ui-mobile ul,
#fm-pivot-view .fm-ui-mobile li,
#fm-pivot-view .fm-ui-mobile input,
#fm-pivot-view .fm-ui-mobile textarea,
#fm-pivot-view .fm-ui-mobile select,
#fm-pivot-view .fm-ui-mobile option {
  font-size: 14px;
  -webkit-border-radius: 0;
  border-radius: 0;
}
#fm-pivot-view .fm-ui-mobile .fm-grid-layout div.fm-cell.fm-header {
  line-height: 14px;
}
#fm-pivot-view .fm-ui-mobile input[type="text"] {
  height: 28px;
  line-height: 28px;
  border: 1px solid #999;
  background-color: #ffffff;
}
#fm-pivot-view .fm-ui-mobile select {
  background-color: #ffffff;
}
#fm-pivot-view .fm-ui a {
  font-weight: normal;
}
#fm-pivot-view .fm-ui ul > li:before {
  width: 0px !important;
  height: 0px !important;
  margin: 0;
  padding: 0;
  border: 0;
}
#fm-pivot-view a.fm-ui {
  color: inherit;
  font-weight: normal;
}
#fm-pivot-view a.fm-ui:hover {
  color: inherit;
  font-weight: normal;
  text-decoration: none;
}
#fm-pivot-view input.fm-ui {
  font-size: 12px;
  outline: none;
}
#fm-pivot-view input.fm-ui-mobile {
  font-size: 14px;
}
#fm-pivot-view span.fm-ui {
  font-size: 11px;
}
#fm-pivot-view span.fm-ui-mobile {
  font-size: 14px;
}
#fm-pivot-view div.fm-ui-clear {
  clear: both;
}
#fm-pivot-view .fm-pointer-events-none {
  pointer-events: none;
}
#fm-pivot-view input[type=text].fm-ui-text-input,
#fm-pivot-view input[type=number].fm-ui-text-input {
  /*vertical-align: -webkit-baseline-middle;
    vertical-align: -moz-middle-with-baseline;*/
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  width: 100%;
  height: 38px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-right: 10px;
  padding-left: 10px;
  text-align: left;
  color: #111;
  font-size: 14px;
  background: #fff;
  border: 1px solid #d5d5d5;
}
#fm-pivot-view input[type=text].fm-ui-text-input.fm-ui-prompt {
  color: #cecece;
  font-style: italic;
}
#fm-pivot-view .fm-ui textarea.fm-ui-text-area {
  resize: none;
  border: 1px solid #999;
  padding: 1px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin: 0;
  color: #111;
  background: #fff;
  font-size: 14px;
}
#fm-pivot-view .fm-ui textarea.fm-ui-text-area:focus {
  outline: none;
}
#fm-pivot-view [class^="fm-ui-icon-"]:before,
#fm-pivot-view [class*=" fm-ui-icon-"]:before,
#fm-pivot-view .fm-ui-icon:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: flexmonster-icons !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#fm-pivot-view .fm-icon-act_add:before {
  content: "\e900";
}
#fm-pivot-view .fm-icon-act_calc:before {
  content: "\e901";
}
#fm-pivot-view .fm-icon-act_check:before {
  content: "\e902";
}
#fm-pivot-view .fm-icon-act_close:before {
  content: "\e903";
}
#fm-pivot-view .fm-icon-act_close_small:before {
  content: "\e904";
}
#fm-pivot-view .fm-icon-act_filter:before {
  content: "\e905";
}
#fm-pivot-view .fm-icon-act_font:before {
  content: "\e906";
}
#fm-pivot-view .fm-icon-act_move:before {
  content: "\e907";
}
#fm-pivot-view .fm-icon-act_roll_down:before {
  content: "\e908";
}
#fm-pivot-view .fm-icon-act_roll_up:before {
  content: "\e909";
}
#fm-pivot-view .fm-icon-act_search:before {
  content: "\e90a";
}
#fm-pivot-view .fm-icon-act_sigma:before {
  content: "\e90b";
}
#fm-pivot-view .fm-icon-act_table_settings:before {
  content: "\e90c";
}
#fm-pivot-view .fm-icon-act_table_sort:before {
  content: "\e90d";
}
#fm-pivot-view .fm-icon-act_trash:before {
  content: "\e90e";
}
#fm-pivot-view .fm-icon-arrow_down:before {
  content: "\e90f";
}
#fm-pivot-view .fm-icon-arrow_left:before {
  content: "\e910";
}
#fm-pivot-view .fm-icon-arrow_right:before {
  content: "\e911";
}
#fm-pivot-view .fm-icon-arrow_up:before {
  content: "\e912";
}
#fm-pivot-view .fm-icon-calc_abs:before {
  content: "\e913";
}
#fm-pivot-view .fm-icon-calc_and:before {
  content: "\e914";
}
#fm-pivot-view .fm-icon-calc_assigne:before {
  content: "\e915";
}
#fm-pivot-view .fm-icon-calc_degree:before {
  content: "\e916";
}
#fm-pivot-view .fm-icon-calc_divide:before {
  content: "\e917";
}
#fm-pivot-view .fm-icon-calc_equal:before {
  content: "\e918";
}
#fm-pivot-view .fm-icon-calc_if:before {
  content: "\e919";
}
#fm-pivot-view .fm-icon-calc_less:before {
  content: "\e91a";
}
#fm-pivot-view .fm-icon-calc_less_equal:before {
  content: "\e91b";
}
#fm-pivot-view .fm-icon-calc_max:before {
  content: "\e91c";
}
#fm-pivot-view .fm-icon-calc_min:before {
  content: "\e91d";
}
#fm-pivot-view .fm-icon-calc_minus:before {
  content: "\e91e";
}
#fm-pivot-view .fm-icon-calc_more:before {
  content: "\e91f";
}
#fm-pivot-view .fm-icon-calc_more_equal:before {
  content: "\e920";
}
#fm-pivot-view .fm-icon-calc_multiply:before {
  content: "\e921";
}
#fm-pivot-view .fm-icon-calc_notequal:before {
  content: "\e922";
}
#fm-pivot-view .fm-icon-calc_or:before {
  content: "\e923";
}
#fm-pivot-view .fm-icon-calc_plus:before {
  content: "\e924";
}
#fm-pivot-view .fm-icon-chart_direction_xy:before {
  content: "\e925";
}
#fm-pivot-view .fm-icon-count_down:before {
  content: "\e926";
}
#fm-pivot-view .fm-icon-count_up:before {
  content: "\e927";
}
#fm-pivot-view .fm-icon-dd_connect_csv:before {
  content: "\e928";
}
#fm-pivot-view .fm-icon-dd_connect_json:before {
  content: "\e929";
}
#fm-pivot-view .fm-icon-dd_export_bar:before {
  content: "\e92a";
}
#fm-pivot-view .fm-icon-dd_export_bar_hor:before {
  content: "\e92b";
}
#fm-pivot-view .fm-icon-dd_export_bar_lane:before {
  content: "\e92c";
}
#fm-pivot-view .fm-icon-dd_export_bar_stack:before {
  content: "\e92d";
}
#fm-pivot-view .fm-icon-dd_export_line:before {
  content: "\e92e";
}
#fm-pivot-view .fm-icon-dd_export_pie:before {
  content: "\e92f";
}
#fm-pivot-view .fm-icon-dd_export_scatter:before {
  content: "\e930";
}
#fm-pivot-view .fm-icon-dd_format:before {
  content: "\e931";
}
#fm-pivot-view .fm-icon-dd_format_1:before {
  content: "\e932";
}
#fm-pivot-view .fm-icon-dd_open_local:before {
  content: "\e933";
}
#fm-pivot-view .fm-icon-dd_open_remote:before {
  content: "\e934";
}
#fm-pivot-view .fm-icon-dd_save_exel:before {
  content: "\e935";
}
#fm-pivot-view .fm-icon-dd_save_html:before {
  content: "\e936";
}
#fm-pivot-view .fm-icon-dd_save_image:before {
  content: "\e937";
}
#fm-pivot-view .fm-icon-dd_save_pdf:before {
  content: "\e938";
}
#fm-pivot-view .fm-icon-dd_save_print:before {
  content: "\e939";
}
#fm-pivot-view .fm-icon-direction_direction_y:before {
  content: "\e93a";
}
#fm-pivot-view .fm-icon-menu_charts:before {
  content: "\e93b";
}
#fm-pivot-view .fm-icon-menu_connect:before {
  content: "\e93c";
}
#fm-pivot-view .fm-icon-menu_export:before {
  content: "\e93d";
}
#fm-pivot-view .fm-icon-menu_fields:before {
  content: "\e93e";
}
#fm-pivot-view .fm-icon-menu_format:before {
  content: "\e93f";
}
#fm-pivot-view .fm-icon-menu_fullscreen_close:before {
  content: "\e940";
}
#fm-pivot-view .fm-icon-menu_fullscreen_open:before {
  content: "\e941";
}
#fm-pivot-view .fm-icon-menu_grid:before {
  content: "\e942";
}
#fm-pivot-view .fm-icon-menu_open:before {
  content: "\e943";
}
#fm-pivot-view .fm-icon-menu_options:before {
  content: "\e944";
}
#fm-pivot-view .fm-icon-menu_save:before {
  content: "\e945";
}
#fm-pivot-view .fm-icon-notif_arrow:before {
  content: "\e946";
}
#fm-pivot-view .fm-icon-notif_confirm:before {
  content: "\e947";
}
#fm-pivot-view .fm-icon-notif_info:before {
  content: "\e948";
}
#fm-pivot-view .fm-icon-notif_warning:before {
  content: "\e949";
}
.fm-ui-icon {
  display: block;
  position: absolute;
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fm-ui-vam {
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}
.fm-ui-icon-vam {
  display: block;
  position: absolute;
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}
.fm-ui-ham {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.fm-ui-icon-ham {
  display: block;
  position: absolute;
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.fm-ui-icon-c {
  display: block;
  position: absolute;
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -ms-transform: translateY(-50%) translateX(-50%);
  -moz-transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
}
.fm-shadow {
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}
#fm-pivot-view span.fm-ui-label {
  color: #111;
  font-size: 14px;
}
#fm-pivot-view span.fm-ui-label * {
  font-size: 14px;
}
#fm-pivot-view span.fm-ui-label b {
  font-weight: bold;
}
#fm-pivot-view span.fm-ui-label-light {
  color: #999;
}
#fm-pivot-view button.fm-ui-btn,
#fm-toolbar-wrapper button.fm-ui-btn,
#fm-pivot-view a.fm-ui-btn,
#fm-toolbar-wrapper a.fm-ui-btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-transform: uppercase;
  position: relative;
  outline: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 12px;
  letter-spacing: 0.5px;
  min-width: 90px;
  color: #555;
  border: 1px solid #555;
  background-color: #fff;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
#fm-pivot-view button.fm-ui-btn:hover,
#fm-toolbar-wrapper button.fm-ui-btn:hover,
#fm-pivot-view a.fm-ui-btn:hover,
#fm-toolbar-wrapper a.fm-ui-btn:hover {
  font-weight: bold;
  color: #3c3c3c;
  border-color: #3c3c3c;
  background-color: #f7f7f7;
}
#fm-pivot-view button.fm-ui-btn:hover,
#fm-toolbar-wrapper button.fm-ui-btn:hover,
#fm-pivot-view a.fm-ui-btn:hover,
#fm-toolbar-wrapper a.fm-ui-btn:hover,
#fm-pivot-view button.fm-ui-btn:focus,
#fm-toolbar-wrapper button.fm-ui-btn:focus,
#fm-pivot-view a.fm-ui-btn:focus,
#fm-toolbar-wrapper a.fm-ui-btn:focus,
#fm-pivot-view button.fm-ui-btn:active,
#fm-toolbar-wrapper button.fm-ui-btn:active,
#fm-pivot-view a.fm-ui-btn:active,
#fm-toolbar-wrapper a.fm-ui-btn:active {
  outline: none;
}
#fm-pivot-view a.fm-ui-btn-dark,
#fm-toolbar-wrapper a.fm-ui-btn-dark,
#fm-pivot-view button.fm-ui-btn-dark,
#fm-toolbar-wrapper button.fm-ui-btn-dark {
  color: #fff;
  background: #555;
}
#fm-pivot-view a.fm-ui-btn-dark:hover,
#fm-toolbar-wrapper a.fm-ui-btn-dark:hover,
#fm-pivot-view button.fm-ui-btn-dark:hover,
#fm-toolbar-wrapper button.fm-ui-btn-dark:hover {
  font-weight: bold;
  color: #fff;
  background: #3c3c3c;
}
#fm-pivot-view a.fm-ui-btn-light,
#fm-toolbar-wrapper a.fm-ui-btn-light,
#fm-pivot-view button.fm-ui-btn-light,
#fm-toolbar-wrapper button.fm-ui-btn-light {
  background: #fbfbfb;
  border: 1px solid #d5d5d5;
  color: #555;
  font-weight: normal;
  text-transform: none;
  padding: 11px 11px;
}
#fm-pivot-view a.fm-ui-btn-light:hover,
#fm-toolbar-wrapper a.fm-ui-btn-light:hover,
#fm-pivot-view button.fm-ui-btn-light:hover,
#fm-toolbar-wrapper button.fm-ui-btn-light:hover {
  background: #f7f7f7;
  border-color: #d5d5d5;
  color: #555;
  font-weight: normal;
}
#fm-pivot-view a.fm-ui-btn-superlight,
#fm-toolbar-wrapper a.fm-ui-btn-superlight,
#fm-pivot-view button.fm-ui-btn-superlight,
#fm-toolbar-wrapper button.fm-ui-btn-superlight {
  background: #fff;
  border: 1px solid #d5d5d5;
  color: #555;
  font-weight: normal;
  text-transform: none;
  padding: 11px 11px;
  padding-top: 9px;
}
#fm-pivot-view a.fm-ui-btn-superlight:hover,
#fm-toolbar-wrapper a.fm-ui-btn-superlight:hover,
#fm-pivot-view button.fm-ui-btn-superlight:hover,
#fm-toolbar-wrapper button.fm-ui-btn-superlight:hover {
  background: #fbfbfb;
  border-color: #d5d5d5;
  color: #555;
  font-weight: normal;
}
#fm-pivot-view a.fm-ui-btn.fm-ui-btn-close,
#fm-toolbar-wrapper a.fm-ui-btn.fm-ui-btn-close,
#fm-pivot-view button.fm-ui-btn.fm-ui-btn-close,
#fm-toolbar-wrapper button.fm-ui-btn.fm-ui-btn-close {
  font-size: 0;
  border: none;
  background: none;
  min-width: auto;
  min-width: initial;
  width: 30px;
  height: 30px;
  padding: 2px;
}
#fm-pivot-view a.fm-ui-btn.fm-ui-btn-close:before,
#fm-toolbar-wrapper a.fm-ui-btn.fm-ui-btn-close:before,
#fm-pivot-view button.fm-ui-btn.fm-ui-btn-close:before,
#fm-toolbar-wrapper button.fm-ui-btn.fm-ui-btn-close:before {
  display: block;
  position: absolute;
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e903";
  font-size: 26px;
  position: static;
}
#fm-pivot-view .fm-ui-btns-row,
#fm-toolbar-wrapper .fm-ui-btns-row {
  font-size: 0;
}
#fm-pivot-view .fm-ui-btns-row .fm-ui-btn,
#fm-toolbar-wrapper .fm-ui-btns-row .fm-ui-btn {
  margin-right: 20px;
  height: 38px;
}
#fm-pivot-view .fm-ui-btns-row .fm-ui-btn:last-child,
#fm-toolbar-wrapper .fm-ui-btns-row .fm-ui-btn:last-child {
  margin-right: 0;
}
#fm-pivot-view a.fm-ui-toggle-btn,
#fm-pivot-view button.fm-ui-toggle-btn {
  color: #999;
  font-size: 14px;
  line-height: 36px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  border: 1px solid #d5d5d5;
  border-radius: 4px;
  background: #fff;
}
#fm-pivot-view a.fm-ui-toggle-btn.fm-selected,
#fm-pivot-view button.fm-ui-toggle-btn.fm-selected {
  color: #111;
  background: #f7f7f7;
}
#fm-pivot-view a.fm-ui-toggle-btn:hover,
#fm-pivot-view button.fm-ui-toggle-btn:hover {
  color: #111;
  background: #f7f7f7;
}
#fm-pivot-view a.fm-ui-toggle-btn-dark,
#fm-pivot-view button.fm-ui-toggle-btn-dark {
  color: #111;
  background: #f7f7f7;
}
#fm-pivot-view a.fm-ui-toggle-btn-dark.fm-selected,
#fm-pivot-view button.fm-ui-toggle-btn-dark.fm-selected {
  color: #fff;
  background: #555;
  border-color: #555;
}
#fm-pivot-view a.fm-ui-toggle-btn-dark.fm-selected:hover,
#fm-pivot-view button.fm-ui-toggle-btn-dark.fm-selected:hover {
  background: #3c3c3c;
  border-color: #3c3c3c;
}
#fm-pivot-view a.fm-ui-link-btn {
  background: none;
  font-size: 12px;
  text-decoration: underline;
  line-height: 1;
  width: auto;
  width: initial;
  height: auto;
  height: initial;
  border: none;
  padding: 0;
  cursor: pointer;
}
#fm-pivot-view a.fm-ui-link-btn:hover {
  background: none;
  text-decoration: none;
}
#fm-pivot-view a.fm-ui-link-btn.fm-ui-pressed {
  background: none;
  text-decoration: underline;
}
#fm-pivot-view a.fm-ui-checkbox {
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-block;
  vertical-align: middle;
}
#fm-pivot-view a.fm-ui-checkbox .fm-ui-label {
  padding-left: 30px;
  position: relative;
  line-height: 1.2;
  color: #111;
  font-size: 14px;
}
#fm-pivot-view a.fm-ui-checkbox .fm-ui-label:before {
  content: '';
  display: inline-block;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #d5d5d5;
  background: #fff;
}
#fm-pivot-view a.fm-ui-checkbox .fm-ui-label:before:hover {
  border-color: #999;
}
#fm-pivot-view a.fm-ui-checkbox .fm-ui-label:after {
  display: block;
  position: absolute;
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  opacity: 0;
  content: '\e947';
  color: #FFFFFF;
  font-size: 20px;
  left: -1px;
}
#fm-pivot-view a.fm-ui-checkbox.fm-selected .fm-ui-label:after {
  opacity: 1;
  font-weight: bold;
  width: 0;
}
#fm-pivot-view a.fm-ui-checkbox.fm-selected .fm-ui-label:before {
  border: 1px solid #555;
  background: #555;
}
#fm-pivot-view a.fm-ui-checkbox.fm-ui-semi-selected .fm-ui-label:after {
  content: '';
  border: 5px solid #555;
  background-color: #555;
  width: 0;
  height: 0;
  left: 4px;
  top: 9px;
  opacity: 1;
}
.fm-ui-arrow-up:before,
.fm-ui-arrow-up:after {
  content: '';
  position: absolute;
  display: block;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.fm-ui-arrow-up:before {
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 5.5px 6px 5.5px;
  border-color: transparent transparent #d5d5d5 transparent;
  top: -7px;
}
.fm-ui-arrow-up:after {
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 4.5px 6px 4.5px;
  border-color: transparent transparent #fff transparent;
  top: -6px;
}
#fm-toolbar-wrapper div.fm-ui-dropdown,
#fm-pivot-view div.fm-ui-dropdown {
  text-align: left;
  display: inline-block;
  vertical-align: top;
  width: auto;
  width: initial;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
#fm-toolbar-wrapper div.fm-ui-dropdown .fm-ui-dropdown-btn,
#fm-pivot-view div.fm-ui-dropdown .fm-ui-dropdown-btn {
  display: block;
  position: relative;
  background-color: #fff;
  text-align: left;
  border: 1px solid #d5d5d5;
  border-radius: 0;
  padding: 9px 25px 10px 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  height: 38px;
}
#fm-toolbar-wrapper div.fm-ui-dropdown .fm-ui-dropdown-btn .fm-ui-label,
#fm-pivot-view div.fm-ui-dropdown .fm-ui-dropdown-btn .fm-ui-label {
  color: #111;
  font-size: 14px;
  vertical-align: top;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  width: 100%;
  line-height: 18px;
}
#fm-toolbar-wrapper div.fm-ui-dropdown .fm-ui-dropdown-btn:after,
#fm-pivot-view div.fm-ui-dropdown .fm-ui-dropdown-btn:after {
  display: block;
  position: absolute;
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  content: "\e908";
  right: 8px;
  font-size: 21px;
  color: #999;
  margin-top: -1px;
}
#fm-toolbar-wrapper div.fm-ui-dropdown .fm-ui-dropdown-btn.fm-selected:after,
#fm-pivot-view div.fm-ui-dropdown .fm-ui-dropdown-btn.fm-selected:after {
  content: "\e909";
}
#fm-toolbar-wrapper div.fm-ui-dropdown .fm-ui-dropdown-btn.fm-selected + .fm-ui-dropdown-list,
#fm-pivot-view div.fm-ui-dropdown .fm-ui-dropdown-btn.fm-selected + .fm-ui-dropdown-list {
  margin-top: 5px;
  visibility: visible;
  opacity: 1;
}
#fm-toolbar-wrapper div.fm-ui-dropdown .fm-ui-dropdown-list,
#fm-pivot-view div.fm-ui-dropdown .fm-ui-dropdown-list {
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  display: block;
  position: absolute;
  z-index: 3;
  left: 0;
  top: 100%;
  border: 1px solid #d5d5d5;
  margin-top: 20px;
  visibility: hidden;
  min-width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  opacity: 1;
  background: #fff;
}
#fm-toolbar-wrapper div.fm-ui-dropdown .fm-ui-dropdown-list:before,
#fm-pivot-view div.fm-ui-dropdown .fm-ui-dropdown-list:before,
#fm-toolbar-wrapper div.fm-ui-dropdown .fm-ui-dropdown-list:after,
#fm-pivot-view div.fm-ui-dropdown .fm-ui-dropdown-list:after {
  content: '';
  position: absolute;
  display: block;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
#fm-toolbar-wrapper div.fm-ui-dropdown .fm-ui-dropdown-list:before,
#fm-pivot-view div.fm-ui-dropdown .fm-ui-dropdown-list:before {
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 5.5px 6px 5.5px;
  border-color: transparent transparent #d5d5d5 transparent;
  top: -7px;
}
#fm-toolbar-wrapper div.fm-ui-dropdown .fm-ui-dropdown-list:after,
#fm-pivot-view div.fm-ui-dropdown .fm-ui-dropdown-list:after {
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 4.5px 6px 4.5px;
  border-color: transparent transparent #fff transparent;
  top: -6px;
}
#fm-toolbar-wrapper div.fm-ui-dropdown .fm-ui-dropdown-list ul,
#fm-pivot-view div.fm-ui-dropdown .fm-ui-dropdown-list ul {
  overflow-y: auto;
  max-height: 300px;
}
#fm-toolbar-wrapper div.fm-ui-dropdown .fm-ui-dropdown-list ul li,
#fm-pivot-view div.fm-ui-dropdown .fm-ui-dropdown-list ul li {
  display: block;
  border-bottom: 1px solid #e9e9e9;
  padding: 9px 9px;
  cursor: pointer;
  position: relative;
}
#fm-toolbar-wrapper div.fm-ui-dropdown .fm-ui-dropdown-list ul li .fm-ui-label,
#fm-pivot-view div.fm-ui-dropdown .fm-ui-dropdown-list ul li .fm-ui-label {
  color: #111;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  width: 100%;
}
#fm-toolbar-wrapper div.fm-ui-dropdown .fm-ui-dropdown-list ul li:last-child,
#fm-pivot-view div.fm-ui-dropdown .fm-ui-dropdown-list ul li:last-child {
  border-bottom: none;
}
#fm-toolbar-wrapper div.fm-ui-dropdown .fm-ui-dropdown-list ul li:hover,
#fm-pivot-view div.fm-ui-dropdown .fm-ui-dropdown-list ul li:hover {
  background: #f7f7f7;
}
#fm-toolbar-wrapper div.fm-ui-dropdown .fm-ui-dropdown-list ul li.fm-selected,
#fm-pivot-view div.fm-ui-dropdown .fm-ui-dropdown-list ul li.fm-selected {
  background: #f7f7f7;
  padding-right: 32px;
  position: relative;
}
#fm-toolbar-wrapper div.fm-ui-dropdown .fm-ui-dropdown-list ul li.fm-selected:after,
#fm-pivot-view div.fm-ui-dropdown .fm-ui-dropdown-list ul li.fm-selected:after {
  display: block;
  position: absolute;
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  content: '\e902';
  color: #999;
  right: 6px;
  font-size: 22px;
  margin-top: -2px;
}
/* popup */
#fm-pivot-view div.fm-ui-window {
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  background: #fff;
  margin: 0 auto 0;
  border: 1px solid #d5d5d5;
  padding: 24px 30px 30px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  z-index: 7;
}
#fm-pivot-view div.fm-ui-window .fm-popup-header {
  min-height: 40px;
  margin-bottom: 10px;
}
#fm-pivot-view div.fm-ui-window .fm-popup-header.fm-ph-simple {
  margin-bottom: 24px;
}
#fm-pivot-view div.fm-ui-window .fm-popup-header .fm-popup-icons-row {
  margin-bottom: 25px;
  margin-top: 7px;
}
#fm-pivot-view div.fm-ui-window .fm-popup-header .fm-ui-btns-row {
  position: absolute;
  right: 30px;
}
#fm-pivot-view div.fm-ui-window .fm-popup-title {
  color: #111;
  font-size: 24px;
  display: block;
  padding: 5px 0;
}
#fm-pivot-view div.fm-ui-window .fm-popup-subtitle {
  color: #999;
  font-size: 14px;
  margin-top: 5px;
  display: block;
}
#fm-pivot-view div.fm-ui-popup {
  z-index: 12;
}
#fm-pivot-view div.fm-ui-popup.fm-layout-mobile {
  padding: 20px;
}
#fm-pivot-view div.fm-ui-popup.fm-layout-mobile .fm-popup-subtitle {
  display: none;
}
#fm-pivot-view div.fm-ui-popup.fm-layout-mobile .fm-ui-btns-row {
  right: 20px;
}
#fm-pivot-view div.fm-ui-popup.fm-layout-mobile-small .fm-popup-header {
  margin-bottom: 10px;
}
#fm-pivot-view div.fm-ui-popup.fm-layout-mobile-small .fm-popup-header .fm-ui-col {
  width: 100%;
  margin-bottom: 10px;
}
#fm-pivot-view div.fm-ui-popup.fm-layout-mobile-small .fm-popup-header .fm-ui-btns-row {
  position: relative;
  right: initial;
  right: auto;
  text-align: left;
}
#fm-pivot-view div.fm-ui-popup.fm-layout-mobile-small .fm-popup-header .fm-ui-btns-row .fm-ui-btn {
  width: calc(50% - 5px);
  width: -webkit-calc(50% - 5px);
  margin-right: 10px;
}
#fm-pivot-view div.fm-ui-popup.fm-layout-mobile-small .fm-popup-header .fm-ui-btns-row .fm-ui-btn:last-child {
  margin-right: 0;
}
#fm-pivot-view div.fm-ui-popup.fm-layout-mobile-small .fm-popup-header .fm-popup-title {
  font-size: 18px;
  line-height: 1;
}
#fm-pivot-view div.fm-ui-datepicker .fm-ui-dp-header {
  text-align: center;
  padding: 10px;
  font-weight: bold;
  border-bottom: 1px solid #d5d5d5;
  position: relative;
}
#fm-pivot-view div.fm-ui-datepicker .fm-ui-dp-header .fm-ui-dp-month {
  margin-right: 5px;
}
#fm-pivot-view div.fm-ui-datepicker .fm-ui-dp-header .fm-ui-dp-year,
#fm-pivot-view div.fm-ui-datepicker .fm-ui-dp-header .fm-ui-dp-month {
  cursor: pointer;
  text-decoration: underline;
}
#fm-pivot-view div.fm-ui-datepicker .fm-ui-dp-header .fm-ui-dp-year:hover,
#fm-pivot-view div.fm-ui-datepicker .fm-ui-dp-header .fm-ui-dp-month:hover {
  text-decoration: none;
}
#fm-pivot-view div.fm-ui-datepicker .fm-ui-dp-header .fm-ui-dp-year.fm-disabled,
#fm-pivot-view div.fm-ui-datepicker .fm-ui-dp-header .fm-ui-dp-month.fm-disabled {
  cursor: auto;
  text-decoration: none;
}
#fm-pivot-view div.fm-ui-datepicker .fm-ui-dp-header .fm-ui-btn {
  padding: 0;
  border: none;
  min-width: auto;
  position: absolute;
  background: none;
  width: 37px;
  height: 37px;
  top: 0;
}
#fm-pivot-view div.fm-ui-datepicker .fm-ui-dp-header .fm-ui-btn:before {
  display: block;
  position: absolute;
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  transform: rotate(90deg);
  -o-transform: rotate(90deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  font-size: 24px;
  top: 6px;
  left: 6px;
}
#fm-pivot-view div.fm-ui-datepicker .fm-ui-dp-header .fm-ui-btn.fm-prev-btn {
  left: 0;
}
#fm-pivot-view div.fm-ui-datepicker .fm-ui-dp-header .fm-ui-btn.fm-prev-btn:before {
  content: "\e908";
}
#fm-pivot-view div.fm-ui-datepicker .fm-ui-dp-header .fm-ui-btn.fm-next-btn {
  right: 0;
}
#fm-pivot-view div.fm-ui-datepicker .fm-ui-dp-header .fm-ui-btn.fm-next-btn:before {
  content: "\e909";
}
#fm-pivot-view div.fm-ui-datepicker .fm-ui-dp-week {
  padding: 10px;
  border-bottom: 1px solid #d5d5d5;
  background-color: #fbfbfb;
}
#fm-pivot-view div.fm-ui-datepicker .fm-ui-dp-week .fm-ui-dp-day-title {
  text-align: center;
  color: #999;
}
#fm-pivot-view div.fm-ui-datepicker .fm-ui-dp-dates {
  padding: 10px 10px;
}
#fm-pivot-view div.fm-ui-datepicker .fm-ui-dp-dates .fm-ui-dp-date-label {
  text-align: center;
  padding: 5px;
  cursor: pointer;
  position: relative;
}
#fm-pivot-view div.fm-ui-datepicker .fm-ui-dp-dates .fm-ui-dp-date-label:hover {
  background-color: #f7f7f7;
}
#fm-pivot-view div.fm-ui-datepicker .fm-ui-dp-dates .fm-ui-dp-date-label.fm-highlighted {
  font-weight: bold;
}
#fm-pivot-view div.fm-ui-datepicker .fm-ui-dp-dates .fm-ui-dp-date-label.fm-muted {
  font-weight: normal;
  color: #999;
}
#fm-pivot-view div.fm-ui-datepicker .fm-ui-dp-dates .fm-ui-dp-date-label.fm-selected {
  font-weight: normal;
  color: #fff;
  background-color: #555;
  border: 1px solid #555;
}
#fm-pivot-view div.fm-ui-datepicker .fm-ui-dp-col-7 {
  width: 14.285%;
  display: inline-block;
}
#fm-pivot-view div.fm-ui-datepicker .fm-ui-dp-col-5 {
  width: 20%;
  display: inline-block;
}
#fm-pivot-view div.fm-ui-datepicker .fm-ui-dp-col-3 {
  width: 33.333%;
  display: inline-block;
}
#fm-pivot-view div.fm-ui-datepicker .fm-ui-dp-footer {
  display: none;
  text-align: center;
  margin-bottom: 5px;
}
#fm-pivot-view div.fm-ui-datepicker .fm-ui-dp-footer .fm-cancel-btn {
  display: inline-block;
  padding: 10px;
  color: #999;
}
#fm-pivot-view div.fm-ui-datepicker .fm-ui-dp-time {
  padding: 10px;
  border-top: 1px solid #d5d5d5;
  text-align: center;
}
#fm-pivot-view div.fm-ui-datepicker .fm-ui-dp-time .fm-ui-label {
  margin-right: 5px;
}
#fm-pivot-view div.fm-ui-datepicker .fm-ui-dp-time input.fm-ui-dp-time-input {
  padding: 2px 1px 2px 4px;
  border: 1px solid #d5d5d5;
  color: #111;
  font-size: 14px;
  text-align: center;
}
#fm-pivot-view div.fm-ui-datepicker.fm-ui-dp-view-months .fm-ui-dp-header .fm-ui-btn {
  display: none;
}
#fm-pivot-view div.fm-ui-datepicker.fm-ui-dp-view-months .fm-ui-dp-week {
  display: none;
}
#fm-pivot-view div.fm-ui-datepicker.fm-ui-dp-view-months .fm-ui-dp-dates {
  padding-bottom: 0;
}
#fm-pivot-view div.fm-ui-datepicker.fm-ui-dp-view-months .fm-ui-dp-footer {
  display: block;
}
#fm-pivot-view div.fm-ui-datepicker.fm-ui-dp-view-years .fm-ui-dp-header .fm-ui-dp-month {
  display: none;
}
#fm-pivot-view div.fm-ui-datepicker.fm-ui-dp-view-years .fm-ui-dp-week {
  display: none;
}
#fm-pivot-view div.fm-ui-datepicker.fm-ui-dp-view-years .fm-ui-dp-dates {
  padding-bottom: 0;
}
#fm-pivot-view div.fm-ui-datepicker.fm-ui-dp-view-years .fm-ui-dp-footer {
  display: block;
}
#fm-pivot-view div.fm-ui-datepicker-input {
  position: relative;
}
#fm-pivot-view div.fm-ui-datepicker-input.fm-opened .fm-ui-datepicker-dropdown {
  display: block;
}
#fm-pivot-view div.fm-ui-datepicker-input .fm-ui-datepicker-dropdown {
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  display: none;
  background-color: #fff;
  border: 1px solid #d5d5d5;
  position: absolute;
  width: 300px;
  right: 0;
  top: 100%;
  margin-top: 5px;
  z-index: 1;
}
#fm-pivot-view div.fm-ui-datepicker-input .fm-ui-datepicker-dropdown:before,
#fm-pivot-view div.fm-ui-datepicker-input .fm-ui-datepicker-dropdown:after {
  content: '';
  position: absolute;
  display: block;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
#fm-pivot-view div.fm-ui-datepicker-input .fm-ui-datepicker-dropdown:before {
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 5.5px 6px 5.5px;
  border-color: transparent transparent #d5d5d5 transparent;
  top: -7px;
}
#fm-pivot-view div.fm-ui-datepicker-input .fm-ui-datepicker-dropdown:after {
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 4.5px 6px 4.5px;
  border-color: transparent transparent #fff transparent;
  top: -6px;
}
#fm-pivot-view div.fm-ui-datepicker-input .fm-ui-datepicker-dropdown:after,
#fm-pivot-view div.fm-ui-datepicker-input .fm-ui-datepicker-dropdown:before {
  left: auto;
}
#fm-pivot-view div.fm-ui-datepicker-input .fm-ui-datepicker-dropdown:after {
  right: 8px;
}
#fm-pivot-view div.fm-ui-datepicker-input .fm-ui-datepicker-dropdown:before {
  right: 6px;
}
#fm-pivot-view div.fm-ui-datepicker-input .fm-ui-datepicker-icon {
  right: 0;
  font-size: 20px;
  color: #999;
  cursor: pointer;
  width: 35px;
  height: 100%;
  position: absolute;
}
#fm-pivot-view div.fm-ui-datepicker-input .fm-ui-datepicker-icon:after {
  display: block;
  position: absolute;
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -ms-transform: translateY(-50%) translateX(-50%);
  -moz-transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
  content: "\e900";
}
#fm-toolbar-wrapper .fm-ui .fm-ui-row,
#fm-pivot-view .fm-ui .fm-ui-row {
  font-size: 0;
}
#fm-toolbar-wrapper .fm-ui .fm-ui-col,
#fm-pivot-view .fm-ui .fm-ui-col,
#fm-toolbar-wrapper .fm-ui .fm-ui-col-2,
#fm-pivot-view .fm-ui .fm-ui-col-2,
#fm-toolbar-wrapper .fm-ui .fm-ui-col-3,
#fm-pivot-view .fm-ui .fm-ui-col-3,
#fm-toolbar-wrapper .fm-ui .fm-ui-col-9,
#fm-pivot-view .fm-ui .fm-ui-col-9 {
  display: inline-block;
  vertical-align: top;
  min-height: 1px;
}
#fm-toolbar-wrapper .fm-ui .fm-ui-col-2,
#fm-pivot-view .fm-ui .fm-ui-col-2 {
  width: 50%;
}
#fm-toolbar-wrapper .fm-ui .fm-ui-col-3,
#fm-pivot-view .fm-ui .fm-ui-col-3 {
  margin-right: 30px;
  width: -webkit-calc(33.33% - 20px);
  width: calc(33.33% - 20px);
}
#fm-toolbar-wrapper .fm-ui .fm-ui-col-3:nth-child(3n+3),
#fm-pivot-view .fm-ui .fm-ui-col-3:nth-child(3n+3) {
  margin-right: 0;
}
#fm-toolbar-wrapper .fm-ui .fm-ui-col-9,
#fm-pivot-view .fm-ui .fm-ui-col-9 {
  margin-right: 1px;
  margin-bottom: 1px;
  width: -webkit-calc(11.11% - 0.9px);
  width: calc(11.11% - 0.9px);
}
#fm-toolbar-wrapper .fm-ui .fm-ui-col-9:nth-child(9n+9),
#fm-pivot-view .fm-ui .fm-ui-col-9:nth-child(9n+9) {
  margin-right: 0;
}
#fm-toolbar-wrapper .fm-ui .fm-vam,
#fm-pivot-view .fm-ui .fm-vam {
  vertical-align: middle;
}
#fm-toolbar-wrapper .fm-ui .fm-vat,
#fm-pivot-view .fm-ui .fm-vat {
  vertical-align: top;
}
#fm-toolbar-wrapper .fm-ui .fm-tar,
#fm-pivot-view .fm-ui .fm-tar {
  text-align: right;
}
#fm-toolbar-wrapper .fm-ui .fm-tal,
#fm-pivot-view .fm-ui .fm-tal {
  text-align: left;
}
#fm-toolbar-wrapper .fm-ui .fm-tac,
#fm-pivot-view .fm-ui .fm-tac {
  text-align: center;
}
#fm-toolbar-wrapper .fm-ui .fm-mb30,
#fm-pivot-view .fm-ui .fm-mb30 {
  margin-bottom: 30px;
}
#fm-pivot-view .fm-helper {
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  display: inline-block;
  background: #f7f7f7;
  opacity: 0.9;
  padding: 10px 9px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  border: 1px solid #d5d5d5;
  pointer-events: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 250px;
  color: #111;
}
#fm-pivot-view .fm-helper .fm-label {
  width: calc(100% - 20px);
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
}
#fm-pivot-view .fm-helper .fm-drag-icon {
  float: right;
  position: relative;
}
#fm-pivot-view .fm-helper.fm-no-drop .fm-drag-icon:after {
  display: block;
  position: absolute;
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\ea0e";
  color: #F44336;
  right: 0;
}
#fm-pivot-view .fm-helper.fm-drag-remove .fm-drag-icon:after {
  display: block;
  position: absolute;
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e90e";
  color: #F44336;
  right: -4px;
  top: -4px;
  font-size: 21px;
}
#fm-pivot-view .fm-header-helper {
  text-transform: uppercase;
  border: none;
  background: #f7f7f7;
  padding: 8px 6px;
  font-size: 12px;
  border: 1px solid #999;
  cursor: move;
}
#fm-pivot-view .fm-header-helper.fm-drag-remove .fm-remove-icon {
  display: inline-block;
  margin-left: 4px;
  width: 10px;
  position: relative;
}
#fm-pivot-view .fm-header-helper.fm-drag-remove .fm-remove-icon:after {
  display: block;
  position: absolute;
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e90e";
  color: #F44336;
  top: -15px;
  left: -6px;
  font-size: 21px;
}
#fm-pivot-view .fm-drop-indicator {
  height: 2px;
  background: #555;
}
#fm-pivot-view div.fm-ui-panel {
  position: relative;
  background: #fff;
  border: 1px solid #999;
}
#fm-pivot-view div.fm-ui-modal-overlay {
  background-color: white;
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  height: 100%;
}
#fm-pivot-view div.fm-ui-modal-overlay.fm-ui-opaque {
  opacity: 1;
}
#fm-pivot-view div.fm-ui-modal-overlay.fm-ui-transparent {
  opacity: 0;
}
#fm-pivot-view div.fm-ui-toolbar {
  background: #fff;
  border-top: 1px dotted #dcdcdc;
  border-left: none;
  border-right: none;
  border-bottom: none;
  margin-bottom: 0px;
}
#fm-pivot-view div.fm-ui-hgroup {
  overflow: hidden;
}
#fm-pivot-view div.fm-ui-hgroup > * {
  float: left;
}
#fm-pivot-view div.fm-ui-vgroup > * {
  display: block;
}
#fm-pivot-view ul.fm-ui-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
#fm-pivot-view .fm-ui-context-submenu {
  position: absolute;
}
#fm-pivot-view .fm-ui-disabled,
#fm-toolbar-wrapper .fm-ui-disabled {
  pointer-events: none !important;
  cursor: default !important;
  opacity: 0.5 !important;
}
#fm-pivot-view div.fm-ui-vlist {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}
#fm-pivot-view div.fm-ui-vlist ul.fm-ui-list {
  position: absolute;
  overflow: hidden;
  width: 100%;
  top: 0;
  background: #fff;
}
#fm-pivot-view div.fm-ui-vlist div.fm-ui-vlist-placeholder {
  width: 100%;
}
#fm-pivot-view div.fm-ui-divider {
  background-color: #f7f7f7;
  position: absolute;
  z-index: 1;
}
#fm-pivot-view div.fm-ui-divider:hover {
  background-color: #f2f2f2;
}
#fm-pivot-view div.fm-ui-divider:before {
  display: block;
  position: absolute;
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -ms-transform: translateY(-50%) translateX(-50%);
  -moz-transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
  content: "\e907";
  font-size: 13px;
  color: #999;
}
#fm-pivot-view div.fm-ui-divider.fm-ui-horizontal {
  left: 0;
  right: 0;
  height: 9px;
}
#fm-pivot-view div.fm-ui-divider.fm-ui-horizontal:hover {
  cursor: row-resize;
}
#fm-pivot-view div.fm-ui-divider.fm-ui-horizontal:before {
  top: 4px;
}
#fm-pivot-view div.fm-ui-divider.fm-ui-vertical {
  top: 0;
  bottom: 0;
  width: 9px;
}
#fm-pivot-view div.fm-ui-divider.fm-ui-vertical:hover {
  cursor: col-resize;
}
#fm-pivot-view div.fm-ui-divider.fm-ui-vertical:before {
  left: -2px;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  transform: rotate(90deg);
  -o-transform: rotate(90deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
}
#fm-pivot-view .fm-ui-toolbar-mobile {
  height: 48px;
  border-bottom: 1px solid #999;
}
#fm-pivot-view .fm-ui-toolbar-mobile .fm-ui-header-display {
  pointer-events: none;
  position: absolute;
  top: 12px;
  font-size: 17px;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  display: inline-block;
  width: 100%;
}
@media only screen and (max-width: 560px) {
  #fm-pivot-view .fm-ui-toolbar-mobile .fm-ui-header-display {
    font-size: 14px;
    top: 15px;
  }
}
#fm-pivot-view .fm-ui-right {
  position: absolute;
  right: 0;
  top: 0;
}
#fm-pivot-view .fm-ui-left {
  position: absolute;
  left: 0;
  top: 0;
}
#fm-pivot-view a.fm-ui-btn.fm-disabled {
  pointer-events: none;
  cursor: default;
  color: #aaa;
  opacity: 0.4;
}
#fm-pivot-view a.fm-ui-btn:hover.fm-disabled {
  background: #fff;
}
#fm-pivot-view a.fm-ui-btn:active.fm-disabled {
  background: #fff;
}
#fm-pivot-view .fm-ui-context-menu {
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  position: absolute;
  background-color: #fff;
  border: 1px solid #999;
  z-index: 20;
}
#fm-pivot-view .fm-ui-context-menu .fm-ui-context-submenu {
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  position: absolute;
  background-color: #fff;
  border: 1px solid #999;
  z-index: 21;
}
#fm-pivot-view .fm-ui-context-menu .fm-ui-context-submenu .fm-ui-list {
  max-height: 300px;
  overflow-y: auto;
}
#fm-pivot-view .fm-ui-context-menu .fm-ui-list {
  min-width: 200px;
}
#fm-pivot-view .fm-ui-context-menu .fm-ui-list li {
  padding: 10px;
  border-bottom: 1px solid #e9e9e9;
  cursor: pointer;
  position: relative;
}
#fm-pivot-view .fm-ui-context-menu .fm-ui-list li:last-child {
  border-bottom: none;
}
#fm-pivot-view .fm-ui-context-menu .fm-ui-list li.fm-ui-active,
#fm-pivot-view .fm-ui-context-menu .fm-ui-list li:hover {
  background-color: #f7f7f7;
}
#fm-pivot-view .fm-ui-context-menu .fm-ui-list li.fm-has-submenu:after {
  display: block;
  position: absolute;
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  content: "\e911";
  right: 5px;
  font-size: 15px;
}
#fm-pivot-view .fm-ui-context-menu .fm-ui-list li.fm-selected {
  background: #f7f7f7;
  padding-right: 28px;
}
#fm-pivot-view .fm-ui-context-menu .fm-ui-list li.fm-selected:before {
  display: block;
  position: absolute;
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: '\e902';
  right: 30px;
  top: 6px;
  color: #999;
  font-size: 26px;
}
/* ===== PIVOT UI END ===== */
/* Filter */
#fm-pivot-view .fm-filter-view {
  min-width: 320px;
  /* layouts */
}
#fm-pivot-view .fm-filter-view.fm-ui-window {
  padding: 0;
}
#fm-pivot-view .fm-filter-view .fm-filter-view-content {
  padding: 24px 30px 30px;
  width: 500px;
  min-width: 320px;
  max-width: 100%;
  height: 386px;
  min-height: 386px;
}
#fm-pivot-view .fm-filter-view .fm-filter-view-content.fm-query-filter-opened {
  height: 256px;
  min-height: 256px;
}
#fm-pivot-view .fm-filter-view .fm-popup-header {
  margin-bottom: 25px;
}
#fm-pivot-view .fm-filter-view .fm-popup-header .fm-ui-row .fm-ui-btns-row {
  white-space: nowrap;
  position: absolute;
  right: 0;
}
#fm-pivot-view .fm-filter-view .fm-popup-header .fm-popup-title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
#fm-pivot-view .fm-filter-view .fm-bold-btn {
  font-weight: bold;
  text-transform: uppercase;
}
#fm-pivot-view .fm-filter-view .fm-filter-sort-row {
  display: flex;
  position: relative;
  margin-bottom: 20px;
}
#fm-pivot-view .fm-filter-view .fm-filter-sort-row .fm-sort-col {
  position: absolute;
  right: 0;
  top: 0;
}
#fm-pivot-view .fm-filter-view .fm-filter-sort-row .fm-sort-col a.fm-ui-toggle-btn.fm-sort-btn,
#fm-pivot-view .fm-filter-view .fm-filter-sort-row .fm-sort-col button.fm-ui-toggle-btn.fm-sort-btn {
  width: 60px;
  font-weight: bold;
  text-transform: uppercase;
}
#fm-pivot-view .fm-filter-view .fm-filter-sort-row .fm-sort-col .fm-sort-label {
  margin-right: 10px;
  display: none;
}
#fm-pivot-view .fm-filter-view .fm-filter-sort-row .fm-sort-col .fm-sort-btns {
  display: inline-block;
}
#fm-pivot-view .fm-filter-view .fm-filter-sort-row .fm-sort-col .fm-sort-btns .fm-sort-btn:first-child {
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
#fm-pivot-view .fm-filter-view .fm-filter-sort-row .fm-sort-col .fm-sort-btns .fm-sort-btn:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
#fm-pivot-view .fm-filter-view .fm-filter-sort-row .fm-filters-col .fm-filter-label {
  width: 55px;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
  vertical-align: middle;
  line-height: 1;
  overflow: hidden;
}
#fm-pivot-view .fm-filter-view .fm-filter-sort-row .fm-filters-col a.fm-ui-toggle-btn,
#fm-pivot-view .fm-filter-view .fm-filter-sort-row .fm-filters-col button.fm-ui-toggle-btn {
  color: #111;
}
#fm-pivot-view .fm-filter-view .fm-sort-only .fm-filter-sort-row .fm-sort-col {
  position: relative;
  display: block;
}
#fm-pivot-view .fm-filter-view .fm-sort-only .fm-filter-sort-row .fm-sort-col .fm-sort-label {
  display: inline-block;
}
#fm-pivot-view .fm-filter-view .fm-filter-btn {
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  min-width: 90px;
  max-width: 130px;
  padding-left: 10px;
  padding-right: 10px;
  height: 38px;
}
#fm-pivot-view .fm-filter-view .fm-filter-btn.fm-has-filter {
  padding-right: 20px;
}
#fm-pivot-view .fm-filter-view .fm-filter-btn.fm-has-filter:after {
  display: block;
  position: absolute;
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  right: 5px;
  content: "\e905";
  font-size: 18px;
}
#fm-pivot-view .fm-filter-view .fm-filter-btn.fm-labels-filter-btn {
  margin-right: 20px;
}
#fm-pivot-view .fm-filter-view .fm-filter-btn .fm-ui-arrow-up {
  display: none;
  position: relative;
  top: 22px;
  z-index: 1;
}
#fm-pivot-view .fm-filter-view .fm-filter-btn .fm-ui-arrow-up:after {
  border-bottom-color: #fbfbfb;
  top: -7px;
}
#fm-pivot-view .fm-filter-view .fm-filter-btn .fm-ui-arrow-up:before {
  top: -8px;
}
#fm-pivot-view .fm-filter-view .fm-filter-btn.fm-selected .fm-ui-arrow-up {
  display: block;
}
#fm-pivot-view .fm-filter-view .fm-filter-btn .fm-ui-label {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  vertical-align: middle;
  margin-bottom: 2px;
  line-height: 36px;
}
#fm-pivot-view .fm-filter-view .fm-filters-table {
  border: 1px solid #d5d5d5;
  margin-bottom: 30px;
  margin-top: 10px;
  height: calc(100% - 108px);
  height: -webkit-calc(100% - 108px);
}
#fm-pivot-view .fm-filter-view .fm-filters-table .fm-ui-checkbox.fm-selected {
  font-weight: bold;
}
#fm-pivot-view .fm-filter-view .fm-filters-table .fm-filters-table-content {
  position: relative;
  height: calc(100% - 37px);
  height: -webkit-calc(100% - 37px);
}
#fm-pivot-view .fm-filter-view .fm-filters-table .fm-filters-table-content .fm-members-filter-list {
  overflow-y: auto;
  height: 100%;
}
#fm-pivot-view .fm-filter-view .fm-filters-table .fm-filters-table-content .fm-members-filter-list li {
  border-bottom: 1px solid #e9e9e9;
  padding: 10px 9px;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 37px;
}
#fm-pivot-view .fm-filter-view .fm-filters-table .fm-filters-table-content .fm-members-filter-list li .fm-expand-toggle-btn {
  color: #999;
  display: inline-block;
  font-size: 14px;
  position: relative;
  height: 100%;
  width: 30px;
}
#fm-pivot-view .fm-filter-view .fm-filters-table .fm-filters-table-content .fm-members-filter-list li .fm-expand-toggle-btn:before {
  display: block;
  position: absolute;
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e911";
  top: -7px;
  text-align: center;
  width: 30px;
  padding: 8px 0;
}
#fm-pivot-view .fm-filter-view .fm-filters-table .fm-filters-table-content .fm-members-filter-list li .fm-expand-toggle-btn.fm-selected:before {
  content: "\e90f";
}
#fm-pivot-view .fm-filter-view .fm-filters-table .fm-filters-table-content .fm-members-filter-list li .fm-ungroup-btn {
  position: absolute;
  right: 10px;
}
#fm-pivot-view .fm-filter-view .fm-filters-table .fm-filters-table-content .fm-members-filter-list li .fm-ui-checkbox {
  vertical-align: top;
  margin-top: -1px;
}
#fm-pivot-view .fm-filter-view .fm-filters-table .fm-filters-table-content .fm-members-filter-list.fm-tree-list:not(.fm-is-searched) li {
  padding-left: 0;
}
#fm-pivot-view .fm-filter-view .fm-filters-table .fm-search-highlight {
  background-color: #FFFF00;
  font-weight: inherit;
}
#fm-pivot-view .fm-filter-view .fm-filters-table .fm-filters-table-header {
  border-bottom: 1px solid #d5d5d5;
  background: #f7f7f7;
  position: relative;
  height: 37px;
}
#fm-pivot-view .fm-filter-view .fm-filters-table .fm-filters-table-header .fm-select-all-wrapper {
  width: 50%;
  padding: 9px;
}
#fm-pivot-view .fm-filter-view .fm-filters-table .fm-filters-table-header .fm-select-all-wrapper .fm-select-all-label {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  height: 18px;
}
#fm-pivot-view .fm-filter-view .fm-filters-table .fm-filters-table-header .fm-select-counter-wrap {
  width: calc(50% - 100px);
  width: -webkit-calc(50% - 100px);
  padding-top: 12px;
  padding-right: 9px;
}
#fm-pivot-view .fm-filter-view .fm-filters-table .fm-filters-table-header .fm-select-counter-wrap .fm-select-counter {
  width: 100%;
  text-align: right;
  color: #999;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
#fm-pivot-view .fm-filter-view .fm-filters-table .fm-filters-table-header .fm-search-wrap {
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  height: 100%;
  border-left: 1px solid #d5d5d5;
}
#fm-pivot-view .fm-filter-view .fm-filters-table .fm-filters-table-header .fm-search-wrap .fm-search-inp {
  position: absolute;
  right: 0;
  width: 100%;
  height: 36px;
  padding-left: 39px;
  padding-right: 39px;
  color: #111;
  font-size: 14px;
  background: #fff;
  opacity: 0;
  border: none;
  visibility: hidden;
  border-left: 1px solid #d5d5d5;
}
#fm-pivot-view .fm-filter-view .fm-filters-table .fm-filters-table-header .fm-search-wrap .fm-close-search {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 36px;
  min-width: 36px;
  border: none;
  background: transparent;
  cursor: pointer;
  color: #999;
  outline-offset: -2px;
}
#fm-pivot-view .fm-filter-view .fm-filters-table .fm-filters-table-header .fm-search-wrap .fm-close-search:before {
  display: block;
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e903";
  font-size: 26px;
  position: absolute;
  top: 5px;
  left: 6px;
}
#fm-pivot-view .fm-filter-view .fm-filters-table .fm-filters-table-header .fm-search-wrap .fm-close-search:hover {
  color: #555;
}
#fm-pivot-view .fm-filter-view .fm-filters-table .fm-filters-table-header .fm-search-wrap .fm-search-btn {
  position: absolute;
  height: 36px;
  width: 100%;
  font-size: 14px;
  cursor: pointer;
  border: none;
  background: transparent;
  color: #999;
  padding-top: 10px;
  left: 10px;
  right: 25px;
}
#fm-pivot-view .fm-filter-view .fm-filters-table .fm-filters-table-header .fm-search-wrap .fm-search-btn:after {
  display: block;
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e90a";
  font-size: 25px;
  color: #999;
  position: absolute;
  top: 5px;
  right: 14px;
}
#fm-pivot-view .fm-filter-view .fm-filters-table .fm-filters-table-header .fm-search-wrap .fm-search-btn:hover {
  color: #111;
}
#fm-pivot-view .fm-filter-view .fm-filters-table .fm-filters-table-header .fm-search-wrap .fm-search-btn:hover:after {
  color: #111;
}
#fm-pivot-view .fm-filter-view .fm-filters-table .fm-filters-table-header .fm-search-wrap.fm-search-opened {
  width: 50%;
  height: 100%;
  border-left: none;
}
#fm-pivot-view .fm-filter-view .fm-filters-table .fm-filters-table-header .fm-search-wrap.fm-search-opened .fm-close-search,
#fm-pivot-view .fm-filter-view .fm-filters-table .fm-filters-table-header .fm-search-wrap.fm-search-opened .fm-search-inp {
  visibility: visible;
  opacity: 1;
}
#fm-pivot-view .fm-filter-view .fm-filters-table .fm-filters-table-header .fm-search-wrap.fm-search-opened .fm-select-counter {
  display: none;
}
#fm-pivot-view .fm-filter-view .fm-filters-table .fm-filters-table-header .fm-search-wrap.fm-search-opened .fm-search-btn {
  font-size: 0;
  width: 40px;
  pointer-events: none;
  z-index: 1;
}
#fm-pivot-view .fm-filter-view .fm-filters-table .fm-filters-table-header .fm-search-wrap.fm-search-opened .fm-search-btn .fm-icon-act_search:before {
  color: #e9e9e9;
}
#fm-pivot-view .fm-filter-view .fm-filters-table:last-child {
  margin-bottom: 0;
}
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap .fm-filters-subview {
  background: #fbfbfb;
  border: 1px solid #e9e9e9;
  position: relative;
  padding: 14px 13px;
  font-size: 0;
}
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap .fm-buttons-bar {
  text-align: right;
  margin-top: 10px;
}
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap .fm-buttons-bar .fm-ui-link-btn.fm-muted {
  color: #999;
}
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-values-filter-view .fm-measures-dropdown,
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-values-filter-view .fm-conditions-dropdown,
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-values-filter-view .fm-num-input-1 {
  width: calc(33.33% - 6.66px);
  width: -webkit-calc(33.33% - 6.66px);
}
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-values-filter-view .fm-measures-dropdown,
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-values-filter-view .fm-conditions-dropdown {
  margin-right: 10px;
}
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-values-filter-view .fm-num-input-2 {
  display: none;
}
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-values-filter-view.fm-range .fm-measures-dropdown,
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-values-filter-view.fm-range .fm-conditions-dropdown,
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-values-filter-view.fm-range .fm-num-input-1,
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-values-filter-view.fm-range .fm-num-input-2 {
  width: calc(25% - 7.5px);
  width: -webkit-calc(25% - 7.5px);
}
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-values-filter-view.fm-range .fm-num-input-1 {
  margin-right: 10px;
}
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-values-filter-view.fm-range .fm-num-input-2 {
  display: inline-block;
}
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-labels-filter-view .fm-conditions-dropdown,
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-labels-filter-view .fm-txt-input-1 {
  width: calc(50% - 5px);
  width: -webkit-calc(50% - 5px);
}
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-labels-filter-view .fm-conditions-dropdown {
  margin-right: 10px;
}
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-labels-filter-view .fm-txt-input-2 {
  display: none;
}
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-labels-filter-view.fm-range .fm-conditions-dropdown,
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-labels-filter-view.fm-range .fm-txt-input-1,
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-labels-filter-view.fm-range .fm-txt-input-2 {
  width: calc(33.33% - 6.66px);
  width: -webkit-calc(33.33% - 6.66px);
}
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-labels-filter-view.fm-range .fm-txt-input-1 {
  margin-right: 10px;
}
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-labels-filter-view.fm-range .fm-txt-input-2 {
  display: inline-block;
}
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-numbers-filter-view .fm-conditions-dropdown,
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-numbers-filter-view .fm-num-input-1 {
  width: calc(50% - 5px);
  width: -webkit-calc(50% - 5px);
}
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-numbers-filter-view .fm-conditions-dropdown {
  margin-right: 10px;
}
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-numbers-filter-view .fm-num-input-2 {
  display: none;
}
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-numbers-filter-view.fm-range .fm-conditions-dropdown,
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-numbers-filter-view.fm-range .fm-num-input-1,
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-numbers-filter-view.fm-range .fm-num-input-2 {
  width: calc(33.33% - 6.66px);
  width: -webkit-calc(33.33% - 6.66px);
}
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-numbers-filter-view.fm-range .fm-num-input-1 {
  margin-right: 10px;
}
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-numbers-filter-view.fm-range .fm-num-input-2 {
  display: inline-block;
}
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-time-filter-view .fm-conditions-dropdown,
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-time-filter-view .fm-num-input-1 {
  width: calc(50% - 45px);
  width: -webkit-calc(50% - 45px);
  margin-right: 10px;
}
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-time-filter-view .fm-interval-dropdown {
  width: 70px;
}
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-time-filter-view .fm-num-input-2 {
  display: none;
  margin-right: 10px;
}
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-time-filter-view.fm-range .fm-num-input-1,
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-time-filter-view.fm-range .fm-num-input-2 {
  width: calc(20% - 7px);
  width: -webkit-calc(20% - 7px);
}
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-time-filter-view.fm-range .fm-num-input-2 {
  display: inline-block;
}
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-dates-filter-view .fm-conditions-dropdown,
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-dates-filter-view .fm-datepicker-1,
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-dates-filter-view .fm-periods-dropdown {
  width: calc(50% - 5px);
  width: -webkit-calc(50% - 5px);
}
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-dates-filter-view .fm-conditions-dropdown {
  margin-right: 10px;
}
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-dates-filter-view .fm-datepicker-1 {
  display: inline-block;
}
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-dates-filter-view .fm-datepicker-2,
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-dates-filter-view .fm-periods-dropdown {
  display: none;
}
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-dates-filter-view.fm-date-range .fm-conditions-dropdown,
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-dates-filter-view.fm-date-range .fm-datepicker-1,
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-dates-filter-view.fm-date-range .fm-datepicker-2 {
  width: calc(33.33% - 6.66px);
  width: -webkit-calc(33.33% - 6.66px);
}
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-dates-filter-view.fm-date-range .fm-datepicker-1 {
  margin-right: 10px;
}
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-dates-filter-view.fm-date-range .fm-datepicker-2 {
  display: inline-block;
}
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-dates-filter-view.fm-date-range .fm-ui-datepicker-input.fm-with-time .fm-ui-datepicker-icon {
  display: none;
}
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-dates-filter-view.fm-date-period .fm-datepicker-1,
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-dates-filter-view.fm-date-period .fm-datepicker-2 {
  display: none;
}
#fm-pivot-view .fm-filter-view .fm-filters-subview-wrap.fm-dates-filter-view.fm-date-period .fm-periods-dropdown {
  display: inline-block;
}
#fm-pivot-view .fm-filter-view .fm-add-group-view {
  margin-bottom: 20px;
}
#fm-pivot-view .fm-filter-view .fm-add-group-view .fm-filters-subview:before {
  right: 150px;
}
#fm-pivot-view .fm-filter-view .fm-add-group-view .fm-filters-subview:after {
  right: 151px;
}
#fm-pivot-view .fm-filter-view .fm-filter-flat-layout .fm-values-filter-view .fm-measures-dropdown {
  display: none;
}
#fm-pivot-view .fm-filter-view .fm-filter-flat-layout .fm-values-filter-view .fm-conditions-dropdown,
#fm-pivot-view .fm-filter-view .fm-filter-flat-layout .fm-values-filter-view .fm-num-input-1 {
  width: calc(50% - 5px);
  width: -webkit-calc(50% - 5px);
}
#fm-pivot-view .fm-filter-view .fm-filter-flat-layout .fm-values-filter-view .fm-num-input-1 {
  margin-right: 0;
}
#fm-pivot-view .fm-filter-view .fm-filter-flat-layout .fm-values-filter-view.fm-range .fm-measures-dropdown,
#fm-pivot-view .fm-filter-view .fm-filter-flat-layout .fm-values-filter-view.fm-range .fm-conditions-dropdown,
#fm-pivot-view .fm-filter-view .fm-filter-flat-layout .fm-values-filter-view.fm-range .fm-num-input-1,
#fm-pivot-view .fm-filter-view .fm-filter-flat-layout .fm-values-filter-view.fm-range .fm-num-input-2 {
  width: calc(33.33% - 6.66px);
  width: -webkit-calc(33.33% - 6.66px);
}
#fm-pivot-view .fm-filter-view .fm-filter-pages-layout .fm-filters-col {
  width: 100%;
}
#fm-pivot-view .fm-filter-view .fm-filter-pages-layout .fm-labels-filter-btn {
  margin-right: 0;
}
#fm-pivot-view .fm-filter-view .fm-filter-pages-layout.fm-no-query-filters .fm-filters-table,
#fm-pivot-view .fm-filter-view .fm-filter-flat-layout.fm-no-query-filters .fm-filters-table {
  height: calc(100% - 60px);
  height: -webkit-calc(100% - 60px);
}
#fm-pivot-view .fm-filter-view .fm-filter-pages-layout.fm-no-query-filters .fm-filter-sort-row,
#fm-pivot-view .fm-filter-view .fm-filter-flat-layout.fm-no-query-filters .fm-filter-sort-row {
  margin-bottom: 0;
}
#fm-pivot-view .fm-filter-view.fm-layout-mobile-small .fm-filter-view-content {
  padding: 20px;
}
#fm-pivot-view .fm-filter-view.fm-layout-mobile-small .fm-filter-view-content.fm-query-filter-opened {
  height: 384px;
  min-height: 384px;
}
#fm-pivot-view .fm-filter-view.fm-layout-mobile-small .fm-filter-view-content.fm-query-filter-opened.fm-filter-pages-layout,
#fm-pivot-view .fm-filter-view.fm-layout-mobile-small .fm-filter-view-content.fm-query-filter-opened.fm-filter-flat-layout {
  height: 288px;
  min-height: 288px;
}
#fm-pivot-view .fm-filter-view.fm-layout-mobile-small .fm-popup-header .fm-ui-col,
#fm-pivot-view .fm-filter-view.fm-layout-mobile-small .fm-popup-header .fm-ui-col-2 {
  width: 100%;
}
#fm-pivot-view .fm-filter-view.fm-layout-mobile-small .fm-popup-header .fm-ui-col-2 {
  margin-bottom: 10px;
}
#fm-pivot-view .fm-filter-view.fm-layout-mobile-small .fm-popup-header .fm-ui-btns-row {
  position: relative;
}
#fm-pivot-view .fm-filter-view.fm-layout-mobile-small .fm-popup-header .fm-popup-title {
  line-height: 1.1;
}
#fm-pivot-view .fm-filter-view.fm-layout-mobile-small .fm-filter-sort-row {
  flex-direction: column;
}
#fm-pivot-view .fm-filter-view.fm-layout-mobile-small .fm-filter-sort-row .fm-sort-col {
  order: 1;
  position: relative;
  margin-bottom: 10px;
}
#fm-pivot-view .fm-filter-view.fm-layout-mobile-small .fm-filter-sort-row .fm-sort-col .fm-sort-btns {
  display: block;
}
#fm-pivot-view .fm-filter-view.fm-layout-mobile-small .fm-filter-sort-row .fm-sort-col .fm-sort-btns a.fm-ui-toggle-btn.fm-sort-btn,
#fm-pivot-view .fm-filter-view.fm-layout-mobile-small .fm-filter-sort-row .fm-sort-col .fm-sort-btns button.fm-ui-toggle-btn.fm-sort-btn {
  width: 50%;
}
#fm-pivot-view .fm-filter-view.fm-layout-mobile-small .fm-filter-sort-row .fm-sort-col .fm-sort-label {
  display: none;
}
#fm-pivot-view .fm-filter-view.fm-layout-mobile-small .fm-filter-sort-row .fm-filters-col {
  order: 2;
}
#fm-pivot-view .fm-filter-view.fm-layout-mobile-small .fm-filter-sort-row .fm-filters-col .fm-filter-label {
  display: none;
}
#fm-pivot-view .fm-filter-view.fm-layout-mobile-small .fm-filter-sort-row .fm-filters-col .fm-filter-btn {
  width: calc(50% - 5px);
  width: -webkit-calc(50% - 5px);
  max-width: 50%;
}
#fm-pivot-view .fm-filter-view.fm-layout-mobile-small .fm-filter-sort-row .fm-filters-col .fm-filter-btn.fm-labels-filter-btn {
  margin-right: 10px;
}
#fm-pivot-view .fm-filter-view.fm-layout-mobile-small .fm-filters-subview-wrap.fm-values-filter-view .fm-measures-dropdown,
#fm-pivot-view .fm-filter-view.fm-layout-mobile-small .fm-filters-subview-wrap.fm-values-filter-view .fm-conditions-dropdown {
  width: calc(50% - 5px);
  width: -webkit-calc(50% - 5px);
  margin-right: 0;
  margin-bottom: 10px;
}
#fm-pivot-view .fm-filter-view.fm-layout-mobile-small .fm-filters-subview-wrap.fm-values-filter-view .fm-num-input-1 {
  width: 100%;
}
#fm-pivot-view .fm-filter-view.fm-layout-mobile-small .fm-filters-subview-wrap.fm-values-filter-view .fm-measures-dropdown {
  margin-right: 10px;
}
#fm-pivot-view .fm-filter-view.fm-layout-mobile-small .fm-filters-subview-wrap.fm-values-filter-view.fm-range .fm-num-input-1,
#fm-pivot-view .fm-filter-view.fm-layout-mobile-small .fm-filters-subview-wrap.fm-values-filter-view.fm-range .fm-num-input-2 {
  width: calc(50% - 5px);
  width: -webkit-calc(50% - 5px);
}
#fm-pivot-view .fm-filter-view.fm-layout-mobile-small .fm-filters-subview-wrap.fm-values-filter-view.fm-range .fm-num-input-1 {
  margin-right: 10px;
}
#fm-pivot-view .fm-filter-view.fm-layout-mobile-small .fm-filters-subview-wrap.fm-dates-filter-view.fm-date-range .fm-conditions-dropdown {
  width: 100%;
  margin-bottom: 10px;
}
#fm-pivot-view .fm-filter-view.fm-layout-mobile-small .fm-filters-subview-wrap.fm-dates-filter-view.fm-date-range .fm-datepicker-1,
#fm-pivot-view .fm-filter-view.fm-layout-mobile-small .fm-filters-subview-wrap.fm-dates-filter-view.fm-date-range .fm-datepicker-2 {
  width: calc(50% - 5px);
  width: -webkit-calc(50% - 5px);
}
#fm-pivot-view .fm-filter-view.fm-layout-mobile-small .fm-filters-subview-wrap.fm-dates-filter-view.fm-date-range .fm-ui-datepicker-input.fm-with-time .fm-ui-datepicker-icon {
  display: none;
}
#fm-pivot-view .fm-filter-view.fm-layout-mobile-small .fm-filters-subview-wrap.fm-labels-filter-view.fm-range .fm-conditions-dropdown {
  width: 100%;
  margin-bottom: 10px;
}
#fm-pivot-view .fm-filter-view.fm-layout-mobile-small .fm-filters-subview-wrap.fm-labels-filter-view.fm-range .fm-txt-input-1,
#fm-pivot-view .fm-filter-view.fm-layout-mobile-small .fm-filters-subview-wrap.fm-labels-filter-view.fm-range .fm-txt-input-2 {
  width: calc(50% - 5px);
  width: -webkit-calc(50% - 5px);
}
#fm-pivot-view .fm-filter-view.fm-layout-mobile-small .fm-filters-subview-wrap.fm-numbers-filter-view.fm-range .fm-conditions-dropdown {
  width: 100%;
  margin-bottom: 10px;
}
#fm-pivot-view .fm-filter-view.fm-layout-mobile-small .fm-filters-subview-wrap.fm-numbers-filter-view.fm-range .fm-num-input-1,
#fm-pivot-view .fm-filter-view.fm-layout-mobile-small .fm-filters-subview-wrap.fm-numbers-filter-view.fm-range .fm-num-input-2 {
  width: calc(50% - 5px);
  width: -webkit-calc(50% - 5px);
}
#fm-pivot-view .fm-filter-view.fm-layout-mobile-small .fm-filters-subview-wrap.fm-time-filter-view.fm-range .fm-conditions-dropdown {
  width: 100%;
  margin-bottom: 10px;
}
#fm-pivot-view .fm-filter-view.fm-layout-mobile-small .fm-filters-subview-wrap.fm-time-filter-view.fm-range .fm-num-input-1,
#fm-pivot-view .fm-filter-view.fm-layout-mobile-small .fm-filters-subview-wrap.fm-time-filter-view.fm-range .fm-num-input-2 {
  width: calc(50% - 45px);
  width: -webkit-calc(50% - 45px);
}
#fm-pivot-view .fm-filter-view.fm-layout-mobile-small .fm-filters-table {
  height: calc(100% - 202px);
  height: -webkit-calc(100% - 202px);
}
#fm-pivot-view .fm-filter-view.fm-layout-mobile-small .fm-filters-table .fm-filters-table-header .fm-select-all-wrapper {
  width: calc(100% - 100px);
  width: -webkit-calc(100% - 100px);
}
#fm-pivot-view .fm-filter-view.fm-layout-mobile-small .fm-filters-table .fm-filters-table-header .fm-select-counter-wrap {
  display: none;
}
#fm-pivot-view .fm-filter-view.fm-layout-mobile-small .fm-no-query-filters .fm-sort-col {
  margin-bottom: 0;
}
#fm-pivot-view .fm-filter-view.fm-layout-mobile-small .fm-no-query-filters .fm-filters-table {
  height: calc(100% - 154px);
  height: -webkit-calc(100% - 154px);
}
#fm-pivot-view .fm-filter-view.fm-layout-mobile-small .fm-filter-pages-layout .fm-filters-table,
#fm-pivot-view .fm-filter-view.fm-layout-mobile-small .fm-filter-flat-layout .fm-filters-table {
  height: calc(100% - 154px);
  height: -webkit-calc(100% - 154px);
}
#fm-pivot-view .fm-filter-view.fm-layout-mobile-small .fm-filter-pages-layout.fm-no-query-filters .fm-filters-table,
#fm-pivot-view .fm-filter-view.fm-layout-mobile-small .fm-filter-flat-layout.fm-no-query-filters .fm-filters-table {
  margin-top: 0;
  height: calc(100% - 96px);
  height: -webkit-calc(100% - 96px);
}
/* Pivot view */
#fm-pivot-view {
  min-width: 300px;
  min-height: 200px;
  position: relative;
  background: #fff;
  border: 1px solid #d5d5d5;
  outline: none;
  box-sizing: border-box;
}
#fm-pivot-view .fm-branding-bar {
  background-color: #fff;
  border-top: 1px solid #d5d5d5;
  width: 100%;
  height: 21px;
  position: absolute;
  bottom: 0px;
  z-index: 2;
}
#fm-pivot-view .fm-branding-bar > span {
  line-height: 21px;
  color: #999;
}
#fm-pivot-view .fm-branding-bar > span a {
  color: #ff9b79;
}
#fm-pivot-view .fm-branding-bar .fm-version-label {
  float: left;
  margin-left: 5px;
}
#fm-pivot-view .fm-branding-bar .fm-link {
  float: right;
  margin-right: 5px;
  vertical-align: top;
}
#fm-pivot-view .fm-branding-bar .fm-info-icon {
  display: inline-block;
  height: 13px;
  width: 13px;
  margin-left: 5px;
  margin-right: 2px;
  color: #d5d5d5;
  font-size: 16px;
  cursor: pointer;
}
#fm-pivot-view .fm-branding-bar .fm-info-icon:before {
  display: block;
  position: absolute;
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\ea0c";
}
#fm-pivot-view .fm-branding-bar.fm-layout-660 span,
#fm-pivot-view .fm-branding-bar.fm-layout-660 span a {
  font-size: 11px;
}
#fm-pivot-view .fm-branding-bar.fm-layout-520 .fm-version-label {
  display: none;
}
@media all and (max-width: 600px) {
  #fm-pivot-view {
    min-width: initial;
    min-width: auto;
    min-height: initial;
    min-height: auto;
  }
}
#fm-pivot-view .fm-grid-view {
  background-color: #fff;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
#fm-pivot-view .fm-grid-column {
  width: 100px;
}
#fm-pivot-view .fm-grid-row {
  height: 30px;
}
#fm-pivot-view .fm-grid-column-mobile {
  width: 100px;
}
#fm-pivot-view .fm-grid-row-mobile {
  height: 30px;
}
#fm-pivot-view span.fm-ui-label.fm-pivot-title {
  text-align: center;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 4px 10px 5px;
}
/* Charts */
#fm-pivot-view .fm-charts-view {
  background-color: #fff;
}
#fm-pivot-view .fm-charts-view span.fm-ui-label.fm-charts-msg {
  position: absolute;
  display: block;
  text-align: center;
  top: 50%;
  width: 100%;
}
#fm-pivot-view .fm-charts-view .fm-header-toolbar {
  background-color: #fbfbfb;
  border-bottom: 1px solid #d5d5d5;
  padding: 20px 20px;
}
#fm-pivot-view .fm-charts-view .fm-header-toolbar.fm-fields-list-padding {
  padding-right: 60px;
}
#fm-pivot-view .fm-charts-view .fm-header-toolbar .fm-ui-col-2 {
  white-space: nowrap;
}
#fm-pivot-view .fm-charts-view .fm-header-toolbar .fm-ui-col-2.fm-wide {
  width: calc(100% - 50px);
}
#fm-pivot-view .fm-charts-view .fm-header-toolbar .fm-ui-dropdown {
  margin-right: 10px;
  max-width: 200px;
}
#fm-pivot-view .fm-charts-view .fm-header-toolbar .fm-charts-filters-container {
  text-align: right;
  white-space: nowrap;
}
#fm-pivot-view .fm-charts-view .fm-header-toolbar .fm-charts-filters-container .fm-ui-btn {
  border-radius: 0;
  margin-right: 10px;
  height: 38px;
  padding-right: 35px;
  text-align: left;
}
#fm-pivot-view .fm-charts-view .fm-header-toolbar .fm-charts-filters-container .fm-ui-btn:last-child {
  margin-right: 0;
}
#fm-pivot-view .fm-charts-view .fm-header-toolbar .fm-charts-filters-container .fm-ui-btn:after {
  display: block;
  position: absolute;
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  content: "\e90c";
  right: 10px;
  font-size: 18px;
  letter-spacing: -2px;
  color: #999;
}
#fm-pivot-view .fm-charts-view .fm-header-toolbar .fm-charts-filters-container .fm-ui-btn:hover:after {
  color: #555;
}
#fm-pivot-view .fm-charts-view .fm-header-toolbar .fm-charts-filters-container .fm-ui-btn.fm-filtered:after {
  content: "\e905\e90c";
}
#fm-pivot-view .fm-charts-view .fm-header-toolbar .fm-charts-filters-container .fm-ui-btn.fm-filtered {
  padding-right: 48px;
}
#fm-pivot-view .fm-charts-view .fm-header-toolbar .fm-charts-filters-btn {
  width: 50px;
  margin: 0;
}
#fm-pivot-view .fm-charts-view .fm-header-toolbar .fm-charts-filters-btn .fm-ui-dropdown-btn:before {
  display: block;
  position: absolute;
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  content: "\e905";
  color: #999;
  font-size: 24px;
  left: 5px;
}
#fm-pivot-view .fm-charts-view .fm-header-toolbar .fm-charts-filters-btn .fm-ui-dropdown-btn:after {
  right: 4px;
}
#fm-pivot-view .fm-charts-view .fm-header-toolbar .fm-charts-filters-btn .fm-ui-dropdown-btn .fm-ui-label {
  font-size: 0;
}
#fm-pivot-view .fm-charts-view .fm-header-toolbar .fm-charts-filters-btn .fm-ui-dropdown-list {
  min-width: 150px;
  max-width: 200px;
  width: auto;
  width: initial;
  left: auto;
  left: initial;
  right: 0;
}
#fm-pivot-view .fm-charts-view .fm-header-toolbar .fm-charts-filters-btn .fm-ui-dropdown-list .fm-ui-list {
  width: 100% !important;
}
#fm-pivot-view .fm-charts-view .fm-header-toolbar .fm-charts-filters-btn .fm-ui-dropdown-list .fm-ui-list li {
  padding-right: 25px;
  position: relative;
}
#fm-pivot-view .fm-charts-view .fm-header-toolbar .fm-charts-filters-btn .fm-ui-dropdown-list .fm-ui-list li:after {
  display: block;
  position: absolute;
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  content: '\e90c';
  color: #999;
  right: 4px;
  font-size: 24px;
}
#fm-pivot-view .fm-charts-view .fm-header-toolbar .fm-charts-filters-btn .fm-ui-dropdown-list .fm-ui-list li.fm-filtered {
  padding-right: 55px;
}
#fm-pivot-view .fm-charts-view .fm-header-toolbar .fm-charts-filters-btn .fm-ui-dropdown-list .fm-ui-list li.fm-filtered::after {
  content: "\e905\e90c";
}
#fm-pivot-view .fm-charts-view .fm-header-toolbar .fm-charts-filters-btn .fm-ui-dropdown-list:before,
#fm-pivot-view .fm-charts-view .fm-header-toolbar .fm-charts-filters-btn .fm-ui-dropdown-list:after {
  left: auto;
  left: initial;
}
#fm-pivot-view .fm-charts-view .fm-header-toolbar .fm-charts-filters-btn .fm-ui-dropdown-list:before {
  right: 12px;
}
#fm-pivot-view .fm-charts-view .fm-header-toolbar .fm-charts-filters-btn .fm-ui-dropdown-list:after {
  right: 14px;
}
#fm-pivot-view .fm-charts-view .fm-chart {
  padding: 10px;
}
#fm-pivot-view .fm-charts-view .fm-chart-legend {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
#fm-pivot-view .fm-charts-view .fm-chart-legend .fm-chart-legend-container {
  background-color: #fbfbfb;
  border-top: 1px solid #d5d5d5;
  padding: 10px 20px;
  overflow: auto;
}
#fm-pivot-view .fm-charts-view .fm-chart-legend .fm-chart-legend-btn {
  border-left: none;
  border-bottom: none;
  border-color: #e9e9e9;
  background-color: #fbfbfb;
  min-width: auto;
  min-width: initial;
  width: 25px;
  height: 25px;
  position: absolute;
  top: -25px;
  padding: 0;
}
#fm-pivot-view .fm-charts-view .fm-chart-legend .fm-chart-legend-btn:after {
  display: block;
  position: absolute;
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;
  padding: 2px;
  color: #999;
  position: static;
}
#fm-pivot-view .fm-charts-view .fm-chart-legend .fm-chart-legend-btn.fm-hide:after {
  content: "\e908";
}
#fm-pivot-view .fm-charts-view .fm-chart-legend .fm-chart-legend-btn.fm-show:after {
  content: "\e909";
}
#fm-pivot-view .fm-charts-view .fm-chart-legend table {
  border-collapse: separate;
  margin: 0px;
  width: auto;
  width: initial;
}
#fm-pivot-view .fm-charts-view .fm-chart-legend table td {
  padding-right: 10px;
  vertical-align: top;
}
#fm-pivot-view .fm-charts-view .fm-chart-legend ul {
  list-style-type: none;
  position: relative;
  vertical-align: bottom;
  font-size: 14px;
  display: inline-table;
}
#fm-pivot-view .fm-charts-view .fm-chart-legend ul li {
  padding: 2px 0;
  white-space: nowrap;
}
#fm-pivot-view .fm-charts-view .fm-chart-legend ul li.fm-collapsed,
#fm-pivot-view .fm-charts-view .fm-chart-legend ul li.fm-expanded {
  cursor: pointer;
}
#fm-pivot-view .fm-charts-view .fm-chart-legend ul li.fm-level-1 {
  padding-left: 18px;
}
#fm-pivot-view .fm-charts-view .fm-chart-legend ul li.fm-level-2 {
  padding-left: 36px;
}
#fm-pivot-view .fm-charts-view .fm-chart-legend ul li.fm-level-3 {
  padding-left: 54px;
}
#fm-pivot-view .fm-charts-view .fm-chart-legend ul li.fm-level-4 {
  padding-left: 72px;
}
#fm-pivot-view .fm-charts-view .fm-chart-legend ul li.fm-level-5 {
  padding-left: 90px;
}
#fm-pivot-view .fm-charts-view .fm-chart-legend ul li.fm-level-6 {
  padding-left: 108px;
}
#fm-pivot-view .fm-charts-view .fm-chart-legend ul li.fm-level-7 {
  padding-left: 126px;
}
#fm-pivot-view .fm-charts-view .fm-chart-legend ul li.fm-level-8 {
  padding-left: 144px;
}
#fm-pivot-view .fm-charts-view .fm-chart-legend ul li.fm-level-9 {
  padding-left: 162px;
}
#fm-pivot-view .fm-charts-view .fm-chart-legend ul li.fm-level-10 {
  padding-left: 180px;
}
#fm-pivot-view .fm-charts-view .fm-chart-legend ul li .fm-icon-display {
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-right: 4px;
  border-radius: 50%;
  position: relative;
}
#fm-pivot-view .fm-charts-view .fm-chart-legend ul li .fm-text-display {
  display: inline-block;
  white-space: nowrap;
  font-size: 14px;
  color: #999;
  vertical-align: bottom;
  margin-bottom: 1px;
}
#fm-pivot-view .fm-charts-view .fm-chart-legend ul li.fm-collapsed .fm-icon-display:before {
  content: '';
  position: absolute;
  width: 8px;
  height: 2px;
  background: #fff;
  top: 6px;
  right: 3px;
}
#fm-pivot-view .fm-charts-view .fm-chart-legend ul li.fm-collapsed .fm-icon-display:after {
  content: '';
  position: absolute;
  width: 2px;
  height: 8px;
  background: #fff;
  top: 3px;
  right: 6px;
}
#fm-pivot-view .fm-charts-view .fm-chart-legend ul li.fm-expanded .fm-icon-display:before {
  content: '';
  position: absolute;
  width: 8px;
  height: 2px;
  background: #999;
  top: 6px;
  right: 3px;
}
#fm-pivot-view .fm-axis text {
  font-size: 14px;
  text-rendering: geometricPrecision;
  fill: #111;
}
#fm-pivot-view .fm-axis path,
#fm-pivot-view .fm-axis line {
  fill: none;
  stroke: #cccccc;
  shape-rendering: crispEdges;
}
#fm-pivot-view .fm-axis line {
  shape-rendering: geometricPrecision;
}
#fm-pivot-view .fm-x .tick line {
  display: none;
}
#fm-pivot-view .fm-x.fm-axis.fm-title {
  font-size: 14px;
  font-weight: bold;
  fill: #111;
}
#fm-pivot-view .fm-line {
  fill: none;
  stroke-width: 1px;
}
#fm-pivot-view .fm-circle {
  fill: #FFFFFF;
  stroke-width: 1px;
}
#fm-pivot-view .fm-scatter-point {
  fill: #FFFFFF;
  stroke-width: 1px;
}
#fm-pivot-view .fm-arc path {
  stroke: #fff;
  stroke-width: 0.5px;
}
#fm-pivot-view .fm-bar {
  shape-rendering: crispEdges;
}
#fm-pivot-view .fm-bar-stack {
  shape-rendering: crispEdges;
}
#fm-pivot-view .fm-bar-stack path {
  stroke: #fff;
  stroke-width: 0.5px;
}
#fm-pivot-view .fm-pie-label {
  font-size: 14px;
  fill: #111;
}
#fm-pivot-view .fm-chart svg .fm-label {
  white-space: pre;
}
#fm-pivot-view .fm-data-label {
  font-size: 12px;
  fill: #111;
}
.fm-charts-color-1 {
  fill: #EE232C;
}
.fm-charts-color-2 {
  fill: #478EC8;
}
.fm-charts-color-3 {
  fill: #FF8F00;
}
.fm-charts-color-4 {
  fill: #4DBF5A;
}
.fm-charts-color-5 {
  fill: #e042a6;
}
.fm-charts-color-6 {
  fill: #59BBAB;
}
.fm-charts-color-7 {
  fill: #b96bdd;
}
.fm-charts-color-8 {
  fill: #EDC200;
}
.fm-charts-color-9 {
  fill: #24c0de;
}
.fm-charts-color-10 {
  fill: none;
}
/* Fields */
#fm-pivot-view .fm-fields-view-wrap {
  position: absolute;
  z-index: 11;
  right: 0;
  top: 0;
}
#fm-pivot-view .fm-fields-view-wrap.fm-fields-opened {
  position: relative;
  z-index: 12;
}
#fm-pivot-view .fm-fields-view-wrap.fm-fields-opened .fm-btn-open-fields {
  display: none;
}
#fm-pivot-view .fm-fields-view-wrap.fm-fields-instant.fm-fields-opened {
  position: absolute;
  z-index: 11;
}
#fm-pivot-view .fm-fields-view-wrap button.fm-btn-open-fields {
  font-size: 0;
  border: none;
  background-color: #fff;
  min-width: auto;
  min-width: initial;
  padding: 6px;
  width: 44px;
  height: 44px;
  border-left: 1px solid #d5d5d5;
  border-bottom: 1px solid #d5d5d5;
  border-radius: 0;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}
#fm-pivot-view .fm-fields-view-wrap button.fm-btn-open-fields:hover {
  background-color: #f7f7f7;
}
#fm-pivot-view .fm-fields-view-wrap button.fm-btn-open-fields:before {
  display: block;
  position: absolute;
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-left: 1px;
  content: "\e94c";
  font-size: 30px;
  color: #999;
  position: static;
}
#fm-pivot-view .fm-fields-view {
  /* layouts */
}
#fm-pivot-view .fm-fields-view.fm-pivot-fields {
  width: 780px;
  min-width: 450px;
  height: 455px;
  min-height: 455px;
}
#fm-pivot-view .fm-fields-view .fm-btn-add-measure-2 {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0;
  border: none;
  background-color: transparent;
  min-width: auto;
  min-width: initial;
  padding: 2px;
  width: 26px;
  height: 100%;
  border-left: 1px solid #d5d5d5;
  border-radius: 0;
}
#fm-pivot-view .fm-fields-view .fm-btn-add-measure-2:before {
  display: block;
  position: absolute;
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -ms-transform: translateY(-50%) translateX(-50%);
  -moz-transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
  color: #999;
  font-size: 24px;
}
#fm-pivot-view .fm-fields-view .fm-btn-add-measure-2:hover:before {
  color: #555;
}
#fm-pivot-view .fm-fields-view .fm-popup-header {
  display: flex;
}
#fm-pivot-view .fm-fields-view .fm-popup-header .fm-ui-col:first-child {
  min-width: 100px;
}
#fm-pivot-view .fm-fields-view .fm-popup-header .fm-ui-col:first-child span.fm-ui-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
#fm-pivot-view .fm-fields-view .fm-popup-header .fm-ui-col:nth-child(1) {
  flex: 1;
}
#fm-pivot-view .fm-fields-view .fm-popup-header .fm-ui-col:nth-child(1) .fm-popup-subtitle {
  width: fit-content;
}
#fm-pivot-view .fm-fields-view .fm-popup-header .fm-ui-col:nth-child(2) {
  min-width: fit-content;
  position: relative;
  right: 0px;
  margin-left: 30px;
}
#fm-pivot-view .fm-fields-view .fm-popup-header .fm-btn-add-measure {
  padding-right: 32px;
  max-width: 250px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
#fm-pivot-view .fm-fields-view .fm-popup-header .fm-btn-add-measure:before {
  display: block;
  position: absolute;
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  right: 4px;
  color: #999;
  font-size: 24px;
}
#fm-pivot-view .fm-fields-view .fm-popup-header .fm-btn-add-measure:hover:before {
  color: #555;
}
#fm-pivot-view .fm-fields-view .fm-popup-content {
  height: calc(100% - 68px);
  height: -webkit-calc(100% - 68px);
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-ui-row {
  height: 100%;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-ui-col-3 {
  height: 100%;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap {
  border: 1px solid #d5d5d5;
  position: relative;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-header {
  background: #f7f7f7;
  border-bottom: 1px solid #d5d5d5;
  padding: 5px 10px;
  position: relative;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-header span.fm-ui-label {
  color: #999;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-header.fm-list-header-wide {
  padding: 0px;
  height: 35px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-header .fm-list-header-label-wrap {
  width: calc(100% - 40px);
  width: -webkit-calc(100% - 40px);
  padding: 10px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-header .fm-btn-collapse-expand-all {
  margin-left: 8px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-header .fm-search-wrap {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 100%;
  border-left: 1px solid #d5d5d5;
  z-index: 9;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-header .fm-search-wrap .fm-search-inp {
  z-index: 2;
  position: absolute;
  right: 0;
  width: 100%;
  height: 34px;
  padding-left: 39px;
  padding-right: 39px;
  color: #111;
  font-size: 14px;
  background: #fff;
  opacity: 0;
  border: none;
  visibility: hidden;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-header .fm-search-wrap .fm-search-inp-flat {
  z-index: 2;
  position: absolute;
  right: 0;
  width: 100%;
  height: 36px;
  padding-left: 39px;
  padding-right: 39px;
  color: #111;
  font-size: 14px;
  background: #fff;
  opacity: 0;
  border: none;
  visibility: hidden;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-header .fm-search-wrap .fm-icon-act_close {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 39px;
  cursor: pointer;
  z-index: 3;
  color: #999;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-header .fm-search-wrap .fm-icon-act_close:before {
  font-size: 26px;
  position: absolute;
  top: 4px;
  left: 8px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-header .fm-search-wrap .fm-icon-act_close:hover {
  color: #555;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-header .fm-search-wrap .fm-search-btn {
  position: absolute;
  height: 36px;
  width: 100%;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  z-index: 3;
  border: none;
  background: transparent;
  color: #999;
  padding-bottom: 4px;
  padding-right: 16px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-header .fm-search-wrap .fm-search-btn .fm-icon-act_search {
  color: #999;
  position: absolute;
  top: 5px;
  right: 7px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-header .fm-search-wrap .fm-search-btn .fm-icon-act_search:before {
  font-size: 25px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-header .fm-search-wrap .fm-search-btn:hover {
  color: #111;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-header .fm-search-wrap .fm-search-btn:hover .fm-icon-act_search,
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-header .fm-search-wrap .fm-search-btn:hover .fm-icon-act_search:before {
  color: #111;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-header .fm-search-wrap.fm-search-opened {
  width: 100%;
  height: 100%;
  border-left: none;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-header .fm-search-wrap.fm-search-opened .fm-icon-act_close,
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-header .fm-search-wrap.fm-search-opened .fm-search-inp {
  visibility: visible;
  opacity: 1;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-header .fm-search-wrap.fm-search-opened .fm-select-counter {
  display: none;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-header .fm-search-wrap.fm-search-opened .fm-search-btn {
  font-size: 0;
  width: 40px;
  left: 0;
  pointer-events: none;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-header .fm-search-wrap.fm-search-opened .fm-search-btn .fm-icon-act_search:before {
  color: #e9e9e9;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-header .fm-search-wrap.fm-search-opened-flat {
  width: 50%;
  height: 100%;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-header .fm-search-wrap.fm-search-opened-flat .fm-icon-act_close,
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-header .fm-search-wrap.fm-search-opened-flat .fm-search-inp-flat {
  visibility: visible;
  opacity: 1;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-header .fm-search-wrap.fm-search-opened-flat .fm-select-counter {
  display: none;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-header .fm-search-wrap.fm-search-opened-flat .fm-search-btn {
  font-size: 0;
  width: 40px;
  left: 0;
  pointer-events: none;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-header .fm-search-wrap.fm-search-opened-flat .fm-search-btn .fm-icon-act_search:before {
  color: #e9e9e9;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content {
  min-height: 37px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-ui-list {
  height: calc(100% - 4px);
  height: -webkit-calc(100% - 4px);
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content li {
  border-bottom: 1px solid #e9e9e9;
  padding: 10px 9px;
  position: relative;
  cursor: move;
  background: #fff;
  height: 38px;
  white-space: nowrap;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content li span {
  vertical-align: middle;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content li .fm-text-display {
  font-size: 14px;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content li .fm-drag-handle {
  display: block;
  position: absolute;
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  color: #d5d5d5;
  font-size: 20px;
  padding: 8px 6px;
  right: 0;
  touch-action: none;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content li .fm-drag-handle:before {
  content: "\e907";
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content li.fm-values {
  padding-left: 24px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content li.fm-values:before {
  display: block;
  position: absolute;
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e90b";
  font-size: 20px;
  color: #d5d5d5;
  left: 2px;
  top: 8px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content li.fm-selected .fm-text-display {
  font-weight: bold;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-search-highlight {
  background-color: #FFFF00;
  font-weight: inherit;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies {
  position: relative;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies.fm-drop-effect::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: red;
  display: block;
  width: 100%;
  height: 100%;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies li .fm-text-display {
  position: absolute;
  left: 30px;
  right: 30px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-check-icon {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 5px;
  cursor: pointer;
  position: relative;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies li.fm-unchecked:not(.fm-level-folder) .fm-icon-display {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 5px;
  cursor: pointer;
  position: relative;
  border: 1px solid #d5d5d5;
  background: #fff;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies li.fm-checked:not(.fm-level-folder) .fm-icon-display {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 5px;
  cursor: pointer;
  position: relative;
  background: #555;
  border: 1px solid #555;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies li.fm-checked:not(.fm-level-folder) .fm-icon-display:before {
  display: block;
  position: absolute;
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: '\e947';
  color: #FFFFFF;
  font-size: 20px;
  font-weight: bold;
  top: -2px;
  left: -2px;
  width: 0;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies li.fm-folder {
  cursor: pointer;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies li.fm-folder .fm-text-display {
  right: 10px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies li.fm-folder .fm-icon-display {
  display: inline-block;
  width: 17px;
  height: 17px;
  margin-right: 5px;
  position: relative;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies li.fm-folder .fm-icon-display:before {
  display: block;
  position: absolute;
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  color: #999;
  padding: 2px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies li.fm-folder.fm-collapsed .fm-icon-display:before {
  content: "\e911";
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies li.fm-folder.fm-expanded .fm-icon-display:before {
  content: "\e90f";
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies li.fm-level-folder .fm-text-display {
  left: 52px;
  right: 20px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies li.fm-level-folder.fm-unchecked .fm-moreicon-display {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 5px;
  cursor: pointer;
  position: relative;
  border: 1px solid #d5d5d5;
  background: #fff;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies li.fm-level-folder.fm-checked .fm-moreicon-display {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 5px;
  cursor: pointer;
  position: relative;
  background: #555;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies li.fm-level-folder.fm-checked .fm-moreicon-display:before {
  display: block;
  position: absolute;
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: '\e947';
  color: #FFFFFF;
  font-weight: bold;
  font-size: 20px;
  top: -2px;
  left: -1px;
  width: 0;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies li.fm-measures-folder:after {
  display: block;
  position: absolute;
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  content: "\e90b";
  right: 6px;
  font-size: 20px;
  color: #e9e9e9;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies li.fm-measures-folder .fm-text-display {
  right: 20px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies li.fm-measure:not(.fm-calculated):after {
  display: block;
  position: absolute;
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  content: "\e90b";
  right: 24px;
  font-size: 20px;
  color: #e9e9e9;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies li.fm-measure:not(.fm-calculated) .fm-text-display {
  right: 50px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies li.fm-calculated .fm-text-display {
  right: 44px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies li.fm-calculated .fm-calc-display {
  display: block;
  position: absolute;
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  cursor: pointer;
  color: #e9e9e9;
  right: 22px;
  font-size: 20px;
  padding: 8px 5px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies li.fm-calculated .fm-calc-display:hover {
  color: #999;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies li.fm-calculated .fm-calc-display:before {
  content: "\e94f";
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies li.fm-kpis-folder:after {
  display: block;
  position: absolute;
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  content: "\e94a";
  right: 8px;
  font-size: 16px;
  color: #e9e9e9;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies li.fm-kpis-folder .fm-text-display {
  right: 25px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-level-1 {
  padding-left: 20px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-level-1 .fm-text-display {
  left: 42px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-level-1.fm-level .fm-text-display {
  left: 54px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-level-1.fm-level-folder .fm-text-display {
  left: 64px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-level-1.fm-level .fm-icon-display {
  left: 12px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-level-2 {
  padding-left: 30px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-level-2 .fm-text-display {
  left: 52px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-level-2.fm-level .fm-text-display {
  left: 64px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-level-2.fm-level-folder .fm-text-display {
  left: 74px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-level-2.fm-level .fm-icon-display {
  left: 12px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-level-3 {
  padding-left: 40px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-level-3 .fm-text-display {
  left: 62px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-level-3.fm-level .fm-text-display {
  left: 74px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-level-3.fm-level-folder .fm-text-display {
  left: 84px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-level-3.fm-level .fm-icon-display {
  left: 12px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-level-4 {
  padding-left: 50px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-level-4 .fm-text-display {
  left: 72px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-level-4.fm-level .fm-text-display {
  left: 84px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-level-4.fm-level-folder .fm-text-display {
  left: 94px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-level-4.fm-level .fm-icon-display {
  left: 12px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-level-5 {
  padding-left: 60px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-level-5 .fm-text-display {
  left: 82px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-level-5.fm-level .fm-text-display {
  left: 94px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-level-5.fm-level-folder .fm-text-display {
  left: 104px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-level-5.fm-level .fm-icon-display {
  left: 12px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-level-6 {
  padding-left: 70px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-level-6 .fm-text-display {
  left: 92px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-level-6.fm-level .fm-text-display {
  left: 104px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-level-6.fm-level-folder .fm-text-display {
  left: 114px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-level-6.fm-level .fm-icon-display {
  left: 12px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-level-7 {
  padding-left: 80px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-level-7 .fm-text-display {
  left: 102px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-level-7.fm-level .fm-text-display {
  left: 114px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-level-7.fm-level-folder .fm-text-display {
  left: 124px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-level-7.fm-level .fm-icon-display {
  left: 12px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-level-8 {
  padding-left: 90px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-level-8 .fm-text-display {
  left: 112px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-level-8.fm-level .fm-text-display {
  left: 124px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-level-8.fm-level-folder .fm-text-display {
  left: 134px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-level-8.fm-level .fm-icon-display {
  left: 12px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-level-9 {
  padding-left: 100px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-level-9 .fm-text-display {
  left: 122px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-level-9.fm-level .fm-text-display {
  left: 134px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-level-9.fm-level-folder .fm-text-display {
  left: 144px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-level-9.fm-level .fm-icon-display {
  left: 12px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-level-10 {
  padding-left: 110px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-level-10 .fm-text-display {
  left: 132px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-level-10.fm-level .fm-text-display {
  left: 144px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-level-10.fm-level-folder .fm-text-display {
  left: 154px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-level-10.fm-level .fm-icon-display {
  left: 12px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-measures {
  position: relative;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-measures .fm-text-display {
  width: calc(100% - 50px);
  width: -webkit-calc(100% - 50px);
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-measures .fm-aggr-display {
  width: 32px;
  height: 100%;
  position: absolute;
  right: 26px;
  top: 0;
  cursor: pointer;
  padding-top: 8px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-measures .fm-aggr-display:before,
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-measures .fm-aggr-display:after {
  display: block;
  position: absolute;
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #999;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-measures .fm-aggr-display:hover::before,
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-measures .fm-aggr-display:hover::after {
  color: #555;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-measures .fm-aggr-display::before {
  content: "\e90b";
  font-size: 20px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-measures .fm-aggr-display::after {
  content: "\e908";
  font-size: 16px;
  left: 14px;
  top: 10px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-measures .fm-calc-display {
  display: block;
  position: absolute;
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  cursor: pointer;
  color: #999;
  right: 32px;
  font-size: 20px;
  padding: 8px 5px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-measures .fm-calc-display:hover {
  color: #555;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-measures .fm-calc-display:before {
  content: "\e94f";
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-pages .fm-text-display,
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-columns .fm-text-display,
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-rows .fm-text-display {
  width: calc(100% - 20px);
  width: -webkit-calc(100% - 20px);
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-prompt {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 12px;
  color: #cecece;
  font-style: italic;
  text-align: center;
  z-index: -1;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap-pages,
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap-columns {
  margin-bottom: 30px;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap-pages,
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap-columns,
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap-rows,
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap-measures {
  height: -webkit-calc(50% - 15px);
  height: calc(50% - 15px);
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap-pages .fm-list-content,
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap-columns .fm-list-content,
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap-rows .fm-list-content,
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap-measures .fm-list-content {
  height: -webkit-calc(100% - 23px);
  height: calc(100% - 23px);
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap-pages .fm-dragging-move,
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap-columns .fm-dragging-move,
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap-rows .fm-dragging-move,
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap-measures .fm-dragging-move {
  display: none;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap-all {
  height: 100%;
}
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap-all .fm-list-content {
  height: -webkit-calc(100% - 31px);
  height: calc(100% - 31px);
}
#fm-pivot-view .fm-fields-view.fm-layout-mobile .fm-popup-content {
  height: calc(100% - 50px);
  height: -webkit-calc(100% - 50px);
}
#fm-pivot-view .fm-fields-view.fm-layout-mobile-small .fm-popup-header {
  flex-direction: column;
}
#fm-pivot-view .fm-fields-view.fm-layout-mobile-small .fm-ui-col:nth-child(2) {
  margin-left: auto;
}
#fm-pivot-view .fm-fields-view.fm-layout-mobile-small .fm-popup-content {
  height: calc(100% - 95px);
  height: -webkit-calc(100% - 95px);
}
#fm-pivot-view .fm-fields-view.fm-layout-mobile-small .fm-btn-add-measure {
  display: none !important;
}
#fm-pivot-view .fm-fields-view.fm-flat-fields {
  min-width: 320px;
  width: 480px;
  min-height: 467px;
  height: 467px;
}
#fm-pivot-view .fm-fields-view.fm-flat-fields .fm-popup-header .fm-btn-add-measure {
  min-width: 40px;
  width: 40px;
  padding: 0;
}
#fm-pivot-view .fm-fields-view.fm-flat-fields .fm-popup-header .fm-btn-add-measure::before {
  display: block;
  position: absolute;
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -ms-transform: translateY(-50%) translateX(-50%);
  -moz-transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
  right: auto;
  right: initial;
}
#fm-pivot-view .fm-fields-view.fm-flat-fields .fm-popup-content .fm-list-wrap {
  min-height: 342px;
  height: 100%;
}
#fm-pivot-view .fm-fields-view.fm-flat-fields .fm-popup-content .fm-list-wrap .fm-list-header {
  padding: 10px 9px;
}
#fm-pivot-view .fm-fields-view.fm-flat-fields .fm-popup-content .fm-list-wrap .fm-list-header .fm-ui-checkbox span.fm-ui-label {
  color: #111;
  padding-left: 26px;
}
#fm-pivot-view .fm-fields-view.fm-flat-fields .fm-popup-content .fm-list-wrap .fm-list-header .fm-ui-checkbox.fm-selected {
  font-weight: bold;
}
#fm-pivot-view .fm-fields-view.fm-flat-fields .fm-popup-content .fm-list-wrap .fm-list-content {
  height: calc(100% - 34px);
  height: -webkit-calc(100% - 34px);
}
#fm-pivot-view .fm-fields-view.fm-flat-fields .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-aggr-display {
  width: 32px;
  height: 100%;
  position: absolute;
  right: 26px;
  top: 0;
  cursor: pointer;
  padding-top: 8px;
}
#fm-pivot-view .fm-fields-view.fm-flat-fields .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-aggr-display:before,
#fm-pivot-view .fm-fields-view.fm-flat-fields .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-aggr-display:after {
  display: block;
  position: absolute;
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #999;
}
#fm-pivot-view .fm-fields-view.fm-flat-fields .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-aggr-display:hover::before,
#fm-pivot-view .fm-fields-view.fm-flat-fields .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-aggr-display:hover::after {
  color: #555;
}
#fm-pivot-view .fm-fields-view.fm-flat-fields .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-aggr-display::before {
  content: "\e90b";
  font-size: 20px;
}
#fm-pivot-view .fm-fields-view.fm-flat-fields .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-aggr-display::after {
  content: "\e908";
  font-size: 16px;
  left: 14px;
  top: 10px;
}
#fm-pivot-view .fm-fields-view.fm-flat-fields .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies li .fm-text-display {
  left: 35px;
}
#fm-pivot-view .fm-fields-view.fm-flat-fields .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies li.fm-calculated .fm-calc-display {
  color: #999;
  right: 33px;
}
#fm-pivot-view .fm-fields-view.fm-flat-fields .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies li.fm-calculated .fm-calc-display:hover {
  color: #555;
}
#fm-pivot-view .fm-fields-view.fm-flat-fields .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies .fm-dragging-move {
  display: none;
}
#fm-pivot-view .fm-fields-view.fm-flat-fields .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies li.fm-has-aggs .fm-text-display {
  right: 55px;
}
#fm-pivot-view .fm-fields-view.fm-flat-fields .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies li.fm-measure::after {
  content: none;
}
#fm-pivot-view .fm-fields-view.fm-instant-view {
  position: absolute;
  width: 300px;
  min-width: 300px;
  min-height: 100%;
  right: 0;
  top: 0;
  height: 100%;
  background-color: #fff;
  border-left: 1px solid #d5d5d5;
}
#fm-pivot-view .fm-fields-view.fm-instant-view .fm-list-wrap {
  border: none;
}
#fm-pivot-view .fm-fields-view.fm-instant-view .fm-popup-content {
  height: 100%;
}
#fm-pivot-view .fm-fields-view.fm-instant-view .fm-popup-content .fm-list-wrap-all {
  height: 100%;
}
#fm-pivot-view .fm-fields-view.fm-instant-view .fm-popup-content .fm-list-wrap-all .fm-list-content {
  height: calc(100% - 35px);
  height: -webkit-calc(100% - 35px);
}
#fm-pivot-view .fm-fields-view.fm-instant-view .fm-popup-content .fm-list-wrap-all .fm-list-content .fm-lst-hierarchies {
  max-height: 100%;
  height: 100%;
}
#fm-pivot-view .fm-fields-view.fm-instant-view .fm-btn-add-measure,
#fm-pivot-view .fm-fields-view.fm-instant-view .fm-btn-close-fields {
  position: absolute;
  top: 0;
  font-size: 0;
  border: none;
  background-color: transparent;
  min-width: auto;
  min-width: initial;
  padding: 2px;
  width: 36px;
  height: 100%;
  border-left: 1px solid #d5d5d5;
  border-radius: 0;
}
#fm-pivot-view .fm-fields-view.fm-instant-view .fm-btn-add-measure:hover,
#fm-pivot-view .fm-fields-view.fm-instant-view .fm-btn-close-fields:hover {
  background-color: #f7f7f7;
}
#fm-pivot-view .fm-fields-view.fm-instant-view .fm-btn-add-measure:before,
#fm-pivot-view .fm-fields-view.fm-instant-view .fm-btn-close-fields:before {
  display: block;
  position: absolute;
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-left: 1px;
  font-size: 30px;
  color: #999;
  position: static;
}
#fm-pivot-view .fm-fields-view.fm-instant-view .fm-btn-close-fields {
  right: 0;
}
#fm-pivot-view .fm-fields-view.fm-instant-view .fm-btn-close-fields:before {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg);
  -o-transform: rotate(180deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  content: "\e94c";
}
#fm-pivot-view .fm-fields-view.fm-instant-view .fm-btn-add-measure {
  right: 36px;
}
#fm-pivot-view .fm-fields-view.fm-instant-view .fm-btn-add-measure::before {
  content: "\e901";
}
#fm-pivot-view .fm-fields-view.fm-instant-view.fm-flat-fields .fm-list-wrap-all {
  height: calc(100% - 2px);
  height: -webkit-calc(100% - 2px);
}
#fm-pivot-view .fm-fields-view.fm-instant-view.fm-pivot-fields .fm-popup-content .fm-list-wrap-all {
  height: 40%;
}
#fm-pivot-view .fm-fields-view.fm-instant-view.fm-pivot-fields .fm-popup-content .fm-ui-row {
  height: 20%;
  border-top: 1px solid #d5d5d5;
}
#fm-pivot-view .fm-fields-view.fm-instant-view.fm-pivot-fields .fm-popup-content .fm-ui-row .fm-ui-col-2 {
  height: 100%;
}
#fm-pivot-view .fm-fields-view.fm-instant-view.fm-pivot-fields .fm-popup-content .fm-ui-row .fm-list-wrap {
  height: 100%;
  margin: 0;
}
#fm-pivot-view .fm-fields-view.fm-instant-view.fm-pivot-fields .fm-popup-content .fm-ui-row .fm-list-wrap.fm-list-wrap-pages {
  border-right: 1px solid #d5d5d5;
}
#fm-pivot-view .fm-fields-view.fm-instant-view.fm-pivot-fields .fm-popup-content .fm-list-wrap .fm-list-content {
  height: calc(100% - 25px);
  height: -webkit-calc(100% - 25px);
}
#fm-pivot-view .fm-fields-view.fm-instant-view.fm-pivot-fields .fm-popup-content .fm-list-wrap .fm-list-content li {
  padding-top: 5px;
  padding-bottom: 5px;
  height: 28px;
}
#fm-pivot-view .fm-fields-view.fm-instant-view.fm-pivot-fields .fm-popup-content .fm-list-wrap .fm-list-content li.fm-values:before {
  top: 3px;
}
#fm-pivot-view .fm-fields-view.fm-instant-view.fm-pivot-fields .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-measures .fm-aggr-display {
  padding-top: 3px;
}
#fm-pivot-view .fm-fields-view.fm-instant-view.fm-pivot-fields .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-measures .fm-aggr-display:after {
  top: 6px;
}
#fm-pivot-view .fm-fields-view.fm-instant-view.fm-pivot-fields .fm-popup-content .fm-list-wrap.fm-list-wrap-all .fm-list-content {
  height: calc(100% - 35px);
  height: -webkit-calc(100% - 35px);
}
#fm-pivot-view .fm-fields-view.fm-layout-tablet .fm-list-wrap .fm-list-header.fm-list-header-wide .fm-list-header-label-wrap {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}
#fm-pivot-view .fm-fields-view.fm-layout-tablet .fm-list-wrap .fm-list-header.fm-list-header-wide span.fm-ui-label {
  display: block;
}
#fm-pivot-view .fm-fields-view.fm-layout-tablet .fm-list-wrap .fm-list-header.fm-list-header-wide .fm-btn-collapse-expand-all {
  margin-left: 0px;
}
#fm-pivot-view .fm-fields-view.fm-layout-mobile .fm-btn-add-measure {
  font-size: 0;
  min-width: 40px;
  width: 40px;
  padding: 0;
}
#fm-pivot-view .fm-fields-view.fm-layout-mobile .fm-btn-add-measure:before {
  display: block;
  position: absolute;
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -ms-transform: translateY(-50%) translateX(-50%);
  -moz-transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
  right: auto;
  right: initial;
}
#fm-pivot-view .fm-fields-view.fm-layout-mobile .fm-ui-col-3 {
  margin-right: 12px;
  width: calc(33.33% - 8px);
  width: -webkit-calc(33.33% - 8px);
}
#fm-pivot-view .fm-fields-view.fm-layout-mobile .fm-ui-col-3:nth-child(3n+3) {
  margin-right: 0;
}
#fm-pivot-view .fm-fields-view.fm-layout-mobile .fm-list-wrap-pages,
#fm-pivot-view .fm-fields-view.fm-layout-mobile .fm-list-wrap-columns {
  margin-bottom: 12px;
}
#fm-pivot-view .fm-fields-view.fm-layout-mobile .fm-list-wrap-pages,
#fm-pivot-view .fm-fields-view.fm-layout-mobile .fm-list-wrap-columns,
#fm-pivot-view .fm-fields-view.fm-layout-mobile .fm-list-wrap-measures,
#fm-pivot-view .fm-fields-view.fm-layout-mobile .fm-list-wrap-rows {
  height: calc(50% - 6px);
  height: -webkit-calc(50% - 6px);
}
#fm-pivot-view .fm-fields-view.fm-layout-mobile-small .fm-ui-btns-row .fm-ui-btn:not(.fm-btn-add-measure) {
  width: calc(50% - 40px);
  width: -webkit-calc(50% - 40px);
}
#fm-pivot-view .fm-aggregations-view {
  width: 200px;
  background: #fff;
  z-index: 9;
  border: 1px solid #d5d5d5;
  position: absolute;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  padding: 0;
}
#fm-pivot-view .fm-aggregations-view .fm-arrow {
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  position: absolute;
  right: 0;
}
#fm-pivot-view .fm-aggregations-view .fm-arrow:after,
#fm-pivot-view .fm-aggregations-view .fm-arrow:before {
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  content: "";
  display: block;
  position: absolute;
  width: 0px;
  height: 0px;
  border-style: solid;
}
#fm-pivot-view .fm-aggregations-view .fm-arrow:before {
  border-width: 5.5px 0 5.5px 6px;
  border-color: transparent transparent transparent #d5d5d5;
}
#fm-pivot-view .fm-aggregations-view .fm-arrow:after {
  border-width: 4.5px 0 4.5px 5px;
  border-color: transparent transparent transparent #f7f7f7;
}
#fm-pivot-view .fm-aggregations-view ul.fm-ui-list {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}
#fm-pivot-view .fm-aggregations-view ul.fm-ui-list li {
  padding: 10px 9px;
  border-bottom: 1px solid #e9e9e9;
  cursor: pointer;
  position: relative;
}
#fm-pivot-view .fm-aggregations-view ul.fm-ui-list li:last-child {
  border-bottom: none;
}
#fm-pivot-view .fm-aggregations-view ul.fm-ui-list li:hover {
  background: #f7f7f7;
}
#fm-pivot-view .fm-aggregations-view ul.fm-ui-list li.fm-selected {
  background: #f7f7f7;
  padding-right: 28px;
}
#fm-pivot-view .fm-aggregations-view ul.fm-ui-list li.fm-selected:before {
  display: block;
  position: absolute;
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: '\e902';
  right: 25px;
  left: auto;
  top: 6px;
  color: #999;
  font-size: 22px;
}
#fm-pivot-view div.fm-ui-modal-overlay.fm-dt-fields-overlay {
  z-index: 13;
}
#fm-pivot-view div.fm-ui-popup.fm-dt-fields {
  z-index: 14;
}
#fm-pivot-view div.fm-ui-popup.fm-dt-fields .fm-btn-add-measure {
  display: none;
}
/* Drill trough view */
#fm-pivot-view .fm-drillthrough-view {
  overflow: hidden;
  min-width: 320px;
}
#fm-pivot-view .fm-drillthrough-view .fm-header-container {
  position: relative;
  margin-bottom: 10px;
}
#fm-pivot-view .fm-drillthrough-view .fm-details-container {
  margin-bottom: 20px;
}
#fm-pivot-view .fm-drillthrough-view .fm-details-container .fm-ui-label {
  margin-right: 20px;
  display: inline-block;
  max-width: 33%;
  vertical-align: top;
}
#fm-pivot-view .fm-drillthrough-view .fm-details-container .fm-ui-label b {
  color: #111;
}
#fm-pivot-view .fm-drillthrough-view .fm-details-container .fm-ui-label:last-child {
  margin-right: 0;
}
#fm-pivot-view .fm-drillthrough-view .fm-grid-container {
  border: 1px solid #d5d5d5;
  position: relative;
}
#fm-pivot-view .fm-drillthrough-view .fm-popup-title {
  padding-right: 30px;
}
#fm-pivot-view .fm-drillthrough-view .fm-ui-btn-close {
  position: absolute;
  top: 0;
  right: 0;
}
#fm-pivot-view input[type=text].fm-ui-text-input.fm-editing-cell {
  position: absolute;
  font-size: 12px;
}
#fm-pivot-view textarea.fm-ui-text-area.fm-editing-cell {
  position: absolute;
  font-size: 12px;
  padding-left: 4px;
  padding-top: 7px;
  box-sizing: border-box;
  z-index: 2;
}
/* Drill trough view end */
/* Calculated view */
#fm-pivot-view div.fm-calculated-view {
  z-index: 14;
  min-width: 320px;
  width: 480px;
}
#fm-pivot-view div.fm-calculated-view .fm-popup-header .fm-popup-title {
  font-size: 20px;
  line-height: 28px;
}
#fm-pivot-view div.fm-calculated-view .fm-popup-header .fm-ui-col:first-child {
  margin-right: 30px;
}
#fm-pivot-view div.fm-calculated-view .fm-popup-header .fm-remove-btn {
  min-width: 40px;
  max-width: 40px;
  padding: 0;
}
#fm-pivot-view div.fm-calculated-view .fm-popup-header .fm-remove-btn:before {
  display: block;
  position: absolute;
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -ms-transform: translateY(-50%) translateX(-50%);
  -moz-transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
  font-size: 30px;
  color: #999;
}
#fm-pivot-view div.fm-calculated-view .fm-popup-header .fm-remove-btn:hover:before {
  color: #555;
}
#fm-pivot-view div.fm-calculated-view .fm-popup-content {
  position: relative;
  /*Calculated view search */
}
#fm-pivot-view div.fm-calculated-view .fm-popup-content .fm-name-input {
  margin-bottom: 20px;
}
#fm-pivot-view div.fm-calculated-view .fm-popup-content .fm-list-header {
  background: #f7f7f7;
  position: relative;
  border: 1px solid #d5d5d5;
  border-bottom: 0px;
}
#fm-pivot-view div.fm-calculated-view .fm-popup-content .fm-list-header .fm-list-header-label-wrap {
  width: calc(100% - 40px);
  width: -webkit-calc(60%);
  padding: 10px;
}
#fm-pivot-view div.fm-calculated-view .fm-popup-content .fm-list-header span.fm-ui-label {
  color: #999;
}
#fm-pivot-view div.fm-calculated-view .fm-popup-content .fm-list-header .fm-search-wrap {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 100%;
  border-left: 1px solid #d5d5d5;
  z-index: 9;
}
#fm-pivot-view div.fm-calculated-view .fm-popup-content .fm-list-header .fm-search-wrap .fm-search-btn {
  position: absolute;
  width: 100%;
  height: 38px;
  cursor: pointer;
  z-index: 3;
  border: none;
  background: transparent;
}
#fm-pivot-view div.fm-calculated-view .fm-popup-content .fm-list-header .fm-search-wrap .fm-search-btn .fm-icon-act_search {
  color: #999;
  position: absolute;
  top: 5px;
  right: 7px;
}
#fm-pivot-view div.fm-calculated-view .fm-popup-content .fm-list-header .fm-search-wrap .fm-search-btn .fm-icon-act_search::before {
  font-size: 25px;
}
#fm-pivot-view div.fm-calculated-view .fm-popup-content .fm-list-header .fm-search-wrap.fm-search-opened {
  width: 100%;
  height: 100%;
  border-left: none;
}
#fm-pivot-view div.fm-calculated-view .fm-popup-content .fm-list-header .fm-search-wrap.fm-search-opened .fm-icon-act_close,
#fm-pivot-view div.fm-calculated-view .fm-popup-content .fm-list-header .fm-search-wrap.fm-search-opened .fm-search-inp {
  visibility: visible;
  opacity: 1;
}
#fm-pivot-view div.fm-calculated-view .fm-popup-content .fm-list-header .fm-search-wrap.fm-search-opened .fm-select-counter {
  display: none;
}
#fm-pivot-view div.fm-calculated-view .fm-popup-content .fm-list-header .fm-search-wrap.fm-search-opened .fm-search-btn {
  font-size: 0;
  width: 40px;
  left: 0;
  pointer-events: none;
}
#fm-pivot-view div.fm-calculated-view .fm-popup-content .fm-list-header .fm-search-wrap.fm-search-opened .fm-search-btn .fm-icon-act_search:before {
  color: #e9e9e9;
}
#fm-pivot-view div.fm-calculated-view .fm-popup-content .fm-list-header .fm-search-wrap .fm-icon-act_close {
  visibility: hidden;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 39px;
  cursor: pointer;
  z-index: 3;
  color: #999;
}
#fm-pivot-view div.fm-calculated-view .fm-popup-content .fm-list-header .fm-search-wrap .fm-icon-act_close::before {
  font-size: 26px;
  position: absolute;
  top: 5px;
  left: 6px;
}
#fm-pivot-view div.fm-calculated-view .fm-popup-content .fm-list-header .fm-search-wrap .fm-search-inp {
  visibility: hidden;
  position: absolute;
  right: 0;
  top: 0px;
  width: 100%;
  height: 37px;
  padding-left: 40px;
  padding-right: 40px;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
}
#fm-pivot-view div.fm-calculated-view .fm-popup-content .fm-list-content {
  border: 1px solid #d5d5d5;
  margin-bottom: 20px;
}
#fm-pivot-view div.fm-calculated-view .fm-popup-content .fm-lst-measures {
  position: relative;
  height: 191px;
  overflow-x: hidden;
  overflow-y: auto;
}
#fm-pivot-view div.fm-calculated-view .fm-popup-content .fm-lst-measures li {
  border-bottom: 1px solid #e9e9e9;
  padding: 10px 9px;
  position: relative;
  cursor: move;
  background: #fff;
  height: 38px;
  white-space: nowrap;
}
#fm-pivot-view div.fm-calculated-view .fm-popup-content .fm-lst-measures li span {
  vertical-align: middle;
}
#fm-pivot-view div.fm-calculated-view .fm-popup-content .fm-lst-measures li .fm-text-display {
  font-size: 14px;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: calc(100% - 50px);
  width: -webkit-calc(100% - 50px);
}
#fm-pivot-view div.fm-calculated-view .fm-popup-content .fm-lst-measures li .fm-text-display .fm-search-highlight {
  background-color: #FFFF00;
  font-weight: inherit;
}
#fm-pivot-view div.fm-calculated-view .fm-popup-content .fm-lst-measures li .fm-aggr-display {
  width: 32px;
  height: 100%;
  position: absolute;
  right: 26px;
  top: 0;
  cursor: pointer;
  padding-top: 7px;
}
#fm-pivot-view div.fm-calculated-view .fm-popup-content .fm-lst-measures li .fm-aggr-display:before,
#fm-pivot-view div.fm-calculated-view .fm-popup-content .fm-lst-measures li .fm-aggr-display:after {
  display: block;
  position: absolute;
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #999;
}
#fm-pivot-view div.fm-calculated-view .fm-popup-content .fm-lst-measures li .fm-aggr-display:hover::before,
#fm-pivot-view div.fm-calculated-view .fm-popup-content .fm-lst-measures li .fm-aggr-display:hover::after {
  color: #555;
}
#fm-pivot-view div.fm-calculated-view .fm-popup-content .fm-lst-measures li .fm-aggr-display::before {
  content: "\e90b";
  font-size: 20px;
}
#fm-pivot-view div.fm-calculated-view .fm-popup-content .fm-lst-measures li .fm-aggr-display::after {
  content: "\e908";
  font-size: 16px;
  left: 14px;
  top: 10px;
}
#fm-pivot-view div.fm-calculated-view .fm-popup-content .fm-lst-measures li .fm-drag-handle {
  display: block;
  position: absolute;
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  color: #d5d5d5;
  font-size: 20px;
  padding: 8px;
  right: 0;
  touch-action: none;
}
#fm-pivot-view div.fm-calculated-view .fm-popup-content .fm-lst-measures li .fm-drag-handle:before {
  content: "\e907";
}
#fm-pivot-view div.fm-calculated-view .fm-popup-content .fm-func-btn-group {
  margin-top: 20px;
  margin-bottom: 20px;
}
#fm-pivot-view div.fm-calculated-view .fm-popup-content .fm-func-btn-group .fm-calc-action {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  border: none;
  border: 1px solid #555;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 38px;
  line-height: 36px;
  font-size: 14px;
  background: #555;
  position: relative;
  display: inline-block;
  vertical-align: top;
  color: #fff;
  text-align: center;
  cursor: pointer;
}
#fm-pivot-view div.fm-calculated-view .fm-popup-content .fm-func-btn-group .fm-calc-action:hover {
  opacity: 0.85;
}
#fm-pivot-view div.fm-calculated-view .fm-popup-content .fm-func-btn-group .fm-calc-action:hover,
#fm-pivot-view div.fm-calculated-view .fm-popup-content .fm-func-btn-group .fm-calc-action:focus,
#fm-pivot-view div.fm-calculated-view .fm-popup-content .fm-func-btn-group .fm-calc-action:active {
  outline: none;
}
#fm-pivot-view div.fm-calculated-view .fm-popup-content .fm-func-btn-group .fm-calc-action:first-child {
  border-top-left-radius: 4px;
}
#fm-pivot-view div.fm-calculated-view .fm-popup-content .fm-func-btn-group .fm-calc-action:nth-child(9) {
  border-top-right-radius: 4px;
}
#fm-pivot-view div.fm-calculated-view .fm-popup-content .fm-func-btn-group .fm-calc-action:nth-child(10) {
  border-bottom-left-radius: 4px;
}
#fm-pivot-view div.fm-calculated-view .fm-popup-content .fm-func-btn-group .fm-calc-action:last-child {
  border-bottom-right-radius: 4px;
}
#fm-pivot-view div.fm-calculated-view .fm-popup-content .fm-formula-wrap {
  position: relative;
}
#fm-pivot-view div.fm-calculated-view .fm-popup-content .fm-formula-wrap .fm-formula-input {
  width: 100%;
  height: 80px;
  font-size: 14px;
  border: 1px solid #d5d5d5;
  font-family: monospace;
}
#fm-pivot-view div.fm-calculated-view .fm-popup-content .fm-formula-wrap .fm-formula-input.fm-droppable-over {
  background: #fbfbfb;
  border: 1px solid #999;
}
#fm-pivot-view div.fm-calculated-view .fm-popup-content .fm-formula-wrap .fm-prompt {
  position: absolute;
  bottom: 10px;
  pointer-events: none;
  left: 0;
  width: 100%;
  text-align: center;
  color: #cecece;
  font-style: italic;
}
#fm-pivot-view div.fm-calculated-view.fm-layout-mobile .fm-popup-header .fm-ui-col:first-child {
  margin-right: 10px;
}
#fm-pivot-view div.fm-calculated-view.fm-layout-mobile-small .fm-with-delete-btn .fm-popup-header .fm-ui-btns-row .fm-ui-btn:not(.fm-remove-btn) {
  width: calc(50% - 40px);
  width: -webkit-calc(50% - 40px);
}
#fm-pivot-view div.fm-ui-modal-overlay.fm-calculated-view-overlay {
  z-index: 13;
}
/* PRELOADER VIEW */
#fm-pivot-view div.fm-ui-modal-overlay.fm-overlay-preloader {
  z-index: 20;
}
#fm-pivot-view div.fm-preloader-view {
  z-index: 21;
  width: 320px;
  height: 100px;
}
#fm-pivot-view div.fm-preloader-view .fm-spinner {
  position: relative;
}
#fm-pivot-view div.fm-preloader-view .fm-spinner:before {
  display: block;
  position: absolute;
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e97b";
  font-size: 32px;
  left: 7px;
  top: 10px;
  color: #999;
  -webkit-animation-name: spin;
  -webkit-animation-duration: 1000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: spin;
  -moz-animation-duration: 1000ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: spin;
  -ms-animation-duration: 1000ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-transition: rotate(3600deg);
}
@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#fm-pivot-view div.fm-preloader-view .fm-message-label {
  display: block;
  padding-left: 70px;
  padding-top: 8px;
}
#fm-pivot-view div.fm-preloader-view .fm-details-label {
  display: block;
  color: #999;
  padding-left: 70px;
  padding-top: 2px;
  font-size: 12px;
}
/* PRELOADER END */
/* ALERT VIEW */
#fm-pivot-view div.fm-ui-modal-overlay.fm-overlay-alert {
  z-index: 16;
}
#fm-pivot-view div.fm-prompt-view {
  z-index: 17;
}
#fm-pivot-view div.fm-prompt-view .fm-content {
  max-width: 300px;
  min-width: 300px;
  word-wrap: break-word;
}
#fm-pivot-view div.fm-prompt-view .fm-message-label {
  display: block;
  margin-bottom: 10px;
}
#fm-pivot-view div.fm-prompt-view .fm-ui-btns-row {
  margin-top: 10px;
}
#fm-pivot-view div.fm-prompt-view .fm-ui-btns-row .fm-ui-btn {
  margin-top: 10px;
}
#fm-pivot-view div.fm-alert-view {
  z-index: 17;
}
#fm-pivot-view div.fm-alert-view .fm-content {
  margin-left: 100px;
  max-width: 300px;
  word-wrap: break-word;
}
#fm-pivot-view div.fm-alert-view .fm-popup-title {
  margin-bottom: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
}
#fm-pivot-view div.fm-alert-view .fm-build-version {
  color: #999;
  font-size: 13px;
}
#fm-pivot-view div.fm-alert-view .fm-alert-icon {
  position: absolute;
  left: 50px;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}
#fm-pivot-view div.fm-alert-view .fm-ui-btns-row {
  margin-top: 10px;
}
#fm-pivot-view div.fm-alert-view .fm-ui-btns-row .fm-ui-btn {
  margin-top: 10px;
}
#fm-pivot-view div.fm-alert-view .fm-circle {
  display: block;
  border-radius: 50%;
  border: 30px solid #555;
  width: 60px;
  height: 60px;
  position: relative;
  margin-left: -10px;
}
#fm-pivot-view div.fm-alert-view .fm-circle .fm-ui-icon {
  color: #FFFFFF;
  font-size: 38px;
  padding: 11px;
  position: absolute;
  top: -29px;
  left: -29px;
}
#fm-pivot-view div.fm-alert-view .fm-hexagon {
  display: block;
  position: relative;
  width: 32.33px;
  height: 56px;
  background-color: #555;
  margin: 16.17px 0;
}
#fm-pivot-view div.fm-alert-view .fm-hexagon .fm-ui-icon {
  color: #FFFFFF;
  font-size: 38px;
  top: 8px;
  left: -2px;
}
#fm-pivot-view div.fm-alert-view .fm-hexagon:before,
#fm-pivot-view div.fm-alert-view .fm-hexagon:after {
  content: "";
  position: absolute;
  width: 0;
  border-top: 28px solid transparent;
  border-bottom: 28px solid transparent;
}
#fm-pivot-view div.fm-alert-view .fm-hexagon:before {
  left: 100%;
  border-left: 16.17px solid #555;
}
#fm-pivot-view div.fm-alert-view .fm-hexagon:after {
  right: 100%;
  width: 0;
  border-right: 16.17px solid #555;
}
#fm-pivot-view div.fm-alert-view .fm-triangle-wrap {
  position: relative;
}
#fm-pivot-view div.fm-alert-view .fm-triangle-wrap .fm-ui-icon {
  color: #FFFFFF;
  font-size: 38px;
  top: -4px;
  left: -1px;
}
#fm-pivot-view div.fm-alert-view .fm-triangle-wrap .fm-triangle {
  position: relative;
  background-color: #555;
  text-align: left;
  display: block;
  margin-top: -7px;
}
#fm-pivot-view div.fm-alert-view .fm-triangle-wrap .fm-triangle:before,
#fm-pivot-view div.fm-alert-view .fm-triangle-wrap .fm-triangle:after {
  content: '';
  position: absolute;
  background-color: inherit;
}
#fm-pivot-view div.fm-alert-view .fm-triangle-wrap .fm-triangle,
#fm-pivot-view div.fm-alert-view .fm-triangle-wrap .fm-triangle:before,
#fm-pivot-view div.fm-alert-view .fm-triangle-wrap .fm-triangle:after {
  width: 35px;
  height: 35px;
  border-top-right-radius: 30%;
}
#fm-pivot-view div.fm-alert-view .fm-triangle-wrap .fm-triangle {
  -webkit-transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
  -ms-transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
  transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
}
#fm-pivot-view div.fm-alert-view .fm-triangle-wrap .fm-triangle:before {
  -webkit-transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%);
  -ms-transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%);
  transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%);
}
#fm-pivot-view div.fm-alert-view .fm-triangle-wrap .fm-triangle:after {
  -webkit-transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
  -ms-transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
  transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
}
#fm-pivot-view div.fm-alert-view.fm-layout-mobile-small .fm-content {
  margin-left: 0;
}
#fm-pivot-view div.fm-alert-view.fm-layout-mobile-small .fm-alert-icon {
  display: none;
}
/* ALERT VIEW END*/
.fm-noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* NEW GRID */
#fm-pivot-view .fm-grid-layout {
  position: relative;
  overflow: hidden;
  z-index: 1;
  /* pages filter */
  /* levels */
  /* collapsed / expanded */
  /* sort */
  /* drill down links */
  /* sheet headers */
  /* KPI cells */
  /* member properties */
  /* selection canvas */
  /* flat view */
  /* live filtering  */
  /* resize handles */
}
#fm-pivot-view .fm-grid-layout div.fm-scroll-pane {
  overflow: auto;
}
#fm-pivot-view .fm-grid-layout div.fm-scroll-pane div.fm-scroll-content {
  position: relative;
}
#fm-pivot-view .fm-grid-layout div.fm-scroll-pane div.fm-scroll-content-float {
  position: relative;
}
#fm-pivot-view .fm-grid-layout div.fm-scroll-pane div.fm-scroll-placeholder {
  position: relative;
  height: 100%;
}
#fm-pivot-view .fm-grid-layout div.fm-cell {
  background-color: #fff;
  color: #111;
  box-sizing: border-box;
  vertical-align: top;
  border-right: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  padding: 7px 4px;
  line-height: normal;
}
#fm-pivot-view .fm-grid-layout div.fm-row {
  white-space: nowrap;
  box-sizing: border-box;
}
#fm-pivot-view .fm-grid-layout div.fm-row.fm-row-hidden {
  position: absolute;
  top: -1000px;
  z-index: -1;
}
#fm-pivot-view .fm-grid-layout div.fm-row > div {
  display: inline-block;
}
#fm-pivot-view .fm-grid-layout .fm-data-sheet .fm-scroll-placeholder {
  z-index: 1;
}
#fm-pivot-view .fm-grid-layout .fm-data-sheet div.fm-row,
#fm-pivot-view .fm-grid-layout .fm-cols-sheet div.fm-row {
  display: flex;
}
#fm-pivot-view .fm-grid-layout .fm-data-sheet div.fm-row > div,
#fm-pivot-view .fm-grid-layout .fm-cols-sheet div.fm-row > div {
  flex-shrink: 0;
}
#fm-pivot-view .fm-grid-layout .fm-rows-sheet div.fm-row {
  display: flex;
}
#fm-pivot-view .fm-grid-layout .fm-rows-sheet div.fm-row > div.fm-sheet-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#fm-pivot-view .fm-grid-layout div.fm-scroll-pane div.fm-scroll-content {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  white-space: nowrap;
  /*pointer-events: none;*/
}
#fm-pivot-view .fm-grid-layout .fm-data-sheet,
#fm-pivot-view .fm-grid-layout .fm-rows-sheet,
#fm-pivot-view .fm-grid-layout .fm-cols-sheet,
#fm-pivot-view .fm-grid-layout .fm-sheet-headers {
  position: absolute;
  overflow: hidden;
  background-color: #fff;
}
#fm-pivot-view .fm-grid-layout .fm-data-sheet {
  border-top: none;
  border-left: none;
}
#fm-pivot-view .fm-grid-layout .fm-cell {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
#fm-pivot-view .fm-grid-layout .fm-data-sheet .fm-cell {
  text-align: right;
}
#fm-pivot-view .fm-grid-layout .fm-cols-sheet .fm-scroll-pane,
#fm-pivot-view .fm-grid-layout .fm-rows-sheet .fm-scroll-pane {
  overflow: hidden;
}
#fm-pivot-view .fm-grid-layout div.fm-scroll-pane div.fm-scroll-content .fm-cell {
  position: relative;
}
#fm-pivot-view .fm-grid-layout div.fm-scroll-pane div.fm-scroll-content-float .fm-cell {
  position: absolute;
}
#fm-pivot-view .fm-grid-layout div.fm-header-r {
  background-color: #f7f7f7;
  color: #111;
  border-right: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
}
#fm-pivot-view .fm-grid-layout div.fm-header-c {
  background-color: #f7f7f7;
  color: #111;
  border-right: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
}
#fm-pivot-view .fm-grid-layout div.fm-filter-header {
  cursor: pointer;
}
#fm-pivot-view .fm-grid-layout div.fm-filter-header.fm-live-filtering-header {
  cursor: auto;
}
#fm-pivot-view .fm-grid-layout div.fm-total-r {
  font-weight: bold;
  background-color: #fff;
  color: #111;
  border-right: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
}
#fm-pivot-view .fm-grid-layout div.fm-total-c {
  font-weight: bold;
  background-color: #fff;
  color: #111;
  border-right: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
}
#fm-pivot-view .fm-grid-layout div.fm-grand-total-r {
  font-weight: bold;
  background-color: #fff;
  color: #111;
  border-right: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
}
#fm-pivot-view .fm-grid-layout div.fm-grand-total-c {
  font-weight: bold;
  background-color: #fff;
  color: #111;
  border-right: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
}
#fm-pivot-view .fm-grid-layout div.fm-grand-total-c.fm-grand-total-r {
  font-weight: bold;
  background-color: #fff;
  color: #111;
  border-right: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
}
#fm-pivot-view .fm-grid-layout div.fm-header.fm-total-r {
  font-weight: normal;
  background-color: #f7f7f7;
  color: #111;
  border-right: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
}
#fm-pivot-view .fm-grid-layout div.fm-header.fm-total-c {
  font-weight: normal;
  background-color: #f7f7f7;
  color: #111;
  border-right: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
}
#fm-pivot-view .fm-grid-layout div.fm-header.fm-grand-total-r {
  font-weight: normal;
  background-color: #f7f7f7;
  color: #111;
  border-right: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
}
#fm-pivot-view .fm-grid-layout div.fm-header.fm-grand-total-c {
  font-weight: normal;
  background-color: #f7f7f7;
  color: #111;
  border-right: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
}
#fm-pivot-view .fm-grid-layout.fm-flat-view div.fm-total,
#fm-pivot-view .fm-grid-layout.fm-flat-view div.fm-grand-total {
  text-align: right;
}
#fm-pivot-view .fm-grid-layout .fm-filters {
  position: absolute;
  background-color: #e9e9e9;
  color: #111;
}
#fm-pivot-view .fm-grid-layout .fm-cols-filter {
  border-right: 1px solid #e9e9e9;
  z-index: 1;
}
#fm-pivot-view .fm-grid-layout .fm-cols-filter .fm-row {
  border-bottom: 1px solid #e9e9e9;
}
#fm-pivot-view .fm-grid-layout .fm-filters .fm-header,
#fm-pivot-view .fm-grid-layout.fm-flat-view .fm-header {
  background-color: #e9e9e9;
  color: #111;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  border-bottom: 1px solid #e9e9e9;
  border-right: 1px solid #e9e9e9;
  overflow: hidden;
  text-overflow: ellipsis;
}
#fm-pivot-view .fm-grid-layout .fm-filters .fm-filter-header,
#fm-pivot-view .fm-grid-layout.fm-flat-view .fm-filter-header {
  background-color: #e9e9e9;
  color: #111;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  border-bottom: 1px solid #e9e9e9;
  border-right: 1px solid #e9e9e9;
  overflow: hidden;
  text-overflow: ellipsis;
}
#fm-pivot-view .fm-grid-layout .fm-filters .fm-filter-header:hover,
#fm-pivot-view .fm-grid-layout.fm-flat-view .fm-filter-header:hover {
  background-color: #dcdcdc;
}
#fm-pivot-view .fm-grid-layout div.fm-filter-header,
#fm-pivot-view .fm-grid-layout a.fm-filter-header {
  padding-right: 20px;
}
#fm-pivot-view .fm-grid-layout div.fm-filter-header.fm-filtered,
#fm-pivot-view .fm-grid-layout a.fm-filter-header.fm-filtered {
  padding-right: 35px;
}
#fm-pivot-view .fm-grid-layout div.fm-filter-header.fm-live-filtering-header:hover,
#fm-pivot-view .fm-grid-layout a.fm-filter-header.fm-live-filtering-header:hover {
  background-color: #e9e9e9;
}
#fm-pivot-view .fm-grid-layout.fm-flat-view div.fm-filter-header,
#fm-pivot-view .fm-grid-layout.fm-flat-view a.fm-filter-header {
  padding-right: 30px;
}
#fm-pivot-view .fm-grid-layout.fm-flat-view div.fm-filter-header.fm-filtered,
#fm-pivot-view .fm-grid-layout.fm-flat-view a.fm-filter-header.fm-filtered {
  padding-right: 40px;
}
#fm-pivot-view .fm-grid-layout i.fm-icon {
  position: absolute;
  height: 100%;
  width: 16px;
  top: 0;
}
#fm-pivot-view .fm-grid-layout i.fm-icon:before {
  font-family: flexmonster-icons;
  font-weight: normal;
  font-size: 15px;
  color: #999;
  position: absolute;
  top: 6px;
}
#fm-pivot-view .fm-grid-layout.fm-flat-view i.fm-icon:before {
  color: #999;
}
#fm-pivot-view .fm-grid-layout .fm-filter-header i.fm-filter-icon {
  right: 2px;
  pointer-events: none;
}
#fm-pivot-view .fm-grid-layout .fm-filter-header i.fm-filter-icon:before {
  content: "\e90c";
  color: #999;
}
#fm-pivot-view .fm-grid-layout .fm-filter-header.fm-filtered i.fm-filtered-icon {
  right: 16px;
  pointer-events: none;
}
#fm-pivot-view .fm-grid-layout.fm-flat-view .fm-filter-header.fm-filtered i.fm-filtered-icon {
  right: 22px;
}
#fm-pivot-view .fm-grid-layout .fm-filter-header.fm-filtered i.fm-filtered-icon:before {
  content: "\e905";
  color: #999;
}
#fm-pivot-view .fm-grid-layout .fm-filter-header.fm-dragging-move {
  opacity: 0.5;
}
#fm-pivot-view .fm-grid-layout .fm-header-drop-indicator {
  background-color: #555;
}
#fm-pivot-view .fm-grid-layout .fm-draggable {
  touch-action: none;
}
#fm-pivot-view .fm-grid-layout .fm-draggable:not(.fm-filter-header) {
  cursor: move;
}
#fm-pivot-view .fm-grid-layout .fm-filters.fm-cols-filter .fm-filter-header {
  border-right: none;
  border-bottom: 1px solid #e9e9e9;
  background-color: #e9e9e9;
  color: #111;
}
#fm-pivot-view .fm-grid-layout .fm-filters.fm-cols-filter .fm-filter-header:hover {
  background-color: #dcdcdc;
}
#fm-pivot-view .fm-grid-layout .fm-filters.fm-cols-filter .fm-header-drop-indicator {
  display: inline-block;
  width: 3px;
  height: 100%;
}
#fm-pivot-view .fm-grid-layout .fm-filters.fm-rows-filter .fm-filter-header {
  border-right: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  background-color: #e9e9e9;
  color: #111;
}
#fm-pivot-view .fm-grid-layout .fm-filters.fm-rows-filter .fm-filter-header:hover {
  background-color: #dcdcdc;
}
#fm-pivot-view .fm-grid-layout .fm-filters.fm-rows-filter .fm-header-drop-indicator {
  display: block;
  width: 100%;
  height: 3px;
}
#fm-pivot-view .fm-grid-layout .fm-filters.fm-page-filter .fm-header-drop-indicator {
  float: none;
  display: inline-block;
  width: 3px;
  height: 40px;
  vertical-align: top;
  margin-right: 5px;
}
#fm-pivot-view .fm-grid-layout.fm-classic-view .fm-filters.fm-rows-filter .fm-header-drop-indicator {
  display: inline-block;
  width: 3px;
  height: 30px;
}
#fm-pivot-view .fm-grid-layout.fm-flat-view .fm-header-drop-indicator {
  position: absolute;
  width: 3px;
  height: 30px;
}
#fm-pivot-view .fm-grid-layout .fm-filters.fm-page-filter {
  padding: 7px 0 0 7px;
  border-bottom: 1px solid #d5d5d5;
  background-color: #f7f7f7;
  white-space: nowrap;
  overflow-x: auto;
}
#fm-pivot-view .fm-grid-layout .fm-filters.fm-page-filter a.fm-filter-header {
  text-align: left;
  border: none;
  background-color: #e9e9e9;
  margin-bottom: 5px;
  margin-right: 5px;
  padding-top: 6px;
  padding-bottom: 7px;
  padding-left: 5px;
  padding-right: 24px;
  width: auto;
  width: initial;
  min-width: 80px;
  line-height: 15px;
  white-space: nowrap;
  font-weight: bold !important;
  display: inline-block;
  float: none;
  cursor: pointer;
}
#fm-pivot-view .fm-grid-layout .fm-filters.fm-page-filter a.fm-filter-header.fm-filtered {
  padding-right: 40px;
}
#fm-pivot-view .fm-grid-layout .fm-filters.fm-page-filter a.fm-filter-header:hover {
  background-color: #dcdcdc;
}
#fm-pivot-view .fm-grid-layout .fm-filters.fm-page-filter a.fm-filter-header i.fm-icon:before {
  top: 7px;
}
#fm-pivot-view .fm-grid-layout .fm-filters.fm-page-filter a.fm-filter-header i.fm-filter-icon {
  right: 4px;
}
#fm-pivot-view .fm-grid-layout .fm-filters.fm-page-filter a.fm-filter-header i.fm-filtered-icon {
  right: 20px;
}
#fm-pivot-view .fm-grid-layout .fm-filters.fm-page-filter a.fm-filter-header .fm-filter-desc {
  display: inline-block;
  font-weight: normal !important;
  text-transform: none;
  color: #888;
  font-size: 10px;
  padding-top: 4px;
  margin-left: 4px;
}
#fm-pivot-view .fm-grid-layout.fm-compact-view .fm-header-r.fm-level-1 {
  padding-left: 15px;
  background-position: 12px center;
}
#fm-pivot-view .fm-grid-layout.fm-compact-view .fm-header-r.fm-level-2 {
  padding-left: 27px;
  background-position: 24px center;
}
#fm-pivot-view .fm-grid-layout.fm-compact-view .fm-header-r.fm-level-3 {
  padding-left: 39px;
  background-position: 36px center;
}
#fm-pivot-view .fm-grid-layout.fm-compact-view .fm-header-r.fm-level-4 {
  padding-left: 51px;
  background-position: 48px center;
}
#fm-pivot-view .fm-grid-layout.fm-compact-view .fm-header-r.fm-level-5 {
  padding-left: 63px;
  background-position: 60px center;
}
#fm-pivot-view .fm-grid-layout.fm-compact-view .fm-header-r.fm-level-6 {
  padding-left: 75px;
  background-position: 72px center;
}
#fm-pivot-view .fm-grid-layout.fm-compact-view .fm-header-r.fm-level-7 {
  padding-left: 87px;
  background-position: 84px center;
}
#fm-pivot-view .fm-grid-layout.fm-compact-view .fm-header-r.fm-level-8 {
  padding-left: 99px;
  background-position: 96px center;
}
#fm-pivot-view .fm-grid-layout.fm-compact-view .fm-header-r.fm-level-9 {
  padding-left: 111px;
  background-position: 108px center;
}
#fm-pivot-view .fm-grid-layout.fm-compact-view .fm-header-r.fm-level-10 {
  padding-left: 123px;
  background-position: 120px center;
}
#fm-pivot-view .fm-grid-layout.fm-compact-view .fm-header-r.fm-level-11 {
  padding-left: 135px;
  background-position: 132px center;
}
#fm-pivot-view .fm-grid-layout.fm-compact-view .fm-header-r.fm-level-12 {
  padding-left: 147px;
  background-position: 144px center;
}
#fm-pivot-view .fm-grid-layout.fm-compact-view .fm-header-r.fm-level-13 {
  padding-left: 159px;
  background-position: 156px center;
}
#fm-pivot-view .fm-grid-layout.fm-compact-view .fm-header-r.fm-level-14 {
  padding-left: 171px;
  background-position: 168px center;
}
#fm-pivot-view .fm-grid-layout.fm-compact-view .fm-header-r.fm-level-15 {
  padding-left: 183px;
  background-position: 180px center;
}
#fm-pivot-view .fm-grid-layout.fm-compact-view .fm-header-r.fm-level-16 {
  padding-left: 195px;
  background-position: 192px center;
}
#fm-pivot-view .fm-grid-layout.fm-compact-view .fm-header-r.fm-level-17 {
  padding-left: 207px;
  background-position: 204px center;
}
#fm-pivot-view .fm-grid-layout.fm-compact-view .fm-header-r.fm-level-18 {
  padding-left: 219px;
  background-position: 216px center;
}
#fm-pivot-view .fm-grid-layout.fm-compact-view .fm-header-r.fm-level-19 {
  padding-left: 231px;
  background-position: 228px center;
}
#fm-pivot-view .fm-grid-layout.fm-compact-view .fm-header-r.fm-level-20 {
  padding-left: 243px;
  background-position: 240px center;
}
#fm-pivot-view .fm-grid-layout div.fm-collapsed,
#fm-pivot-view .fm-grid-layout div.fm-expanded {
  cursor: pointer;
}
#fm-pivot-view .fm-grid-layout .fm-collapsed .fm-collapsed-icon,
#fm-pivot-view .fm-grid-layout .fm-expanded .fm-expanded-icon {
  position: relative;
  display: inline-block;
  height: 12px;
  width: 11px;
}
#fm-pivot-view .fm-grid-layout .fm-collapsed .fm-collapsed-icon:before,
#fm-pivot-view .fm-grid-layout .fm-expanded .fm-expanded-icon:before {
  top: 0;
  left: -4px;
}
#fm-pivot-view .fm-grid-layout .fm-collapsed .fm-collapsed-icon:before {
  content: "\e911";
}
#fm-pivot-view .fm-grid-layout .fm-expanded .fm-expanded-icon:before {
  content: "\e90f";
}
#fm-pivot-view .fm-grid-layout i.fm-icon.fm-sort-icon {
  cursor: pointer;
  right: 0;
}
#fm-pivot-view .fm-grid-layout i.fm-icon.fm-sort-icon:before {
  font-size: 17px;
}
#fm-pivot-view .fm-grid-layout i.fm-icon.fm-sort-icon.fm-with-order span {
  color: #999;
  font-size: 10px;
  font-weight: normal;
  line-height: 30px;
  margin-left: -4px;
}
#fm-pivot-view .fm-grid-layout .fm-cell.fm-v-sort,
#fm-pivot-view .fm-grid-layout .fm-cell.fm-h-sort {
  padding-right: 16px;
}
#fm-pivot-view .fm-grid-layout .fm-v-sort:not(.fm-live-filtering-header):hover .fm-v-sort-icon:before,
#fm-pivot-view .fm-grid-layout .fm-v-sort.fm-live-filtering-header .fm-v-sort-icon:hover:before,
#fm-pivot-view .fm-grid-layout .fm-v-sort .fm-v-sort-asc:before,
#fm-pivot-view .fm-grid-layout .fm-v-sort .fm-v-sort-desc:before,
#fm-pivot-view .fm-grid-layout .fm-h-sort:hover .fm-h-sort-icon:before,
#fm-pivot-view .fm-grid-layout .fm-h-sort .fm-h-sort-asc:before,
#fm-pivot-view .fm-grid-layout .fm-h-sort .fm-h-sort-desc:before {
  content: "\e90d";
}
#fm-pivot-view .fm-grid-layout .fm-v-sort .fm-v-sort-asc:before {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg);
  -o-transform: rotate(180deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
}
#fm-pivot-view .fm-grid-layout .fm-h-sort:hover .fm-h-sort-icon:before,
#fm-pivot-view .fm-grid-layout .fm-h-sort .fm-h-sort-desc:before {
  right: 1px;
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  transform: rotate(270deg);
  -o-transform: rotate(270deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}
#fm-pivot-view .fm-grid-layout .fm-h-sort .fm-h-sort-asc:before {
  right: 2px;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  transform: rotate(90deg);
  -o-transform: rotate(90deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
}
#fm-pivot-view .fm-grid-layout span.fm-hierarchy-link {
  white-space: nowrap;
  color: #888;
  font-size: 10px;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 4px;
}
#fm-pivot-view .fm-grid-layout span.fm-hierarchy-link:before {
  text-indent: 0;
  margin-right: 2px;
  display: inline-block;
}
#fm-pivot-view .fm-grid-layout .fm-drilled-up span.fm-hierarchy-link:before {
  content: "+";
}
#fm-pivot-view .fm-grid-layout .fm-drilled-down span.fm-hierarchy-link:before {
  content: "-";
}
#fm-pivot-view .fm-grid-layout div.fm-sheet-header,
#fm-pivot-view .fm-grid-layout .fm-filters div.fm-sheet-header {
  border-right: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  color: #999;
  background-color: #f7f7f7;
  text-align: center;
  line-height: 23px;
  padding: 0 0;
}
#fm-pivot-view .fm-grid-layout .fm-sheet-headers .fm-sheet-header {
  display: inline-block;
}
#fm-pivot-view .fm-grid-layout div.fm-cell.fm-kpi:after {
  display: block;
  position: absolute;
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  color: #999;
  font-size: 16px;
  padding-top: 3px;
}
#fm-pivot-view .fm-grid-layout div.fm-cell.fm-kpi-trend-bad-icon:after {
  content: "\ea3e";
}
#fm-pivot-view .fm-grid-layout div.fm-cell.fm-kpi-trend-risk-icon:after {
  content: "\ea3d";
}
#fm-pivot-view .fm-grid-layout div.fm-cell.fm-kpi-trend-ok-icon:after {
  content: "\ea3c";
}
#fm-pivot-view .fm-grid-layout div.fm-cell.fm-kpi-trend-rising-icon:after {
  content: "\ea3b";
}
#fm-pivot-view .fm-grid-layout div.fm-cell.fm-kpi-trend-good-icon:after {
  content: "\ea3a";
}
#fm-pivot-view .fm-grid-layout div.fm-cell.fm-kpi-status-bad-icon:after {
  content: "\e951";
}
#fm-pivot-view .fm-grid-layout div.fm-cell.fm-kpi-status-risk-icon:after {
  content: "\e954";
}
#fm-pivot-view .fm-grid-layout div.fm-cell.fm-kpi-status-ok-icon:after {
  content: "\e952";
}
#fm-pivot-view .fm-grid-layout div.fm-cell.fm-kpi-status-rising-icon:after {
  content: "\e953";
}
#fm-pivot-view .fm-grid-layout div.fm-cell.fm-kpi-status-good-icon:after {
  content: "\e950";
}
#fm-pivot-view .fm-grid-layout div.fm-cell .fm-member-property-label {
  font-style: italic;
}
#fm-pivot-view .fm-grid-layout .fm-sheet-selection-canvas {
  position: absolute;
  background-color: rgba(255, 245, 106, 0.2);
  pointer-events: none;
  box-sizing: border-box;
  border: 1px solid #ccc;
  z-index: 1;
}
#fm-pivot-view .fm-grid-layout .fm-sheet-selection-canvas.fm-clear-border {
  border: none;
}
#fm-pivot-view .fm-grid-layout .fm-sheet-selection-canvas.fm-clear-border-right {
  border-right: none;
}
#fm-pivot-view .fm-grid-layout .fm-sheet-selection-canvas.fm-clear-border-left {
  border-left: none;
}
#fm-pivot-view .fm-grid-layout .fm-sheet-selection-canvas.fm-clear-border-top {
  border-top: none;
}
#fm-pivot-view .fm-grid-layout .fm-sheet-selection-canvas.fm-clear-border-bottom {
  border-bottom: none;
}
#fm-pivot-view .fm-grid-layout .fm-auto-calculation-bar {
  position: absolute;
  background-color: #888888;
  opacity: 1;
  pointer-events: none;
  box-sizing: border-box;
  z-index: 2;
  height: 17px;
  overflow: hidden;
}
#fm-pivot-view .fm-grid-layout .fm-auto-calculation-bar .fm-auto-calculation-bar-conainer {
  overflow: hidden;
  white-space: nowrap;
}
#fm-pivot-view .fm-grid-layout .fm-auto-calculation-bar .fm-auto-calculation-bar-content {
  margin-left: 5px;
  margin-right: 5px;
  vertical-align: middle;
  display: inline-block;
}
#fm-pivot-view .fm-grid-layout .fm-auto-calculation-bar .fm-auto-calculation-bar-content-text {
  color: #fff;
  text-transform: uppercase;
  margin-right: 5px;
  float: left;
  font-size: 11px;
  line-height: 17px;
}
#fm-pivot-view .fm-grid-layout .fm-auto-calculation-bar .fm-auto-calculation-bar-content-results {
  color: #fff;
  font-weight: bold;
  display: inline-block;
  font-size: 11px;
  line-height: 17px;
}
#fm-pivot-view .fm-grid-layout .fm-sheet-selection-header {
  border: none;
}
#fm-pivot-view .fm-grid-layout.fm-flat-view .fm-filter-header {
  padding-left: 16px;
}
#fm-pivot-view .fm-grid-layout.fm-flat-view .fm-filter-header i.fm-filter-icon {
  left: 0;
}
#fm-pivot-view .fm-grid-layout .fm-live-filtering-header div {
  position: relative;
  margin-top: 4px;
}
#fm-pivot-view .fm-grid-layout .fm-live-filtering-header div .fm-ui-text-input {
  position: absolute;
  padding: 0 4px;
  height: 22px;
  top: 0;
  left: 0;
}
#fm-pivot-view .fm-grid-layout.fm-compact-view .fm-live-filtering-header,
#fm-pivot-view .fm-grid-layout.fm-classic-view .fm-live-filtering-header {
  min-width: 100px;
}
#fm-pivot-view .fm-grid-layout.fm-compact-view .fm-live-filtering-header .fm-ui-text-input,
#fm-pivot-view .fm-grid-layout.fm-classic-view .fm-live-filtering-header .fm-ui-text-input {
  width: calc(100% + 16px);
}
#fm-pivot-view .fm-grid-layout.fm-compact-view .fm-live-filtering-header.fm-filtered .fm-ui-text-input,
#fm-pivot-view .fm-grid-layout.fm-classic-view .fm-live-filtering-header.fm-filtered .fm-ui-text-input {
  width: calc(100% + 31px);
}
#fm-pivot-view .fm-grid-layout.fm-flat-view .fm-filter-header.fm-live-filtering-header {
  padding: 7px;
  padding-bottom: 29px;
}
#fm-pivot-view .fm-grid-layout.fm-flat-view .fm-filter-header.fm-live-filtering-header.fm-filtered {
  padding-right: 40px;
}
#fm-pivot-view .fm-grid-layout.fm-flat-view .fm-filter-header.fm-live-filtering-header.fm-filtered .fm-ui-text-input {
  width: calc(100% + 23px);
}
#fm-pivot-view .fm-grid-layout .fm-resize-handles {
  position: absolute;
}
#fm-pivot-view .fm-grid-layout .fm-resize-handles .fm-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}
#fm-pivot-view .fm-grid-layout .fm-resize-handles .fm-indicator {
  border: 1px solid #4285F4;
  position: absolute;
  z-index: 1;
}
#fm-pivot-view .fm-grid-layout .fm-resize-handles .fm-handle {
  position: absolute;
  z-index: 1;
  pointer-events: all;
}
#fm-pivot-view .fm-grid-layout .fm-resize-handles .fm-handle:hover {
  border: 4px solid #4285F4;
}
#fm-pivot-view .fm-grid-layout .fm-resize-handles .fm-handle.fm-active {
  border: 4px solid #4285F4;
}
#fm-pivot-view .fm-grid-layout .fm-resize-handles .fm-handle:after {
  content: '';
  position: absolute;
  top: -5px;
  bottom: -5px;
  left: -5px;
  right: -5px;
}
#fm-pivot-view .fm-grid-layout .fm-resize-handles.fm-cols-resize .fm-handle {
  width: 7px;
  margin-left: -3px;
  height: 100%;
  min-height: 25px;
  cursor: col-resize;
}
#fm-pivot-view .fm-grid-layout .fm-resize-handles.fm-rows-resize .fm-handle {
  height: 7px;
  margin-top: -3px;
  width: 100%;
  min-width: 25px;
  cursor: row-resize;
}
/*
 * 	Toolbar for Flexmonster Pivot Table & Charts Component
 */
#fm-toolbar-wrapper [class^="fm-"],
#fm-toolbar-wrapper [class*=" fm-"] {
  color: #111;
  line-height: 1;
}
#fm-toolbar-wrapper [class^="fm-"] strong,
#fm-toolbar-wrapper [class*=" fm-"] strong {
  font-weight: bold;
}
#fm-toolbar-wrapper [class^="fm-"] a,
#fm-toolbar-wrapper [class*=" fm-"] a {
  text-decoration: none;
}
#fm-toolbar-wrapper [class^="fm-"] *,
[class*=" fm-"] * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
#fm-toolbar-wrapper [class^="fm-"] ul,
#fm-toolbar-wrapper [class^="fm-"] ol,
#fm-toolbar-wrapper [class^="fm-"] p,
#fm-toolbar-wrapper [class*=" fm-"] ul,
#fm-toolbar-wrapper [class*=" fm-"] ol,
#fm-toolbar-wrapper [class*=" fm-"] p {
  list-style: none;
  margin: 0;
  padding: 0;
}
#fm-toolbar-wrapper [class^="fm-icon-"]:before,
#fm-toolbar-wrapper [class*=" fm-icon-"]:before,
#fm-toolbar-wrapper .fm-icon:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-button-add .fm-icon:before,
#fm-toolbar-wrapper .fm-toolbar-ui .fm-button-add-large .fm-icon:before,
#fm-toolbar-wrapper .fm-toolbar-ui .fm-colorpick-btn .fm-icon,
#fm-toolbar-wrapper .fm-toolbar-ui .fm-cp-currentmark,
#fm-toolbar-wrapper .fm-toolbar-ui .fm-cp-currentmark:before {
  font-size: 30px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  font-weight: normal;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-condition-row .fm-cr-delete {
  content: '';
  display: block;
  position: absolute;
  top: -42px;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}
/* TOOLBAR */
#fm-toolbar-wrapper {
  height: 80px;
}
#fm-toolbar-wrapper #fm-toolbar {
  width: 100%;
  height: 80px;
  list-style: none;
  background: #fff;
  white-space: nowrap;
}
#fm-toolbar-wrapper #fm-toolbar,
#fm-toolbar-wrapper #fm-toolbar div,
#fm-toolbar-wrapper #fm-toolbar span,
#fm-toolbar-wrapper #fm-toolbar p,
#fm-toolbar-wrapper #fm-toolbar a,
#fm-toolbar-wrapper #fm-toolbar table,
#fm-toolbar-wrapper #fm-toolbar table th,
#fm-toolbar-wrapper #fm-toolbar table tr,
#fm-toolbar-wrapper #fm-toolbar table td,
#fm-toolbar-wrapper #fm-toolbar ul,
#fm-toolbar-wrapper #fm-toolbar li,
#fm-toolbar-wrapper #fm-toolbar input,
#fm-toolbar-wrapper #fm-toolbar textarea,
#fm-toolbar-wrapper #fm-toolbar select {
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: #888;
  text-align: left;
  padding: 0;
  margin: 0;
  font-weight: normal;
  text-shadow: none;
}
/* TABS */
#fm-toolbar-wrapper #fm-toolbar > li {
  display: inline-block;
}
#fm-toolbar-wrapper #fm-toolbar li:before {
  content: none;
}
#fm-toolbar-wrapper #fm-toolbar .fm-dropdown-content li {
  float: none;
  width: 140px;
}
#fm-toolbar-wrapper #fm-toolbar .fm-toolbar-group-left {
  position: absolute;
  left: 0;
}
#fm-toolbar-wrapper #fm-toolbar .fm-toolbar-group-right {
  position: absolute;
  right: 0;
}
#fm-toolbar-wrapper #fm-toolbar li {
  cursor: pointer;
}
#fm-toolbar-wrapper #fm-toolbar li svg {
  transform: translateZ(0);
  fill: #999;
}
#fm-toolbar-wrapper #fm-toolbar li svg .fill-border {
  fill: #999;
}
#fm-toolbar-wrapper #fm-toolbar li svg .fill-background {
  fill: #e9e9e9;
}
#fm-toolbar-wrapper #fm-toolbar li a:hover svg,
#fm-toolbar-wrapper #fm-toolbar li li:hover > .fm-svg-icon > svg {
  fill: #808080;
}
#fm-toolbar-wrapper #fm-toolbar li a:hover svg .fill-border,
#fm-toolbar-wrapper #fm-toolbar li li:hover > .fm-svg-icon > svg .fill-border {
  fill: #808080;
}
#fm-toolbar-wrapper #fm-toolbar li a:hover svg .fill-background,
#fm-toolbar-wrapper #fm-toolbar li li:hover > .fm-svg-icon > svg .fill-background {
  fill: #d0d0d0;
}
#fm-toolbar-wrapper #fm-toolbar > li > a .fm-svg-icon,
#fm-toolbar-wrapper #fm-toolbar > .fm-toolbar-group-left > li > a .fm-svg-icon,
#fm-toolbar-wrapper #fm-toolbar > .fm-toolbar-group-right > li > a .fm-svg-icon {
  position: absolute;
  text-align: center;
  bottom: 25px;
  width: 100%;
}
#fm-toolbar-wrapper #fm-toolbar > li > a .fm-tab-label,
#fm-toolbar-wrapper #fm-toolbar > .fm-toolbar-group-left > li > a .fm-tab-label,
#fm-toolbar-wrapper #fm-toolbar > .fm-toolbar-group-right > li > a .fm-tab-label {
  position: absolute;
  top: 55px;
  left: 0;
  right: 0;
}
#fm-toolbar-wrapper #fm-toolbar .fm-dropdown-content li {
  height: 36px;
}
#fm-toolbar-wrapper #fm-toolbar .fm-dropdown-content li .fm-svg-icon {
  position: absolute;
  left: 0;
  right: 0;
}
#fm-toolbar-wrapper #fm-toolbar .fm-dropdown-content li .fm-svg-icon svg {
  width: 36px;
  height: 36px;
}
#fm-toolbar-wrapper #fm-toolbar .fm-dropdown-content li .fm-tab-label {
  display: inline-block;
  padding: 12px 0px;
  padding-left: 36px;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#fm-toolbar-wrapper #fm-toolbar .fm-dropdown-content li .fm-tab-checkbox:before {
  content: '';
  display: inline-block;
  box-sizing: border-box;
  position: absolute;
  left: 10px;
  top: 9px;
  width: 18px;
  height: 18px;
  border: 1px solid #d5d5d5;
  background: #fff;
}
#fm-toolbar-wrapper #fm-toolbar .fm-dropdown-content li .fm-tab-checkbox:before:hover {
  border-color: #999;
}
#fm-toolbar-wrapper #fm-toolbar .fm-dropdown-content li .fm-tab-checkbox:after {
  display: block;
  position: absolute;
  font-family: 'flexmonster-icons' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto;
  height: auto;
  height: initial;
  background: transparent;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  left: 10px;
  top: 8px;
  opacity: 0;
  content: '\e947';
  color: #FFFFFF;
  font-size: 18px;
}
#fm-toolbar-wrapper #fm-toolbar .fm-dropdown-content li.fm-selected .fm-tab-checkbox:after {
  opacity: 1;
  font-weight: bold;
  width: 0;
}
#fm-toolbar-wrapper #fm-toolbar .fm-dropdown-content li.fm-selected .fm-tab-checkbox:before {
  border: 1px solid #555;
  background: #555;
}
#fm-toolbar-wrapper #fm-toolbar #fm-tab-charts-multiple {
  border-top: 1px dotted #dcdcdc;
}
#fm-toolbar-wrapper #fm-toolbar #fm-tab-charts-multiple > span.fm-tab-checkbox {
  position: absolute;
}
#fm-toolbar-wrapper #fm-toolbar li#fm-tab-fullscreen {
  width: 70px;
}
#fm-toolbar-wrapper .fm-list-wrapper.fm-mobile #fm-toolbar {
  overflow-x: auto;
}
#fm-toolbar-wrapper.fm-mobile #fm-toolbar li#fm-tab-format-conditional {
  width: 80px;
}
#fm-toolbar-wrapper.fm-mobile #fm-toolbar li#fm-tab-charts-bar-stack {
  width: 80px;
}
/* Tabs */
#fm-toolbar-wrapper #fm-toolbar li {
  display: inline-block;
  background: none;
  vertical-align: top;
  padding: 0;
  position: static;
  width: 57px;
}
#fm-toolbar-wrapper #fm-toolbar a {
  height: 80px;
  display: block;
  text-decoration: none;
  border: none;
  position: relative;
}
#fm-toolbar-wrapper #fm-toolbar a > span {
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 15px;
}
#fm-toolbar-wrapper #fm-toolbar li:first-child a {
  margin-left: 1px;
}
#fm-toolbar-wrapper #fm-toolbar li.fm-divider {
  width: 0px;
  margin: 20px 15px 0px 15px;
  height: 40px;
  border-right: 1px solid #d5d5d5;
}
#fm-toolbar-wrapper .fm-list-wrapper.fm-mobile #fm-toolbar li.fm-divider {
  display: none;
}
#fm-toolbar-wrapper #fm-toolbar li.fm-v-divider {
  height: 0px;
  width: 140px;
  border-bottom: 1px dotted #dcdcdc;
}
/* Tab menu */
#fm-toolbar-wrapper #fm-toolbar .fm-dropdown {
  position: absolute;
  display: none;
  top: 80px;
  z-index: 25;
}
#fm-toolbar-wrapper #fm-toolbar .fm-opened .fm-dropdown {
  display: block;
}
#fm-toolbar-wrapper #fm-toolbar .fm-dropdown-content:after {
  content: '';
  border-width: 5px;
  border-color: transparent transparent #fff;
  border-style: outset outset solid;
  border-style: solid;
  top: -10px;
  left: 23px;
  width: 0;
  height: 0;
  position: absolute;
}
#fm-toolbar-wrapper #fm-toolbar .fm-dropdown-content:before {
  content: '';
  border-color: transparent transparent #d5d5d5;
  border-style: outset outset solid;
  border-width: 6px;
  position: absolute;
  top: -12px;
  left: 22px;
}
#fm-toolbar-wrapper #fm-toolbar .fm-dropdown-scrollable-left:after {
  left: 1px;
}
#fm-toolbar-wrapper #fm-toolbar .fm-dropdown-scrollable-left:before {
  left: 0px;
}
#fm-toolbar-wrapper #fm-toolbar .fm-align-right .fm-dropdown-content:after {
  left: inherit;
  right: 20px;
}
#fm-toolbar-wrapper #fm-toolbar .fm-align-right .fm-dropdown-content:before {
  left: inherit;
  right: 19px;
}
#fm-toolbar-wrapper #fm-toolbar .fm-dropdown-scrollable-right:after {
  left: inherit;
  right: 0px;
}
#fm-toolbar-wrapper #fm-toolbar .fm-dropdown-scrollable-right:before {
  left: inherit;
  right: -1px;
}
#fm-toolbar-wrapper #fm-toolbar .fm-dropdown-content {
  padding: 0;
  list-style: none;
  background: #fff;
  border: 1px solid #d5d5d5;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  overflow-y: hidden;
}
#fm-toolbar-wrapper #fm-toolbar .fm-dropdown-content li {
  width: 100%;
  padding-right: 6px;
  min-width: 120px;
}
#fm-toolbar-wrapper #fm-toolbar .fm-dropdown-content li:hover {
  background-color: #f7f7f7;
}
/*#fm-toolbar-wrapper #fm-toolbar .fm-dropdown-content a.disabled {
        pointer-events: none;
        cursor: default;
        color: #AAA;
    }*/
/* POPUP WINDOW */
#fm-toolbar-wrapper div.fm-popup {
  position: absolute;
  min-width: 270px;
  z-index: 100;
  font-family: Arial, sans-serif;
  max-width: 100%;
  background: #fff;
  border: 1px solid #d5d5d5;
  margin: 0 auto;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  padding: 24px 30px 24px 30px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
#fm-toolbar-wrapper div.fm-popup.fm-popup-w700 {
  width: 700px;
}
#fm-toolbar-wrapper div.fm-popup.fm-popup-w570 {
  width: 570px;
}
#fm-toolbar-wrapper div.fm-popup.fm-popup-w500 {
  width: 500px;
}
#fm-toolbar-wrapper div.fm-popup.fm-popup-w460 {
  width: 462px;
}
#fm-toolbar-wrapper div.fm-panel .fm-toolbox {
  margin-top: 30px;
  text-align: center;
}
#fm-toolbar-wrapper div.fm-panel .fm-title-bar .fm-toolbox {
  flex: 1;
  margin-top: 0;
  min-width: fit-content;
  width: fit-content;
  float: right;
  text-align: right;
  margin-left: 30px;
}
#fm-toolbar-wrapper div.fm-panel .fm-toolbox .fm-ui-btn {
  height: 38px;
  max-width: calc((100% - 83px) / 2);
  max-width: -webkit-calc((100% - 83px) / 2);
  margin-right: 20px;
}
#fm-toolbar-wrapper div.fm-panel .fm-toolbox .fm-ui-btn:last-child {
  margin-right: 0px;
}
#fm-toolbar-wrapper div.fm-panel .fm-toolbox.fm-ui-col .fm-ui-btn:last-child {
  float: right;
}
#fm-toolbar-wrapper div.fm-panel .fm-panel-content {
  position: relative;
}
#fm-toolbar-wrapper div.fm-panel .fm-panel-content .fm-title-bar {
  display: flex;
  justify-content: center;
  clear: both;
  margin-bottom: 24px;
}
#fm-toolbar-wrapper div.fm-panel .fm-panel-content .fm-title-bar .fm-title-text {
  color: #111;
  font-size: 24px;
  text-align: center;
  text-shadow: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px 0;
}
#fm-toolbar-wrapper div.fm-panel .fm-panel-content .fm-title-bar .fm-title-text.fm-ui-col {
  text-align: left;
  flex: 1;
}
#fm-toolbar-wrapper .fm-popup-modal-overlay {
  z-index: 20;
}
#fm-toolbar-wrapper .fm-popup-modal-overlay .fm-modal-overlay {
  opacity: 0;
}
#fm-toolbar-wrapper div.fm-modal-overlay {
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
}
#fm-toolbar-wrapper .fm-portrait-radio {
  margin-left: 14px;
}
#fm-toolbar-wrapper .fm-landscape-radio {
  margin-left: 14px;
}
#fm-toolbar-wrapper div.fm-panel.fm-popup.fm-layout-mobile {
  padding: 20px;
}
#fm-toolbar-wrapper div.fm-panel.fm-popup.fm-layout-mobile .fm-panel-content .fm-title-bar {
  display: flex;
  flex-direction: column;
}
#fm-toolbar-wrapper div.fm-panel.fm-popup.fm-layout-mobile .fm-panel-content .fm-title-bar .fm-title-text.fm-ui-col {
  max-width: none !important;
  width: 100%;
  font-size: 18px;
  margin-bottom: 10px;
}
#fm-toolbar-wrapper div.fm-panel.fm-popup.fm-layout-mobile .fm-panel-content .fm-toolbox {
  display: flex;
  max-width: none !important;
  width: auto !important;
  margin-left: 0;
}
#fm-toolbar-wrapper div.fm-panel.fm-popup.fm-layout-mobile .fm-panel-content .fm-toolbox .fm-ui-btn:not(.fm-button-add) {
  max-width: none;
  flex: 1;
}
/* Conditional formatting */
#fm-toolbar-wrapper .fm-popup-conditional {
  width: 610px;
  min-width: 400px;
}
#fm-toolbar-wrapper .fm-popup-conditional .fm-toolbox {
  max-width: 54% !important;
  width: 54% !important;
}
#fm-toolbar-wrapper .fm-popup-conditional .fm-title-text {
  max-width: 46% !important;
  width: -webkit-calc(100% - 270px);
  width: calc(100% - 270px);
}
#fm-toolbar-wrapper .fm-popup-conditional .fm-popup-content {
  overflow-x: hidden;
  overflow-y: auto;
  /*min-height: 149px;*/
  max-height: 298px;
  width: 100%;
}
#fm-toolbar-wrapper .fm-popup-conditional .fm-ui-btn.fm-button-add {
  min-width: 40px;
  width: 40px;
}
#fm-toolbar-wrapper .fm-popup-conditional .fm-condition-row {
  border-top: 1px solid #d5d5d5;
  padding: 30px 30px;
  margin-left: -30px;
  width: -webkit-calc(100% + 60px);
  width: calc(100% + 60px);
}
#fm-toolbar-wrapper .fm-popup-conditional .fm-condition-row .fm-wrap-relative {
  position: relative;
}
#fm-toolbar-wrapper .fm-popup-conditional .fm-condition-row .fm-cr-delete {
  position: absolute;
  color: #999;
  cursor: pointer;
  font-size: 30px;
  right: 0;
}
#fm-toolbar-wrapper .fm-popup-conditional .fm-condition-row .fm-cr-delete:hover {
  color: #555;
}
#fm-toolbar-wrapper .fm-popup-conditional .fm-condition-row .fm-values {
  width: calc(100% - 390px);
  width: -webkit-calc(100% - 390px);
}
#fm-toolbar-wrapper .fm-popup-conditional .fm-condition-row .fm-conditions {
  width: 150px;
}
#fm-toolbar-wrapper .fm-popup-conditional .fm-condition-row .fm-font-family {
  width: calc(100% - 390px);
  width: -webkit-calc(100% - 390px);
}
#fm-toolbar-wrapper .fm-popup-conditional .fm-condition-row .fm-font-size {
  width: 100px;
}
#fm-toolbar-wrapper .fm-popup-conditional .fm-condition-row .fm-sample {
  width: 120px;
}
#fm-toolbar-wrapper .fm-popup-conditional.fm-layout-mobile .fm-values {
  width: calc(100% - 100px);
  width: -webkit-calc(100% - 100px);
  margin-bottom: 10px;
}
#fm-toolbar-wrapper .fm-popup-conditional.fm-layout-mobile .fm-conditions {
  width: calc(100% - 230px);
  width: -webkit-calc(100% - 230px);
  margin-left: 60px;
}
#fm-toolbar-wrapper .fm-popup-conditional.fm-layout-mobile .fm-font-family {
  width: calc(100% - 100px);
  width: -webkit-calc(100% - 100px);
  margin-bottom: 10px;
}
#fm-toolbar-wrapper .fm-popup-conditional.fm-layout-mobile .fm-font-size {
  width: calc(100% - 230px);
  width: -webkit-calc(100% - 230px);
  margin-left: 60px;
}
#fm-toolbar-wrapper .fm-popup-conditional.fm-layout-mobile .fm-sample {
  width: 70px;
}
#fm-toolbar-wrapper .fm-popup-conditional.fm-layout-mobile .fm-cr-delete {
  top: -90px;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-cr-inner {
  font-size: 0;
  margin-bottom: 10px;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-cr-inner:nth-child(2) {
  margin-bottom: 0;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-cr-inner .fm-cr-lbl {
  display: inline-block;
  vertical-align: top;
  color: #999;
  font-size: 14px;
  margin-right: 10px;
  text-align: right;
  padding: 11px 0;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-cr-inner .fm-cr-lbl:last-child {
  margin-right: 0;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-cr-inner .fm-ui-dropdown,
#fm-toolbar-wrapper .fm-toolbar-ui .fm-number-inp,
#fm-toolbar-wrapper .fm-toolbar-ui .fm-cr-inner .fm-inp,
#fm-toolbar-wrapper .fm-toolbar-ui .fm-cr-inner .fm-colorpick-wrap {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-cr-inner .fm-ui-dropdown:last-child,
#fm-toolbar-wrapper .fm-toolbar-ui .fm-cr-inner .fm-number-inp:last-child,
#fm-toolbar-wrapper .fm-toolbar-ui .fm-cr-inner .fm-inp:last-child,
#fm-toolbar-wrapper .fm-toolbar-ui .fm-cr-inner .fm-colorpick-wrap:last-child {
  margin-right: 0;
}
#fm-toolbar-wrapper .fm-icon-act_trash:before {
  content: "\e903";
}
#fm-toolbar-wrapper .fm-toolbar-ui span.fm-and-label {
  display: inline-block;
  text-align: center;
  padding: 12px 0;
}
/* GENERAL */
#fm-toolbar-wrapper div.fm-popup input[type=text],
#fm-toolbar-wrapper div.fm-popup input[type=number],
#fm-toolbar-wrapper div.fm-popup input[type=password] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #d5d5d5;
  background-color: #fff;
  height: 38px;
  padding: 0 9px;
  color: #111;
  font-size: 14px;
  width: 100%;
  -webkit-appearance: none;
  border-radius: 0px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin: 0;
  text-align: left;
  font-family: Arial, sans-serif;
}
#fm-toolbar-wrapper div.fm-popup input:disabled {
  opacity: 0.5;
  filter: alpha(opacity=50);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  background-color: #fff;
}
#fm-toolbar-wrapper div.fm-popup input.fm-half-input {
  padding-right: 0;
  border-right: none;
}
#fm-toolbar-wrapper .fm-toolbar-ui select {
  border: 1px solid #d5d5d5;
  background-color: #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  border-radius: 0px;
  margin: 0;
  outline: none;
  height: 38px;
  font-size: 14px;
  font-family: Arial, sans-serif;
  width: 100%;
  color: #111;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 4px 35px 4px 10px;
  cursor: pointer;
}
#fm-toolbar-wrapper .fm-toolbar-ui div.fm-ui-dropdown {
  position: static;
}
#fm-toolbar-wrapper .fm-toolbar-ui div.fm-ui-dropdown .fm-ui-dropdown-btn {
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
#fm-toolbar-wrapper .fm-toolbar-ui div.fm-ui-dropdown .fm-ui-dropdown-list {
  position: absolute;
  margin-top: 6px;
  min-width: auto;
  min-width: initial;
}
#fm-toolbar-wrapper .fm-toolbar-ui div.fm-ui-dropdown .fm-ui-dropdown-list li {
  font-size: 14px;
}
#fm-toolbar-wrapper .fm-toolbar-ui div.fm-ui-dropdown.fm-opened .fm-ui-dropdown-list {
  visibility: visible;
}
#fm-toolbar-wrapper div.fm-popup select::-ms-expand {
  display: none;
}
#fm-toolbar-wrapper div.fm-popup select:disabled {
  opacity: 0.5;
  filter: alpha(opacity=50);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  cursor: none;
  pointer-events: none;
}
#fm-toolbar-wrapper div.fm-popup select * {
  margin: 0 !important;
}
#fm-toolbar-wrapper div.fm-popup select option.placeholder {
  color: white;
  font-size: 0px;
  display: none;
}
#fm-toolbar-wrapper div.fm-clear {
  clear: both;
}
/* Hack for Firefox */
@-moz-document url-prefix() {
  #fm-toolbar-wrapper select {
    text-indent: 0;
  }
}
/* COLOR PICKER */
#fm-toolbar-wrapper .fm-toolbar-ui .fm-colorpick-btn {
  border: 1px solid #d5d5d5;
  height: 38px;
  position: relative;
  font-size: 28px;
  cursor: pointer;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-colorpick-btn span {
  font-size: 28px;
}
#fm-toolbar-wrapper .fm-icon-act_font:before {
  content: "\e906";
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-colorpick-popup {
  background: #fff;
  width: 396px;
  padding: 30px 30px;
  border: 1px solid #d5d5d5;
  position: absolute;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  display: none;
  left: 50%;
  margin-left: -178px;
  margin-top: -5px;
  z-index: 99;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-colorpick-popup:before,
#fm-toolbar-wrapper .fm-toolbar-ui .fm-colorpick-popup:after {
  content: '';
  display: block;
  position: absolute;
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-colorpick-popup:before {
  width: 0px;
  height: 0px;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-colorpick-popup:after {
  width: 0px;
  height: 0px;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-colorpick-popup.fm-arrow-up:before {
  top: -6px;
  border-left: 5.5px solid transparent;
  border-right: 5.5px solid transparent;
  border-bottom: 6px solid #d5d5d5;
  border-top: 0 solid transparent;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-colorpick-popup.fm-arrow-up:after {
  top: -5px;
  border-left: 4.5px solid transparent;
  border-right: 4.5px solid transparent;
  border-bottom: 5px solid #fff;
  border-top: 0 solid transparent;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-colorpick-popup.fm-arrow-down:before {
  bottom: -6px;
  border-left: 5.5px solid transparent;
  border-right: 5.5px solid transparent;
  border-top: 6px solid #d5d5d5;
  border-bottom: 0 solid transparent;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-colorpick-popup.fm-arrow-down:after {
  bottom: -5px;
  border-left: 4.5px solid transparent;
  border-right: 4.5px solid transparent;
  border-top: 5px solid #fff;
  border-bottom: 0 solid transparent;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-popup-opened .fm-colorpick-popup {
  display: block;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-color-targ-switch {
  border: 1px solid #d5d5d5;
  font-size: 0;
  margin-bottom: 20px;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-color-targ-switch .fm-cts-item {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  border: none;
  border-right: 1px solid #d5d5d5;
  line-height: 38px;
  font-weight: bold;
  color: #999;
  background: #fff;
  text-transform: uppercase;
  text-align: center;
  font-size: 14px;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-color-targ-switch .fm-cts-item.fm-current {
  color: #111;
  background: #f7f7f7;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-color-targ-switch .fm-cts-item:hover {
  color: #111;
  background: #f7f7f7;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-color-targ-switch .fm-cts-item:last-child {
  border-right: none;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-cp-sett-row {
  margin-bottom: 20px;
  font-size: 0;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-cp-sett-row .fm-cp-curr-color {
  border: 1px solid #d5d5d5;
  height: 38px;
  cursor: pointer;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-cp-2-colors-width.fm-cp-2-colors-width {
  width: calc(50% - 5px);
  width: -webkit-calc(50% - 5px);
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-cp-sett-row .fm-cp-2-colors {
  border: 1px solid #d5d5d5;
  font-size: 0;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-cp-sett-row .fm-cp-2-colors .fm-cp2c-item {
  height: 36px;
  cursor: pointer;
  border-right: 1px solid #d5d5d5;
  display: inline-block;
  vertical-align: top;
  width: 50%;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-cp-sett-row .fm-cp-2-colors .fm-cp2c-item:last-child {
  border-right: none;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-cp-sett-row .fm-inp,
.fm-cp-sett-row .fm-cp-curr-color,
.fm-cp-sett-row .fm-cp-2-colors {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-cp-sett-row .fm-inp:last-child,
.fm-cp-sett-row .fm-cp-curr-color:last-child,
.fm-cp-sett-row .fm-cp-2-colors:last-child {
  margin-right: 0;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-cp-btns-row {
  margin-top: 20px;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-cp-btns-row .fm-ui-btn {
  width: calc(50% - 5px);
  width: -webkit-calc(50% - 5px);
  margin-right: 10px;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-cp-btns-row .fm-ui-btn:last-child {
  margin-right: 0;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-cp-currentmark {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background: #555;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-cp-currentmark:before {
  color: #fff;
  font-size: 20px;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-row-10colors {
  font-size: 0;
  margin-bottom: 20px;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-row-10colors .fm-r10c-item {
  content: "";
  display: inline-block;
  vertical-align: top;
  position: relative;
  cursor: pointer;
  height: 36px;
  width: -webkit-calc(10% - 1px);;
  width: calc(10% - 1px);;
  margin-right: 1px;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-row-10colors .fm-r10c-item:before,
#fm-toolbar-wrapper .fm-toolbar-ui .fm-row-10colors .fm-r10c-item:after {
  content: '';
  display: block;
  position: absolute;
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-row-10colors .fm-r10c-item.fm-r10c-white-arrow {
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-row-10colors .fm-r10c-item.fm-r10c-white-arrow.fm-current:before {
  bottom: -11px;
  border-left: 17px solid transparent;
  border-right: 17px solid transparent;
  border-top: 11px solid #e9e9e9;
  border-bottom: 0 solid transparent;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-row-10colors .fm-r10c-item.fm-r10c-white-arrow.fm-current:after {
  bottom: -10px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-top: 10px solid #fff;
  border-bottom: 0 solid transparent;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-row-10colors .fm-r10c-item .fm-r10c-arrow {
  position: absolute;
  top: 100%;
  border-style: solid;
  border-top-width: 10px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-bottom: none;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-row-10colors .fm-r10c-item .fm-cp-currentmark {
  margin-top: 2px;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-row-10colors .fm-r10c-item .fm-r10c-arrow,
#fm-toolbar-wrapper .fm-toolbar-ui .fm-row-10colors .fm-r10c-item .fm-cp-currentmark {
  opacity: 0;
  visibility: hidden;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-row-10colors .fm-r10c-item:nth-child(10n+10) {
  margin-right: 0;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-row-10colors .fm-r10c-item.fm-current .fm-r10c-arrow,
#fm-toolbar-wrapper .fm-toolbar-ui .fm-row-10colors .fm-r10c-item.fm-current .fm-cp-currentmark {
  opacity: 1;
  visibility: visible;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-row-4colors {
  font-size: 0;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-row-4colors .fm-r4c-item {
  display: inline-block;
  vertical-align: top;
  margin-right: 1px;
  margin-bottom: 1px;
  position: relative;
  cursor: pointer;
  height: 36px;
  width: -webkit-calc(25% - 0.75px);
  width: calc(25% - 0.75px);
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-row-4colors .fm-r4c-item.fm-white-shade {
  border-top: 1px solid #e9e9e9;
  border-left: 1px solid #e9e9e9;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-row-4colors .fm-r4c-item:nth-child(4n+4) {
  margin-right: 0;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-row-4colors .fm-r4c-item .fm-cp-currentmark {
  opacity: 0;
  visibility: hidden;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-row-4colors .fm-r4c-item.fm-current .fm-cp-currentmark {
  opacity: 1;
  visibility: visible;
}
#fm-toolbar-wrapper .fm-list-wrapper.fm-mobile {
  overflow-x: auto;
}
#fm-toolbar-wrapper .fm-list-wrapper.fm-one-arrow-scroll {
  width: calc(100% - 25px) !important;
}
#fm-toolbar-wrapper .fm-list-wrapper.fm-two-arrow-scroll {
  width: calc(100% - 50px) !important;
}
#fm-toolbar-wrapper .fm-list-wrapper,
#fm-toolbar-wrapper .fm-left-scroll-button,
#fm-toolbar-wrapper .fm-right-scroll-button {
  float: left;
  position: relative;
}
#fm-toolbar-wrapper .fm-left-scroll-button.fm-scroll-arrow {
  margin-top: 15px;
  margin-right: 5px;
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  border-right: 20px solid #555;
}
#fm-toolbar-wrapper .fm-right-scroll-button.fm-scroll-arrow {
  margin-top: 15px;
  margin-left: 5px;
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  border-left: 20px solid #555;
}
#fm-toolbar-wrapper .fm-right-scroll-button.fm-mobile-scroll-not-active,
#fm-toolbar-wrapper .fm-left-scroll-button.fm-mobile-scroll-not-active {
  opacity: 0.2;
}
/* new ui */
#fm-toolbar-wrapper .fm-radio-wrap label:before,
#fm-toolbar-wrapper .fm-radio-wrap label:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}
#fm-toolbar-wrapper .fm-toolbar-ui div.fm-title-2 {
  color: #999;
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 25px;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-radiobtn-list {
  margin-bottom: 52px;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-radiobtn-list:last-child {
  margin-bottom: 0;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-radiobtn-list li {
  margin-bottom: 12px;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-radio-wrap label {
  display: inline-block;
  cursor: pointer;
  color: #111;
  font-size: 14px;
  padding: 4px 0;
  padding-left: 35px;
  position: relative;
  line-height: 1.2;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-radio-wrap label:before {
  width: 22px;
  height: 22px;
  border: 1px solid #d5d5d5;
  background: #fff;
  left: 0;
  border-radius: 50%;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-radio-wrap label:after {
  border: 7px solid #555;
  background-color: #555;
  border-radius: 50%;
  opacity: 0;
  width: 0;
  height: 0;
  left: 5px;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-radio-wrap label:hover:before {
  border-color: #999;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-radio-wrap input {
  display: none;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-radio-wrap input:checked + label {
  font-weight: bold;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-radio-wrap input:checked + label:after {
  opacity: 1;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-inp-group {
  margin-bottom: 30px;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-inp-group:last-child {
  margin-bottom: 0;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-inp-row {
  margin-bottom: 10px;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-inp-row label {
  color: #999;
  font-size: 14px;
  margin-bottom: 8px;
  display: block;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-inp-row .fm-ui-dropdown,
#fm-toolbar-wrapper .fm-toolbar-ui .fm-inp-row .fm-inp {
  width: 100%;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-inp-row:last-child {
  margin-bottom: 0;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-ir-horizontal {
  font-size: 0;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-ir-horizontal label {
  margin-bottom: 0;
  width: 140px;
  margin-right: 20px;
  display: inline-block;
  vertical-align: middle;
  text-align: right;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-ir-horizontal .fm-ui-dropdown,
#fm-toolbar-wrapper .fm-toolbar-ui .fm-ir-horizontal .fm-inp {
  width: -webkit-calc(100% - 160px);
  width: calc(100% - 160px);
  display: inline-block;
  vertical-align: middle;
  background-color: #fff;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-uc {
  text-transform: uppercase;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-button-add {
  padding-right: 30px;
  min-width: auto;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-button-add-large {
  padding-left: 36px;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-button-add .fm-icon {
  color: #999;
  position: absolute;
  right: 0;
  width: 40px;
  height: 100%;
  top: 0;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-button-add-large .fm-icon {
  color: #999;
  position: absolute;
  left: 0;
  width: 40px;
  height: 100%;
  top: 0;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-button-add:hover .fm-icon,
#fm-toolbar-wrapper .fm-toolbar-ui .fm-button-add-large:hover .fm-icon {
  color: #555;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-popup-content .fm-popup-placeholder {
  text-align: center;
  margin: 40px 0;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-popup-content .fm-popup-placeholder .fm-popup-placeholder-text {
  text-align: center;
  margin-bottom: 9px;
  font-size: 16px;
  color: #999;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-icon-act_add:before {
  content: "\ea0a";
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-icon-act_check:before {
  content: "\e902";
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-width20.fm-width20 {
  width: 20px;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-width40.fm-width40 {
  width: 40px;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-width45.fm-width45 {
  width: 45px;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-width50.fm-width50 {
  width: 50px;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-width85.fm-width85 {
  width: 85px;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-width120.fm-width120 {
  width: 120px;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-width140.fm-width140 {
  width: 140px;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-width180.fm-width180 {
  width: 180px;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-width200.fm-width200 {
  width: 200px;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-width210.fm-width210 {
  width: 210px;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-width220.fm-width220 {
  width: 220px;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-width230.fm-width230 {
  width: 230px;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-width240.fm-width240 {
  width: 240px;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-width250.fm-width250 {
  width: 250px;
}
/* Connect to OLAP */
#fm-toolbar-wrapper .fm-popup-olap .fm-ir-horizontal label {
  width: 110px;
}
#fm-toolbar-wrapper .fm-popup-olap .fm-ir-horizontal .fm-ui-dropdown {
  width: -webkit-calc(100% - 130px);
  width: calc(100% - 130px);
  vertical-align: middle;
}
#fm-toolbar-wrapper .fm-popup-olap input.fm-inp.fm-inp-proxy-url {
  width: -webkit-calc(100% - 230px);
  width: calc(100% - 230px);
}
#fm-toolbar-wrapper .fm-popup-olap .fm-btn-connect {
  width: 100px;
  height: 38px;
  padding-left: 0;
  padding-right: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
#fm-toolbar-wrapper .fm-popup-olap.fm-layout-mobile-small .fm-ir-horizontal label {
  text-align: left;
  width: 100%;
}
#fm-toolbar-wrapper .fm-popup-olap.fm-layout-mobile-small .fm-ir-horizontal .fm-ui-dropdown {
  width: 100%;
}
#fm-toolbar-wrapper .fm-popup-olap.fm-layout-mobile-small input.fm-inp.fm-inp-proxy-url {
  width: -webkit-calc(100% - 100px);
  width: calc(100% - 100px);
}
/* Format cells */
#fm-toolbar-wrapper .fm-popup-format-cells {
  width: 480px;
}
#fm-toolbar-wrapper .fm-popup-format-cells.fm-layout-mobile-small .fm-ir-horizontal label {
  text-align: left;
  width: 100%;
}
#fm-toolbar-wrapper .fm-popup-format-cells.fm-layout-mobile-small .fm-ir-horizontal .fm-ui-dropdown,
#fm-toolbar-wrapper .fm-popup-format-cells.fm-layout-mobile-small .fm-ir-horizontal .fm-inp {
  width: 100%;
}
#fm-toolbar-wrapper .fm-hide {
  display: none;
}
/* Options */
#fm-toolbar-wrapper .fm-popup-options {
  width: 570px;
  min-width: 320px;
}
/* layouts */
#fm-toolbar-wrapper.fm-layout-700 #fm-toolbar .fm-divider {
  display: none;
}
#fm-toolbar-wrapper #fm-toolbar.fm-scroll-toolbar {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
  overflow-x: hidden;
  overflow-y: auto;
}
#fm-toolbar-wrapper #fm-toolbar.fm-scroll-toolbar::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}
.fm-resize-triggers {
  visibility: hidden;
  opacity: 0;
}
.fm-resize-triggers,
.fm-resize-triggers > div,
.fm-contract-trigger:before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.fm-resize-triggers > div {
  background: #eee;
  overflow: auto;
}
.fm-contract-trigger:before {
  width: 200%;
  height: 200%;
}
#fm-pivot-view .fm-resizable-handle {
  position: absolute;
}
#fm-pivot-view .fm-resizable-handle.fm-left {
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  cursor: w-resize;
}
#fm-pivot-view .fm-resizable-handle.fm-right {
  right: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  cursor: e-resize;
}
#fm-pivot-view .fm-resizable-handle.fm-top {
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  cursor: n-resize;
}
#fm-pivot-view .fm-resizable-handle.fm-bottom {
  bottom: 0;
  left: 0;
  right: 0;
  height: 5px;
  cursor: s-resize;
}
#fm-pivot-view .fm-scrollbars::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
  background-color: rgba(0, 0, 0, 0);
}
#fm-pivot-view .fm-scrollbars::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}
#fm-pivot-view .fm-scrollbars::-webkit-scrollbar-track {
  background: transparent;
  background-color: rgba(0, 0, 0, 0.05);
}
#fm-pivot-view .fm-scrollbars::-webkit-scrollbar-corner {
  background-color: rgba(0, 0, 0, 0.05);
}
#fm-pivot-view.fm-accessibility :focus,
#fm-toolbar-wrapper.fm-accessibility :focus,
#fm-pivot-view.fm-accessibility a.fm-ui-btn:focus,
#fm-toolbar-wrapper.fm-accessibility a.fm-ui-btn:focus {
  outline: dashed #999 2px;
  outline-offset: -2px;
}
#fm-pivot-view.fm-accessibility a.fm-ui-btn:focus,
#fm-toolbar-wrapper.fm-accessibility a.fm-ui-btn:focus,
#fm-pivot-view.fm-accessibility .fm-grid-view:focus,
#fm-toolbar-wrapper.fm-accessibility .fm-grid-view:focus {
  outline-offset: 0;
}
#fm-pivot-view.fm-accessibility .fm-grid-layout:focus .fm-sheet-selection-canvas,
#fm-toolbar-wrapper.fm-accessibility .fm-grid-layout:focus .fm-sheet-selection-canvas {
  border: 2px dashed #999;
}
#fm-pivot-view.fm-accessibility .fm-grid-layout:focus .fm-sheet-selection-canvas.fm-sheet-selection-header,
#fm-toolbar-wrapper.fm-accessibility .fm-grid-layout:focus .fm-sheet-selection-canvas.fm-sheet-selection-header,
#fm-pivot-view.fm-accessibility .fm-grid-layout:focus .fm-sheet-selection-canvas.fm-clear-border,
#fm-toolbar-wrapper.fm-accessibility .fm-grid-layout:focus .fm-sheet-selection-canvas.fm-clear-border {
  border: none;
}
#fm-pivot-view.fm-accessibility .fm-grid-layout:focus .fm-sheet-selection-canvas.fm-clear-border-right,
#fm-toolbar-wrapper.fm-accessibility .fm-grid-layout:focus .fm-sheet-selection-canvas.fm-clear-border-right {
  border-right: none;
}
#fm-pivot-view.fm-accessibility .fm-grid-layout:focus .fm-sheet-selection-canvas.fm-clear-border-left,
#fm-toolbar-wrapper.fm-accessibility .fm-grid-layout:focus .fm-sheet-selection-canvas.fm-clear-border-left {
  border-left: none;
}
#fm-pivot-view.fm-accessibility .fm-grid-layout:focus .fm-sheet-selection-canvas.fm-clear-border-top,
#fm-toolbar-wrapper.fm-accessibility .fm-grid-layout:focus .fm-sheet-selection-canvas.fm-clear-border-top {
  border-top: none;
}
#fm-pivot-view.fm-accessibility .fm-grid-layout:focus .fm-sheet-selection-canvas.fm-clear-border-bottom,
#fm-toolbar-wrapper.fm-accessibility .fm-grid-layout:focus .fm-sheet-selection-canvas.fm-clear-border-bottom {
  border-bottom: none;
}
.fm-keyboard-mode #fm-pivot-view :focus,
.fm-keyboard-mode #fm-toolbar-wrapper :focus,
.fm-keyboard-mode #fm-pivot-view a.fm-ui-btn:focus,
.fm-keyboard-mode #fm-toolbar-wrapper a.fm-ui-btn:focus,
.fm-keyboard-mode #fm-pivot-view button.fm-ui-btn:focus,
.fm-keyboard-mode #fm-toolbar-wrapper button.fm-ui-btn:focus,
.fm-keyboard-mode #fm-pivot-view select:focus,
.fm-keyboard-mode #fm-toolbar-wrapper select:focus,
.fm-keyboard-mode #fm-pivot-view input.fm-ui:focus,
.fm-keyboard-mode #fm-toolbar-wrapper input.fm-ui:focus,
.fm-keyboard-mode #fm-pivot-view .fm-ui-dropdown-btn:focus,
.fm-keyboard-mode #fm-toolbar-wrapper .fm-ui-dropdown-btn:focus,
.fm-keyboard-mode #fm-pivot-view .fm-ui-dropdown-list li.fm-current,
.fm-keyboard-mode #fm-toolbar-wrapper .fm-ui-dropdown-list li.fm-current,
.fm-keyboard-mode #fm-pivot-view .fm-ui-dropdown-list li.fm-ui-active,
.fm-keyboard-mode #fm-toolbar-wrapper .fm-ui-dropdown-list li.fm-ui-active,
.fm-keyboard-mode #fm-pivot-view .fm-dropdown-content li.fm-current,
.fm-keyboard-mode #fm-toolbar-wrapper .fm-dropdown-content li.fm-current,
.fm-keyboard-mode #fm-pivot-view .fm-ui-list:focus li.fm-ui-active,
.fm-keyboard-mode #fm-toolbar-wrapper .fm-ui-list:focus li.fm-ui-active,
.fm-keyboard-mode #fm-pivot-view .fm-ui-vlist:focus li.fm-ui-active,
.fm-keyboard-mode #fm-toolbar-wrapper .fm-ui-vlist:focus li.fm-ui-active,
.fm-keyboard-mode #fm-pivot-view .fm-calculated-view .fm-popup-content .fm-func-btn-group button.fm-calc-action:focus,
.fm-keyboard-mode #fm-toolbar-wrapper .fm-calculated-view .fm-popup-content .fm-func-btn-group button.fm-calc-action:focus {
  outline: dashed #111 2px;
  outline-offset: -2px;
}
.fm-keyboard-mode #fm-pivot-view a.fm-ui-btn:focus,
.fm-keyboard-mode #fm-toolbar-wrapper a.fm-ui-btn:focus,
.fm-keyboard-mode #fm-pivot-view button.fm-ui-btn:focus,
.fm-keyboard-mode #fm-toolbar-wrapper button.fm-ui-btn:focus,
.fm-keyboard-mode #fm-pivot-view .fm-grid-view:focus,
.fm-keyboard-mode #fm-toolbar-wrapper .fm-grid-view:focus,
.fm-keyboard-mode #fm-pivot-view select:focus,
.fm-keyboard-mode #fm-toolbar-wrapper select:focus,
.fm-keyboard-mode #fm-pivot-view .fm-calculated-view .fm-popup-content .fm-func-btn-group .fm-calc-action:focus,
.fm-keyboard-mode #fm-toolbar-wrapper .fm-calculated-view .fm-popup-content .fm-func-btn-group .fm-calc-action:focus,
.fm-keyboard-mode #fm-pivot-view .fm-colorpick-btn,
.fm-keyboard-mode #fm-toolbar-wrapper .fm-colorpick-btn,
.fm-keyboard-mode #fm-pivot-view .fm-r10c-item,
.fm-keyboard-mode #fm-toolbar-wrapper .fm-r10c-item,
.fm-keyboard-mode #fm-pivot-view .fm-r4c-item,
.fm-keyboard-mode #fm-toolbar-wrapper .fm-r4c-item,
.fm-keyboard-mode #fm-pivot-view .fm-calculated-view .fm-popup-content .fm-func-btn-group button.fm-calc-action:focus,
.fm-keyboard-mode #fm-toolbar-wrapper .fm-calculated-view .fm-popup-content .fm-func-btn-group button.fm-calc-action:focus {
  outline-offset: 0;
}
.fm-keyboard-mode #fm-pivot-view a.fm-ui-btn:focus.fm-btn-add-measure-2,
.fm-keyboard-mode #fm-toolbar-wrapper a.fm-ui-btn:focus.fm-btn-add-measure-2,
.fm-keyboard-mode #fm-pivot-view button.fm-ui-btn:focus.fm-btn-add-measure-2,
.fm-keyboard-mode #fm-toolbar-wrapper button.fm-ui-btn:focus.fm-btn-add-measure-2,
.fm-keyboard-mode #fm-pivot-view .fm-grid-view:focus.fm-btn-add-measure-2,
.fm-keyboard-mode #fm-toolbar-wrapper .fm-grid-view:focus.fm-btn-add-measure-2,
.fm-keyboard-mode #fm-pivot-view select:focus.fm-btn-add-measure-2,
.fm-keyboard-mode #fm-toolbar-wrapper select:focus.fm-btn-add-measure-2,
.fm-keyboard-mode #fm-pivot-view .fm-calculated-view .fm-popup-content .fm-func-btn-group .fm-calc-action:focus.fm-btn-add-measure-2,
.fm-keyboard-mode #fm-toolbar-wrapper .fm-calculated-view .fm-popup-content .fm-func-btn-group .fm-calc-action:focus.fm-btn-add-measure-2,
.fm-keyboard-mode #fm-pivot-view .fm-colorpick-btn.fm-btn-add-measure-2,
.fm-keyboard-mode #fm-toolbar-wrapper .fm-colorpick-btn.fm-btn-add-measure-2,
.fm-keyboard-mode #fm-pivot-view .fm-r10c-item.fm-btn-add-measure-2,
.fm-keyboard-mode #fm-toolbar-wrapper .fm-r10c-item.fm-btn-add-measure-2,
.fm-keyboard-mode #fm-pivot-view .fm-r4c-item.fm-btn-add-measure-2,
.fm-keyboard-mode #fm-toolbar-wrapper .fm-r4c-item.fm-btn-add-measure-2,
.fm-keyboard-mode #fm-pivot-view .fm-calculated-view .fm-popup-content .fm-func-btn-group button.fm-calc-action:focus.fm-btn-add-measure-2,
.fm-keyboard-mode #fm-toolbar-wrapper .fm-calculated-view .fm-popup-content .fm-func-btn-group button.fm-calc-action:focus.fm-btn-add-measure-2 {
  outline-offset: -2px;
}
.fm-keyboard-mode #fm-pivot-view .fm-grid-layout:focus .fm-sheet-selection-canvas,
.fm-keyboard-mode #fm-toolbar-wrapper .fm-grid-layout:focus .fm-sheet-selection-canvas {
  border: 2px dashed #111;
}
.fm-keyboard-mode #fm-pivot-view .fm-grid-layout:focus .fm-sheet-selection-canvas.fm-sheet-selection-header,
.fm-keyboard-mode #fm-toolbar-wrapper .fm-grid-layout:focus .fm-sheet-selection-canvas.fm-sheet-selection-header,
.fm-keyboard-mode #fm-pivot-view .fm-grid-layout:focus .fm-sheet-selection-canvas.fm-clear-border,
.fm-keyboard-mode #fm-toolbar-wrapper .fm-grid-layout:focus .fm-sheet-selection-canvas.fm-clear-border {
  border: none;
}
.fm-keyboard-mode #fm-pivot-view .fm-grid-layout:focus .fm-sheet-selection-canvas.fm-clear-border-right,
.fm-keyboard-mode #fm-toolbar-wrapper .fm-grid-layout:focus .fm-sheet-selection-canvas.fm-clear-border-right {
  border-right: none;
}
.fm-keyboard-mode #fm-pivot-view .fm-grid-layout:focus .fm-sheet-selection-canvas.fm-clear-border-left,
.fm-keyboard-mode #fm-toolbar-wrapper .fm-grid-layout:focus .fm-sheet-selection-canvas.fm-clear-border-left {
  border-left: none;
}
.fm-keyboard-mode #fm-pivot-view .fm-grid-layout:focus .fm-sheet-selection-canvas.fm-clear-border-top,
.fm-keyboard-mode #fm-toolbar-wrapper .fm-grid-layout:focus .fm-sheet-selection-canvas.fm-clear-border-top {
  border-top: none;
}
.fm-keyboard-mode #fm-pivot-view .fm-grid-layout:focus .fm-sheet-selection-canvas.fm-clear-border-bottom,
.fm-keyboard-mode #fm-toolbar-wrapper .fm-grid-layout:focus .fm-sheet-selection-canvas.fm-clear-border-bottom {
  border-bottom: none;
}
.fm-keyboard-mode #fm-pivot-view .fm-ui-list:not(.fm-ui-empty):focus,
.fm-keyboard-mode #fm-toolbar-wrapper .fm-ui-list:not(.fm-ui-empty):focus,
.fm-keyboard-mode #fm-pivot-view .fm-ui-vlist:not(.fm-ui-empty):focus,
.fm-keyboard-mode #fm-toolbar-wrapper .fm-ui-vlist:not(.fm-ui-empty):focus {
  outline: none;
  outline-offset: 0;
}
/*-------------------------------------------------------
|||||||||||||||||||||||||| FONTS ||||||||||||||||||||||||
---------------------------------------------------------*/
/*-------------------------------------------------------
||||||||||||||||||||| THEME COLORS ||||||||||||||||||||||
---------------------------------------------------------*/
/* =================== USAGE: ========================== 
Not used in flexmonster-base.less directly; 
Used in this file to define bg, border and other colors 
======================================================== */
/* ======== basic colors, defined for this theme ======= */
/* ===== basic grey colors, common for all themes ===== */
/*-------------------------------------------------------
|||||||||||||||||||||| TEXT COLORS ||||||||||||||||||||||
---------------------------------------------------------*/
/*-------------------------------------------------------
||||||||||||||||||| TOOLBAR STYLES ||||||||||||||||||||||
---------------------------------------------------------*/
/*-------------------------------------------------------
|||||||||||||||||| BACKGROUND COLORS ||||||||||||||||||||
---------------------------------------------------------*/
/*-------------------------------------------------------
|||||||||||||||||||| BORDER COLORS ||||||||||||||||||||||
---------------------------------------------------------*/
/*-------------------------------------------------------
|||||||||||||||||||||||| BORDERS ||||||||||||||||||||||||
---------------------------------------------------------*/
/*-------------------------------------------------------
||||||||||||||||||| GRID SHEET HEADERS|||||||||||||||||||
---------------------------------------------------------*/
/*-------------------------------------------------------
||||||||||||||||||||||||| GRID ICONS|||||||||||||||||||||
---------------------------------------------------------*/
/*-------------------------------------------------------
|||||||||||||||| GRID TABLE HEADER CELLS|||||||||||||||||
---------------------------------------------------------*/
/* ===== table header row ===== */
/* ===== table header column ===== */
/* ===== table header subtotals row ===== */
/* ===== table header subtotals column ===== */
/* ===== table header grand total row ===== */
/* ===== table header grand total column ===== */
/*-------------------------------------------------------
|||||||||||||||||||| GRID FILTERS |||||||||||||||||||||||
---------------------------------------------------------*/
/* ===== grid filter icon, common for all filter header cells ===== */
/* ===== table header filters column area ===== */
/* ===== table header column filter cell ===== */
/* ===== table header row filter cell ===== */
/* ===== report filter cell ===== */
/* ===== flat table filter cell ===== */
/* ===== drill-through table filter cell and empty filter cell styles ===== */
/* ======== live filtering header ============= */
/*-------------------------------------------------------
||||||||||||||||||||||| GRID CELLS ||||||||||||||||||||||
---------------------------------------------------------*/
/* ===== general grid cell style ===== */
/* ===== subtotal row grid cell style ===== */
/* ===== subtotal column grid cell style ===== */
/* ===== grand total row grid cell style ===== */
/* ===== grand total column grid cell style ===== */
/* ===== grand total row-column intersection grid cell style ===== */
/*-------------------------------------------------------
|||||||||||||||||| GRID DATA SHEET ||||||||||||||||||||||
---------------------------------------------------------*/
/*-------------------------------------------------------
||||||||||||||| GRID DRILL-THROUGH LINK |||||||||||||||||
---------------------------------------------------------*/
/*-------------------------------------------------------
|||||||||||||||| GRID SELECTION CANVAS ||||||||||||||||||
---------------------------------------------------------*/
/*-------------------------------------------------------
|||||||||||||||| GRID AUTO CALCULATION BAR ||||||||||||||
---------------------------------------------------------*/
/*-------------------------------------------------------
||||||||||||| GRID RESIZE HANDLE & INDICATOR ||||||||||||
---------------------------------------------------------*/
/*-------------------------------------------------------
|||||||||||||||||||||||| CHARTS |||||||||||||||||||||||||
---------------------------------------------------------*/
/*-------------------------------------------------------
|||||||||||||||||||||||||||| UI |||||||||||||||||||||||||
---------------------------------------------------------*/
/* ================= checkbox style ==================== */
/* ===================== text style ==================== */
/* ============== draggable item style ================= */
/* ================ button hover colors ================ */
/* ================= button text style ================= */
/* ==================== icon style ===================== */
/* ====================== label style ================== */
/* =================== text area style ================= */
/* ====================== titles style ================= */
/* ====================== prompt style ================= */
